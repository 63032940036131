import { Routes, Route, useParams, Navigate, useLocation } from 'react-router-dom';
import { GerenciadorProvider } from '../../context/Gerenciador';
import { decrypt } from '../../_assets/js/global';
import Navbar from '../../components/header/navbar';
import NavbarLink from '../../components/header/navbar/navbarLink';
import Container from '../../components/body/container';
import Icon from '../../components/body/icon';

// PÁGINAS
import Lista from './Lista';

export default function Lojas(){
  const params = useParams();  
  const location = useLocation();

  var tokenUrl = params['*'].substring(params['*'].indexOf('/')+1);
  var decryptedReturn = JSON.parse(decrypt(tokenUrl));

  window.token = tokenUrl;
  window.rs_id_emp = decryptedReturn.id_emp; 
  window.rs_id_usr = decryptedReturn.id_usr;
  window.rs_id_lja = decryptedReturn.id_lja;
  window.rs_name_usr = decryptedReturn.name_usr;
  window.rs_id_apl = decryptedReturn.id_apl;
  window.rs_sistema_id = decryptedReturn.sistema_id;
  window.rs_permission_apl = decryptedReturn.pus;
  window.rs_id_module = decryptedReturn.id_module;
  window.rs_id_office = decryptedReturn.id_office;

  return (
    <GerenciadorProvider>
      <Navbar
        icons={
          (location.pathname == '/systems/lojas-react/lista/'+window.token ?
            <Icon type="print" />
          :'')
        }
      >
        <NavbarLink link={'lista/'+window.token} name="Lista" icon="analytics" active={true} />
      </Navbar>

      <Container>
        <Routes>
          <Route path="/:token" index element={ <Navigate to={'lista/'+window.token} replace /> }/>
          <Route path="lista/:token" index element={ <Lista /> }/>
        </Routes>
      </Container>
    </GerenciadorProvider>
  );
}
