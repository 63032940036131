import { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../../context/Global";
import Row from "../../../components/body/row";
import Title from "../../../components/body/title";
import SelectReact from "../../../components/body/select";
import Input from "../../../components/body/form/input";
import CardGaleria from "../../../components/body/galeria/cardGaleria";
import Col from "../../../components/body/col";
import CalendarTitle from "../../../components/body/calendarTitle";
import CalendarFilter from "../../../components/body/calendarTitle/calendarFilter";
import Loader from "../../../components/body/loader";
import "./style.css";
import axios from "axios";
import { toast } from "react-hot-toast";
import Icon from "../../../components/body/icon";
import ModalCadastroImagem from "../Gerenciador/modalAddFoto";

export default function Lista() {

    //MODAL
    const [reload, setReload] = useState(false)
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(!show)

    //FECHAR MODAL 
    const handleClose = (e) => {
        setShow(e);
        setReload(true);
        setTimeout(() => {
            setReload(false);
        }, 500)
    };

    // CONTEXT
    const { handleSetSources, handleSetToggler, handleSetFilters } = useContext(GlobalContext);

    //ESTADOS FILTROS 
    const [categoria, setCategoria] = useState('');
    const [opCategoria, setOpCategoria] = useState([]);
    const [subCategoria, setSubCategoria] = useState('');
    const [opSubCategoria, setOpSubCategoria] = useState([]);
    const [date, setDate] = useState(new Date('2023-06-01 00:00'));
    const [loading, setLoading] = useState(true)
    const [categoriaTitle, setCategoriaTitle] = useState([]);
    const [subCategoriaTitle, setSubCategoriaTitle] = useState([]);
    //ESTADOS IMAGENS CARD
    const [image, setImage] = useState([]);
    const [imageArray, setImageArray] = useState();

    //CORRIGIR A DATA PARA MANDAR 
    var dateArr = new Date(date).toLocaleDateString("de", { timeZone: "UTC", year: "numeric", month: "2-digit" }).replaceAll(".", "/");

    //FUNÇÕES - IMAGENS CARD
    function getImages() {
        let imageArr = []
        axios.get(window.host+"/systems/galeria/api/galeria.php?do=get", {
            params: {
                categoria_id: categoria,
                subcategoria_id: subCategoria,
                mes: dateArr,
                limit: false,
                empreendimento_id: window.rs_id_emp,
                token: window.token
            }
        }).then((response) => {
            response.data.map(item => {
                imageArr.push([item.diretorio])
            })
            setLoading(false)
            setImageArray(imageArr)
            setImage(response.data)
        }).catch((_error) => { })
    };

    // RENDERIZAR E TROCAR NOME DOS OBJETOS -> CATEGORIA
    const getCategory = async () => {
        let objCategoria = [];
        await axios.get(window.host+"/systems/galeria/api/galeria.php?do=get_categoria", {
            params: {
                empreendimento_id: window.rs_id_emp,
                ativo: 1,
                token: window.token,
            }
        }).then((response) => {
            setCategoriaTitle(response.data)
            response.data.map(item => {
                objCategoria.push({ value: item.id, label: item.nome })
            });
            setCategoria(objCategoria[0].value)
        })
        setOpCategoria(objCategoria)
    };

    // RENDERIZAR E TROCAR NOME DOS OBJETOS -> SUBCATEGORIA
    const getSubcategory = async () => {
        let objSubcategoria = [];
        await axios.get(window.host+"/systems/galeria/api/galeria.php?do=get_subcategoria", {
            params: {
                empreendimento_id: window.rs_id_emp,
                categoria_id: categoria,
                ativo: 1,
                token: window.token,
            }
        }).then((response) => {
            response.data.map(item => {
                objSubcategoria.push({ value: item.id, label: item.nome })
            });
            setSubCategoriaTitle(response.data)
            setSubCategoria(objSubcategoria[0].value)
        })
        setOpSubCategoria(objSubcategoria)
    };

    //FUNCÃO DE DELETAR IMAGEM 
    function deleteImage(id) {
        if (window.confirm("Deseja remover essa imagem?")) {
            axios.get(window.host+"/systems/galeria/api/galeria.php?do=delete", {
                params: {
                    id: id,
                    empreendimento_id: window.rs_id_emp,
                    token: window.token
                }
            }).then((_response) => {
                toast("Imagem removida com sucesso!");
                getImages();
            }).catch((_error) => { })
        }
    };

    //CLIQUE IMAGEM CATEGORIA
    const handleClickImage = (i) => {
        handleSetToggler(true);
        handleSetSources(imageArray, i)
    };

    //RENDERIZAR API 
    useEffect(() => {
        getCategory();
        getSubcategory();
    }, []);

    useEffect(() => {
        getImages();
    }, [categoria, subCategoria, date])

    const callback = () => {
        getImages();
    };

    // MANDA OS FILTROS PARA A INDEX
    useEffect(() => {
        handleSetFilters(
            <>
                <SelectReact
                    options={opCategoria}
                    placeholder="Categoria"
                    value={categoria}
                    onChange={(e) => setCategoria(e.value)}
                />
                <SelectReact
                    options={opSubCategoria}
                    placeholder="Subcategoria"
                    value={subCategoria}
                    onChange={(e) => setSubCategoria(e.value)}
                />
            </>
        );
    },[opCategoria, opSubCategoria, categoria, subCategoria]);

    return (
        <>
            <ModalCadastroImagem
                show={show}
                handleShow={handleShow}
                onHide={handleClose}
                callback={callback}
                categoriaImg={categoria}
                subcategoriaImg={subCategoria}
            />
            
            <Row>
                <Title>
                    {categoriaTitle ? categoriaTitle.map((item) => {
                        return (
                            <div key={'categoria_'+item.id}>
                                {item.id == categoria ? item.nome : ""}
                            </div>
                        )
                    }) : ""}
                    {(
                        subCategoria ?
                            <>
                                {subCategoriaTitle ? subCategoriaTitle.map((item) => {
                                    return (
                                        <div className="ps-2" key={'subcategoria_'+item.id}>
                                            {item.id == subCategoria ? ' - ' + item.nome : ""}
                                        </div>
                                    )
                                }) : ""}
                            </>
                            : ""
                    )}
                </Title>
                <Col lg={12} className="mb-4">
                    <>
                        {(
                            loading ?
                                <div className="container-load">
                                    <Loader show={true} />
                                </div>
                                :
                                <div className="galeria-container">
                                    {(
                                        image.length > 0 ?
                                            image.map((item, i) => {
                                                var url;
                                                if (item.diretorio.includes(process.env.REACT_APP_URL_UPLOAD+"/")) {
                                                    url = ""
                                                } else {
                                                    url = process.env.REACT_APP_URL_UPLOAD+"/"
                                                }
                                                return (
                                                    <CardGaleria
                                                        key={'galeria_'+item.id}
                                                        src={url + item.diretorio}
                                                        id={item.id}
                                                        onClick={() => handleClickImage(i)}
                                                        icon={
                                                            window.rs_permission_apl === 'master' ?
                                                                <Icon className="button-delete-galeria" type="delete" onClick={() => deleteImage(item.id)} />
                                                                : ""
                                                        }
                                                    />
                                                )
                                            })
                                            : ""
                                    )}
                                    <CardGaleria callModal onClick={handleShow} />
                                </div>
                        )}
                    </>
                </Col>
            </Row>
        </>
    )
}
