import { useEffect, useState, useContext } from "react";
import axios from "axios";
import Row from "../../../components/body/row/index";
import Col from "../../../components/body/col/index";
import Icon from "../../../components/body/icon/index";
import ActionSection from "../../../components/body/news/actionSection";
import NewsLiContainer from "../../../components/body/news/listNews/newsLiContainer";
import TextSuccess from "../../../components/body/news/listNews/textSuccess";
import BoxImageNews from "../../../components/body/news/listNews/boxImageNews";
import NewsHashtag from "../../../components/body/news/listNews/newsHashtags";
import TextNewsDivContainer from "../../../components/body/news/listNews/textNewsDivContainer";
import TitleNews from "../../../components/body/news/listNews/titleNews";
import MainImageNews from "../../../components/body/news/listNews/mainImageNews";
import CommentTitle from "../../../components/body/news/listNews/commentTitleNews";
import DescriptionParagraph from "../../../components/body/news/listNews/descriptionNews";
import SearchNewsDiv from "../../../components/body/news/buscaNews/searchNewsDiv";
import SearchNewsInput from "../../../components/body/news/buscaNews/searchNewsInput";
import SearchNewsTitle from "../../../components/body/news/buscaNews/searchNewsTitle";
import "./style.scss";
import ImageModal from "./modalImage";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "../../../_assets/css/swiper.scss";
import { GlobalContext } from "../../../context/Global";
import NewModal from "./newModal";
import SelectReact from "../../../components/body/select";
import parse from "html-react-parser";
import IframeNews from "../../../components/body/news/listNews/iframeNews";
import DocumentParagraph from "../../../components/body/news/listNews/documentParagraph";

export default function Lista(props) {

    const { handlePageError } = useContext(GlobalContext);

    //ESTADO QUE SALVA AS INFOMAÇÕES DA API
    const [loading, setLoading] = useState(true);
    const [infoApi, setInfoApi] = useState([]);

    //FILTRO DO STATUS NO TITULO
    const [status, setStatus] = useState(1);

    //BUSCAR POR HASHTAGS
    const [hashtag, setHashtag] = useState('');

    //MUDAR STATUS
    const handleStatus = (e) => {
        setStatus(e);
    };

    //ativo=" + status + "permissao=3&page=1&limit=15&&hashtag_filtro=&token=" + window.token,
    function getNews(loading) {
        if(loading){
            setLoading(true);
        }

        axios.get(window.host + "/homes/api/home3.php?do=cadastros&", {
            params: {
                ativo: status,
                //permissao: 3,
                page: 1,
                limit: 15, // limitado a 15, mas deveria aumentar conforme da scroll 
                hashtag_filtro: hashtag,
                //noticia_id_filtro: noticiaFiltro,
                token: window.token
            }
        }).then((response) => {
            setInfoApi(response.data);
            setLoading(false);
        }).catch(() => {
            handlePageError(true);
        })
    };

    //MOSTRAR AS INFORMAÇÕES DA API
    useEffect(() => {
        getNews(true)
    }, [status, hashtag]);


    //FUNÇÃO DE DELETAR NOTICIA
    function deleteNews(newsId) {
        if (window.confirm("Deseja remover essa noticía?")) {
            axios({
                url: window.host + "/homes/api/home3.php?do=removerNews&id=" + newsId + "&token=" + window.token,
                method: "post",
                data: { id: newsId },
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(() => {
                alert("Noticia removida com sucesso!");
                getNews(false);
            }).catch((_error) => {
            })
        }
    };

    //ESTADO QUE MUDA QUANDO TEM MAIS DE UMA IMAGEM
    const [swiper, setSwiper] = useState();

    //MODAL PARA ABRIR A IMAGEM
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(!show)

    //MODAL PARA ADICIONAR NOTICIAS
    const [showNew, setShowNew] = useState(false);
    const [reload, setReload] = useState(false);
    const handleShowNew = () => setShowNew(true);

    //FECHAR MODAL 
    const handleCloseNew = (e) => {
        setShowNew(e);
        getNews(false);
        setReload(true);
        setTimeout(() => {
            setReload(false);
        }, 500)
    };

    function editShowClose(id) {
        setEditId(id);
        handleShowNew();
    };

    //ESTADO QUE SALVA ID DA NOTICIA QUE VAI SER EDITADA
    const [editId, setEditId] = useState();

    useEffect(() => {
        if (editId)
            handleShowNew();
    }, [editId]);

    //FUNÇÃO QUE MANDA O ID 0 PRO MODAL NOVO/EDITAR
    function openNew() {
        setEditId();
        handleShowNew();
    };

    return (
        <div className={'mainContainer' + ' ' + (props?.widget ? 'my-0' : '')}>
            <Row justifyContent="center" className="rowNews">
                <Col>
                    <NewModal
                        showNew={showNew}
                        handleShowNew={handleShowNew}
                        id={editId}
                        onHide={handleCloseNew}
                    />
                    <TitleNews
                        overflow={true}
                        display="block"
                        // filter={
                        //     hashtag.length > 0 ? '' :
                        //         <SelectReact
                        //             value={status}
                        //             options={[
                        //                 { value: 1, label: "Ativas" },
                        //                 { value: 2, label: "Inativas" }
                        //             ]}
                        //             onChange={(e) => handleStatus(e.value)}
                        //         />
                        // }
                        icon={<Icon type="plus" onClick={() => openNew()} />} //handleShowNew
                    >
                        Notícias {hashtag.length > 0 ? "#" + hashtag : ''}
                        {/* {(
                            hashtag ?
                                <div>#{hashtag}
                                    <button onClick={setHashtag('')}>x</button>
                                </div>
                                : ""
                        )} */}
                    </TitleNews>
                    <ul className="ps-0">
                        {(!loading ?
                            (infoApi.length > 0 ?
                                infoApi.map((item, index) => {
                                var imagemTratada = (item.img).split(",");
                                imagemTratada.forEach((index) => {
                                    (imagemTratada.slice(index, index + 1));
                                });

                                var published= new Date(item.data_publicacao).toLocaleDateString([], {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit'
                                }).replaceAll(",", "");

                                var expired= new Date(item.data_saida).toLocaleDateString([], {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit'
                                }).replaceAll(",", "");                        

                                var corrigirUrlVideo;
                                var corrigirUrlVideo2;
                                if (item.video) {
                                    if (item.video.includes("youtube")) {
                                        if (item.video.includes("watch?v=")) {
                                            corrigirUrlVideo = (item.video).split("watch?v=");
                                            corrigirUrlVideo2 = corrigirUrlVideo[0] + "embed/" + corrigirUrlVideo[1];
                                        }
                                    }
                                }

                                return (
                                    <NewsLiContainer key={index} widget={props.widget}>
                                        <div className="buttonDeleteDiv">
                                            {(!props.widget ? 
                                                <div className="buttonDelete">
                                                    <Icon
                                                        type="trash"
                                                        animated
                                                        onClick={() => deleteNews(item.id)}
                                                    />
                                                </div>
                                            :'')}
                                            <TextSuccess>
                                                <div>
                                                    <Icon className="text-success" type="calendar" />
                                                    Publicado: {published}
                                                </div>
                                                {(
                                                    item.data_saida === null ? " "
                                                        :
                                                        <div className="text-danger">
                                                            <Icon className="text-danger" type="calendar" />
                                                            Expira em: {expired}
                                                        </div>
                                                )}
                                            </TextSuccess>
                                        </div>
                                        {(
                                            item.video ?
                                                <IframeNews
                                                    src={corrigirUrlVideo2 ? corrigirUrlVideo2 : item.video}
                                                    title="YouTube video player"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    allowFullScreen
                                                >
                                                </IframeNews>
                                                : ""
                                        )}
                                        {(
                                            item.documento ?
                                                <>
                                                    <iframe
                                                        title={item.id}
                                                        className="documentoDiv" src={"https://docs.google.com/gview?url="+process.env.REACT_APP_URL_UPLOAD+"/" + item.documento + "&embedded=true"}
                                                    >
                                                    </iframe>
                                                    <div>
                                                        <DocumentParagraph>
                                                            <a href={process.env.REACT_APP_URL_UPLOAD+"/" + item.documento} target="_blank" rel="noreferrer">
                                                                Clique aqui</a> caso o documento não seja exibido.
                                                        </DocumentParagraph>
                                                    </div>
                                                </>
                                                : ""
                                        )}
                                        <BoxImageNews>
                                            {(
                                                item.img ?
                                                    <>
                                                        <ImageModal show={show} handleShow={handleShow} img={item.img} nome={item.nome} />
                                                        {(imagemTratada.length <= 1 ? <MainImageNews src={imagemTratada[0] ? process.env.REACT_APP_URL_UPLOAD+"/" + imagemTratada[0] : window.host+"/systems/news-react/"} alt={item.nome} onClick={() => handleShow()} />
                                                            : <Swiper
                                                                ref={swiper}
                                                                autoHeight={false}
                                                                spaceBetween={15}
                                                                onSwiper={(swiper) => setSwiper(swiper)}
                                                                modules={[Navigation]}
                                                                navigation
                                                                allowTouchMove={true}
                                                                slidesPerView={1}
                                                                slidesPerGroup={1}
                                                            >{imagemTratada.map((image) => {
                                                                return (
                                                                    <SwiperSlide>
                                                                        <MainImageNews src={image ? process.env.REACT_APP_URL_UPLOAD+"/" + image : window.host+"/systems/news-react/"} alt={item.nome} onClick={() => handleShow()} />
                                                                    </SwiperSlide>
                                                                )
                                                            })}
                                                            </Swiper>
                                                        )}
                                                    </>
                                                    : ""
                                            )}
                                        </BoxImageNews>
                                        <TextNewsDivContainer>
                                            <CommentTitle>
                                                {item.nome}
                                                <Icon
                                                    type="editar"
                                                    animated
                                                    onClick={() => editShowClose(item.id)}
                                                />
                                            </CommentTitle>
                                            {(
                                                item.hashtags ?
                                                    <NewsHashtag>
                                                        {item.hashtags}
                                                    </NewsHashtag>
                                                    : ""
                                            )}
                                            <DescriptionParagraph>
                                                {parse(item.descricao)}
                                            </DescriptionParagraph>
                                        </TextNewsDivContainer>
                                        <ActionSection item={item} />
                                    </NewsLiContainer>
                                )
                                }) 
                            :
                                <div className="erroHashtag">
                                    <Icon type="sad" className="erroHashtagIcon" />
                                    <h3 className="erroHashtagTitle">Não encontramos nenhum resultado</h3>
                                    <p>Certifique-se de que tudo está escrito corretamente ou tente hashtags diferentes</p>
                                </div>
                            )
                        :
                            [...Array(5)].map((card, i) => (
                                <NewsLiContainer loading={true}></NewsLiContainer>
                            ))
                        )}
                    </ul>
                </Col>

                {(!props.widget ?
                    <Col>
                        <SearchNewsTitle>
                            Busca
                        </SearchNewsTitle>
                        <SearchNewsDiv>
                            <label>
                                #
                            </label>
                            <SearchNewsInput
                                type="text"
                                name="hashtag"
                                placeholder="Busca por Hashtag"
                                className="inputBuscaNews"
                                value={hashtag}
                                onChange={e => (setHashtag(e.target.value), setStatus(1))}
                            />
                        </SearchNewsDiv>
                    </Col>
                :'')}
            </Row>
        </div >
    )
}