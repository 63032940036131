import { useContext, useEffect, useState } from "react";
import Col from "../../../components/body/col";
import Icon from "../../../components/body/icon";
import Row from "../../../components/body/row";
import Table from "../../../components/body/table";
import Tbody from "../../../components/body/table/tbody";
import Thead from "../../../components/body/table/thead";
import Th from "../../../components/body/table/thead/th";
import Tr from "../../../components/body/table/tr";
import { GlobalContext } from "../../../context/Global";
import axios from "axios";
import Td from "../../../components/body/table/tbody/td";
import { cd, get_date } from "../../../_assets/js/global";
import ModalComentarios from "../Lista/Item/Detalhes/modal/modalComentarios";
import { useNavigate } from "react-router";

export default function Relatorio() {
    // NAVIGATE
    const navigate = useNavigate();

    // CONTEXT
    const { handleSetFilter } = useContext(GlobalContext);   

    // ESTADOS
    const [obras, setObras] = useState([]);
    const [options, setOptions] = useState([]);
    const [reload, setReload] = useState(false);
    const [showCometarios, setShowCometarios] = useState(false);
    const [job, setJob] = useState('');

    // FILTROS
    const [filterLoja, setFilterLoja] = useState([]);
    const [filterTipo, setFilterTipo] = useState([]);
    const [filterCategoria, setFilterCategoria] = useState([]);
    const [filterSubcategoria, setFilterSubcategoria] = useState([]);
    const [filterFormato, setFilterFormato] = useState([]);
    const [filterQualificacao, setFilterQualificacao] = useState([]);
    const [filterStatus, setFilterStatus] = useState([]);
    const [dataInicioStart, setDataInicioStart] = useState('');
    const [dataInicioEnd, setDataInicioEnd] = useState('');
    const [dataPrevisaoStart, setDataPrevisaoStart] = useState('');
    const [dataPrevisaoEnd, setDataPrevisaoEnd] = useState('');
    const [dataCarenciaStart, setDataCarenciaStart] = useState('');
    const [dataCarenciaEnd, setDataCarenciaEnd] = useState('');
    const [dataInauguracaoStart, setDataInauguracaoStart] = useState('');
    const [dataInauguracaoEnd, setDataInauguracaoEnd] = useState('');

    // HABILITA O LOADING DOS CARDS PARA QUANDO VOLTAR PRA TELA DO CALENDÁRIO
    useEffect(() => {
        handleSetFilter(true);
    },[]);

    // GET OBRAS
    const handleSetObras = (e) => {
        setObras(e);
    }

    // GET OPTIONS
    useEffect(() => {
        if(options.length == 0){
            axios({
                url: window.host + "/systems/integration-react/api/registry.php?do=get_reportFilters&token=" + window.token,
                method: "get",
                params:{
                    filter_id_apl_default: 0
                }
            }).then((response) => {
                if(response.data){
                    setOptions(response.data);
                }
            })
        }
    },[]);

    // OPTIONS FILTRO DE TIPOS
    const optionsTipos = [
        {value: 'file', label: 'Arquivo'},
        {value: 'document', label: 'Documento'},
        {value: 'vistory_initial', label: 'Vistoria Inicial'},
        {value: 'vistory_intermediate', label: 'Vistoria Intermediária'},
        {value: 'vistory_final', label: 'Vistoria Final'}
    ]

    // FILTRO DE TIPOS
    const handleFilterTipo = (e) => {
        setFilterTipo(e);
    }

    // FILTRO DE LOJA
    const handleFilterLoja = (e) => {
        setFilterLoja(e);
    }

    // FILTRO DE CATEGORIA
    const handleFilterCategoria = (e) => {
        setFilterCategoria(e);
    }

    // FILTRO DE SUBCATEGORIA
    const handleFilterSubcategoria = (e) => {
        setFilterSubcategoria(e);
    }

    // FILTRO DE FORMATO
    const handleFilterFormato = (e) => {
        setFilterFormato(e);
    }

    // FILTRO DE QUALIFICAÇÃO
    const handleFilterQualificacao = (e) => {
        setFilterQualificacao(e);
    }

    // FILTRO DE STATUS
    const handleFilterStatus = (e) => {
        setFilterStatus(e);
    }

    // FILTRA DATA DE INÍCIO (START)
    const handleSetDataInicioStart = (e) => {
        setDataInicioStart(e);
        handleSetFilter(true);
    }
    
    // FILTRA DATA DE INÍCIO (END)
    const handleSetDataInicioEnd = (e) => {
        setDataInicioEnd(e);
        handleSetFilter(true);
    }

    // FILTRA DATA DE PREVISÃO (START)
    const handleSetDataPrevisaoStart = (e) => {
        setDataPrevisaoStart(e);
        handleSetFilter(true);
    }

    // FILTRA DATA DE PREVISÃO (END)
    const handleSetDataPrevisaoEnd = (e) => {
        setDataPrevisaoEnd(e);
        handleSetFilter(true);
    }

    // FILTRA DATA DE CARÊNCIA (START)
    const handleSetDataCarenciaStart = (e) => {
        setDataCarenciaStart(e);
        handleSetFilter(true);
    }

    // FILTRA DATA DE CARÊNCIA (END)
    const handleSetDataCarenciaEnd = (e) => {
        setDataCarenciaEnd(e);
        handleSetFilter(true);
    }

    // FILTRA DATA DE INAUGURAÇÃO (START)
    const handleSetDataInauguracaoStart = (e) => {
        setDataInauguracaoStart(e);
        handleSetFilter(true);
    }

    // FILTRA DATA DE INAUGURAÇÃO (END)
    const handleSetDataInauguracaoEnd = (e) => {
        setDataInauguracaoEnd(e);
        handleSetFilter(true);
    }

    // ABRE MODAL DE COMENTÁRIOS
    function handleShowCometarios(job){
        setShowCometarios(!showCometarios);
        setJob(job);
    }

    // FECHA MODAL COMENTÁRIOS
    const handleCloseCometarios = (e) => {
        setShowCometarios(e);
        setReload(true);
        setTimeout(() => {
            setReload(false);
        }, 500);
    }

    return (
        <>
            <ModalComentarios
                show={showCometarios}
                handleShow={handleShowCometarios}
                onHide={handleCloseCometarios}
                interaction={false}
                job={job}
            />
            
            <Row>
                <Col lg={12}>
                    <Table
                        id="obras"
                        api={window.host+"/systems/integration-react/api/list.php?do=get_list&token="+window.token}
                        params={
                            {
                                filter_type: '5',
                                filter_parent: true,
                                filter_type_registry: 'document', // CRAVADO POIS RETIRARAM AS VISTORIAS
                                filter_id_store: (window.rs_id_lja && window.rs_id_lja > 0 ? window.rs_id_lja : filterLoja),
                                filter_category: filterCategoria,
                                filter_subcategory: filterSubcategoria,
                                filter_date_start_start: (dataInicioStart ? cd(dataInicioStart) : ''),
                                filter_date_start_end: (dataInicioEnd ? cd(dataInicioEnd) : ''),
                                filter_date_prevision_start: (dataPrevisaoStart ? cd(dataPrevisaoStart) : ''),
                                filter_date_prevision_end: (dataPrevisaoEnd ? cd(dataPrevisaoEnd) : ''),
                                filter_date_lack_start: (dataCarenciaStart ? cd(dataCarenciaStart) : ''),
                                filter_date_lack_end: (dataCarenciaEnd ? cd(dataCarenciaEnd) : ''),
                                filter_date_opening_start: (dataInauguracaoStart ? cd(dataInauguracaoStart) : ''),
                                filter_date_opening_end: (dataInauguracaoEnd ? cd(dataInauguracaoEnd) : ''),
                                filter_id_format: filterFormato,
                                filter_id_qualification: filterQualificacao,
                                filter_id_status: filterStatus                             
                            }
                        }
                        text_limit={false}
                        onLoad={handleSetObras}
                        reload={reload}
                    >
                        <Thead className="obras-lista-head">
                            <Tr>
                                {/* <Th
                                    items={optionsTipos}
                                    onChange={handleFilterTipo}
                                >
                                    Tipo
                                </Th> */}

                                <Th            
                                    items={options.subcategory}                        
                                    onChange={handleFilterSubcategoria}
                                >
                                    Documento
                                </Th>

                                <Th            
                                    items={options.category}                        
                                    onChange={handleFilterCategoria}
                                >
                                    Categoria
                                </Th>

                                <Th
                                    name="loja"
                                    api={window.host + '/api/sql.php?do=select&component=loja&filial=true&token=' + window.token}
                                    onChange={handleFilterLoja}
                                >
                                    Loja
                                </Th>

                                <Th
                                    type="date"
                                    start={{ value: dataInicioStart, onChange: handleSetDataInicioStart }}
                                    end={{ value: dataInicioEnd, onChange: handleSetDataInicioEnd }}
                                    align="center"
                                >
                                    Data Início
                                </Th>

                                <Th
                                    type="date"
                                    start={{ value: dataPrevisaoStart, onChange: handleSetDataPrevisaoStart }}
                                    end={{ value: dataPrevisaoEnd, onChange: handleSetDataPrevisaoEnd }}
                                    align="center"
                                >
                                    Data Previsão
                                </Th>

                                <Th
                                    type="date"
                                    start={{ value: dataCarenciaStart, onChange: handleSetDataCarenciaStart}}
                                    end={{ value: dataCarenciaEnd, onChange: handleSetDataCarenciaEnd }}
                                    align="center"
                                >
                                    Data Carência
                                </Th>

                                <Th
                                    type="date"
                                    start={{ value: dataInauguracaoStart, onChange: handleSetDataInauguracaoStart}}
                                    end={{ value: dataInauguracaoEnd, onChange: handleSetDataInauguracaoEnd }}
                                    align="center"
                                >
                                    Data Inauguração
                                </Th>

                                <Th
                                    items={options.format}
                                    onChange={handleFilterFormato}
                                >
                                    Formato
                                </Th>

                                <Th
                                    items={options.qualification}
                                    onChange={handleFilterQualificacao}
                                >
                                    Qualificação
                                </Th>

                                <Th
                                    items={options.status}
                                    onChange={handleFilterStatus}
                                >
                                    Status
                                </Th>      

                                <Th width={1} align="center">Ações</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {(obras.length > 0 ?
                                obras.map((obra, i) => {
                                    let tipo;

                                    if(obra?.tipo_registro == 'document'){
                                        tipo = 'Documento';
                                    }else if(obra?.tipo_registro == 'vistory_initial'){
                                        tipo = 'Vistoria Inicial';
                                    }else if(obra?.tipo_registro == 'vistory_intermediate'){
                                        tipo = 'Vistoria Intermediária';
                                    }else if(obra?.tipo_registro == 'vistory_final'){
                                        tipo = 'Vistoria Final';
                                    }else if(obra?.tipo_registro == 'file'){
                                        tipo = 'Arquivo';
                                    }else{
                                        tipo = '';
                                    }

                                    return(
                                        <Tr key={obra?.id_job_status}>
                                            {/* <Td>{tipo}</Td> */}
                                            <Td>{obra?.subcategoria}</Td>
                                            <Td>{obra?.categoria}</Td>                                            
                                            <Td>{obra?.loja}</Td>
                                            <Td align="center">{(obra?.data_inicio_parent ? get_date('date', obra?.data_inicio_parent, 'date_sql') : '')}</Td>
                                            <Td align="center">{(obra?.data_previsao_parent ? get_date('date', obra?.data_previsao_parent, 'date_sql') : '')}</Td>
                                            <Td align="center">{(obra?.data_carencia_parent ? get_date('date', obra?.data_carencia_parent, 'date_sql') : '')}</Td>
                                            <Td align="center">{(obra?.data_inauguracao_parent ? get_date('date', obra?.data_inauguracao_parent, 'date_sql') : '')}</Td>
                                            <Td>{obra?.mov_formato}</Td>
                                            <Td>{obra?.mov_qualificacao}</Td>
                                            <Td>{obra?.mov_status}</Td>                                            
                                            <Td width={1} align="center">
                                                <Icon
                                                    type="view"
                                                    title="Visualizar"
                                                    animated
                                                    onClick={() => handleShowCometarios(obra)}
                                                />
                                            </Td>
                                        </Tr>
                                    )
                                })
                            :
                                <></>
                            )}
                        </Tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
}
