import { useState, useEffect } from "react"
import Button from "../../../components/body/button";
import Form from "../../../components/body/form";
import Input from "../../../components/body/form/input";
import Textarea from "../../../components/body/form/textarea";
import Modal from "../../../components/body/modal";
import ModalBody from "../../../components/body/modal/modalBody";
import ModalHeader from "../../../components/body/modal/modalHeader";
import ModalTitle from "../../../components/body/modal/modalHeader/modalTitle";
import CheckboxGroup from "../../../components/body/form/checkboxGroup";
import axios from "axios";
import SelectReact from "../../../components/body/select";
import { cd } from "../../../_assets/js/global";

export default function ModalListaVM({ show, handleShow, onHide, campanhaId, status }) {

    //VARIAVEL FECHAR MODAL
    const handleCloseModal = () => {
        onHide(false);
    };

    //ESTADO FILTRO LOJA
    const [statusCampanha, setStatusCampanha] = useState('');
    const [titulo, setTitulo] = useState('');
    const [dataInicio, setDataInicio] = useState('');
    const [lojas, setLojas] = useState('');
    const [anexo, setAnexo] = useState('');
    const [descricao, setDescricao] = useState('');
    //ESTADOS DO CHECKBOX
    const [optionLojas, setOptionLojas] = useState([]);

    // MOSTRAR AS OPÇÕES LOJAS
    useEffect(() => {
        if (optionLojas.length == 0) {
            axios({
                method: "get",
                url: window.host + '/api/sql.php?do=select&component=loja&filial=true&token=' + window.token
            }).then((response) => {
                setOptionLojas(response.data);
            });
        };
    });

    //OPÇÕES DE AÇÃO CAMPANHA
    const options_campanha = [
        { value: '0', label: "Em andamento" },
        { value: '1', label: "Finalizar" },
        { value: '2', label: "Suspender" }
    ];

    // SELECIONAR LOJAS
    function handleSetLoja(e) {
        setLojas(e);
    };

    //MOSTRAR AS INFORMAÇÕES DA API NOS INPUTS
    function getEditCampanha() {
        axios({
            url: window.host+"/systems/vm/api/campanha_react.php?do=get&campanha_id=" + campanhaId + "&token=" + window.token,
            method: "get",
            data: {
                campanha_id: campanhaId,
                status_campanha: status
            },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }).then((response) => {
            setStatusCampanha(response.data.payload.status);
            setTitulo(response.data.payload.titulo);
            setDataInicio(new Date(response.data.payload.data_inicio));
            setAnexo(response.data.payload.anexo); // --> tem anexos e anexo
            setDescricao(response.data.payload.descricao);
        })
    };

    useEffect(() => {
        getEditCampanha();
    }, [campanhaId]);

    const data = {
        id: campanhaId,
        status_campanha: statusCampanha,
        anexos_aux: '', // envia algo no dev, mas não sei o que
        titulo: titulo,
        data_inicio: (dataInicio ? cd(dataInicio) : ''),
        filtro_checkbox_item: "",
        lojas: lojas,
        anexo: anexo,
        descricao: descricao
    };

    function resetForm() {
        handleCloseModal();
        setTitulo('');
        setDataInicio('');
        setLojas('');
        setAnexo('');
        setDescricao('');
    };

    // SETAR ANEXO
    const handleSetAnexo = (response) => {
        setAnexo(response[0]);
    };

    return (
        <>
            <Modal show={show} onClick={handleShow} onHide={handleCloseModal}>
                <ModalHeader>
                    <ModalTitle> {campanhaId ? "Editar Campanha" : "Nova Campanha"}</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Form
                        api={window.host+"/systems/vm/api/campanha_novo.php?do=" + (campanhaId ? "update" : "insert") + "&token=" + window.token}
                        data={data}
                        formData={true}
                        callback={resetForm}
                    >
                        {(
                            campanhaId ?
                                <SelectReact
                                    name="status-campanha"
                                    label="Ação Campanha"
                                    value={statusCampanha.toString()} // -> único jeito de mostrar o label no input
                                    onChange={(e) => setStatusCampanha(e.value)}
                                    options={options_campanha}
                                />
                                : ""
                        )}
                        <Input
                            type="text"
                            label="Título"
                            name="titulo"
                            icon="letter"
                            value={titulo}
                            onChange={(e) => setTitulo(e.target.value)} />
                        <Input
                            type="date"
                            label="Data início"
                            icon="calendar"
                            value={dataInicio}
                            onChange={(e) => setDataInicio(e)}
                            required={false}
                        />
                        {(
                            campanhaId ? ""
                                :
                                <CheckboxGroup
                                    group="loja"
                                    callback={handleSetLoja}
                                    required={false}
                                />
                        )}
                        <Input
                            type="file"
                            label="Anexo"
                            value={anexo}
                            multiple={true}
                            required={false}
                            callback={handleSetAnexo}
                        />
                        <Textarea
                            label="Descrição"
                            name="descricao"
                            value={descricao}
                            onChange={(e) => setDescricao(e.target.value)}
                        />
                        <Button type="submit">Enviar</Button>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
};