import './cancelar.scss';

import { useState, useContext, useEffect } from 'react';

import Modal from '../../../body/modal';
import ModalHeader from '../../../body/modal/modalHeader';
import ModalTitle from '../../../body/modal/modalHeader/modalTitle';
import ModalBody from '../../../body/modal/modalBody';
import Icon from '../../icon';
import Form from '../../form';
import Textarea from '../../form/textarea';
import Button from '../../button';

import { GlobalContext } from "../../../../context/Global";

export default function CancelarCard(props){
    // CONTEXT
    const { filterModule, refreshCalendar, loadingCalendar } = useContext(GlobalContext);
    
    const [showModal, setShowModal] = useState(false);
    const [observacao, setObservacao] = useState('');

    const data = {
        ativ_desc: 'Cancelou o '+(props.chamados?'chamado':'job'),
        id_job: props.id_job,
        id_mov: props.id_job_status,
        status: 6,
        msg: observacao,
        data_aux: undefined,
        tipo: 'next',
        tipo_fase: 'Check',
    }

    // FECHAR MODAL DO FORM
    function handleCloseModal(){
        setShowModal(false);
        setObservacao('');
        
        props?.callback(props?.id_job_status);
    }

    return(
        <>
            {/* MODAL DE CANCELAR */}
            {(props.motivo !== false ?
                <Modal show={ showModal } onHide={() => handleCloseModal()}>
                    <ModalHeader>
                        <ModalTitle>
                            {(props.modalTitle?props.modalTitle:'Motivo')}
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <Form
                            api={window.host+'/systems/integration-react/api/list.php?do=set_status&filter_id_module='+filterModule+'&token='+window.token}
                            data={data}
                            toast="Card cancelado"
                            callback={() => handleCloseModal()}                        
                        >                            
                            <Textarea name="motivo" placeholder="Observação" value={observacao} onChange={(e) => setObservacao(e.target.value)} />

                            <Button type="submit">
                                Salvar
                            </Button>
                        </Form>
                    </ModalBody>
                </Modal>
            :'')}

            <Icon
                type={(props.icon ? props.icon : 'reprovar')}
                title={(props.title ? props.title : 'Cancelar')}
                onClick={() => setShowModal(true)}
            />
        </>
    )
}
