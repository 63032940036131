import { useState, useEffect } from 'react';

import Gerenciador from '../../../../components/body/gerenciador';
import Input from '../../../../components/body/form/input';
import InputContainer from '../../../../components/body/form/inputcontainer';
import Table from '../../../../components/body/table';
import Tbody from '../../../../components/body/table/tbody';
import Tr from '../../../../components/body/table/tr';
import Td from '../../../../components/body/table/tbody/td';
import Form from '../../../../components/body/form';
import Icon from '../../../../components/body/icon';
import Button from '../../../../components/body/button';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import Switch from '../../../../components/body/switch';
import { scrollToCol } from '../../../../_assets/js/global';

export default function Categorias({id_grupo, emp, id_apl, callback, disabled, show, fases, chamados, visitas}){
    // ESTADOS
    const [items, setItems] = useState([]);
    const [active, setActive] = useState(null);
    const [filter, setFilter] = useState(null);
    const [filterInactive, setFilterInactive] = useState(false);
    const [edit, setEdit] = useState(false);
    const [buttonStatus, setButtonStatus] = useState('');

    // ESTADOS DO FORM
    const [reload, setReload] = useState(false);
    const [id, setId] = useState('');
    const [nome, setNome] = useState('');
    const [todosSistemas, setTodosSistemas] = useState(true);
    const [categoriaGrupo, setCategoriaGrupo] = useState(false);

    // SETA CATEGORIA ATIVA
    function handleSetActive(id){
        let id_aux = (active == id ? '' : id); // VERIFICA SE O ITEM ESTÁ ATIVO PARA MANDAR O ID VAZIO E RESETAR O FILTRO SE FOR O CASO

        setActive(id_aux);
        setEdit(false);

        if(callback){
            callback({
                active: id_aux
            })
        }

        // SCROLL AUTOMÁTICO ATÉ A COLUNA DE USUÁRIOS
        scrollToCol('subcategoria');
    }

    // RESETA O FILTRO E O ITEM ATIVO CASO TROQUE O GRUPO
    useEffect(() => {
        setActive(null);
        setFilter(null);
        setEdit(false);
    },[emp]);

    // CALLBACK DA API DA TABLE
    const handleSetItems = (e) => {
        setItems(e)
    }

    // RESETAR FORM
    function reset_form(){
        setId('');
        setNome('');
        setTodosSistemas(true);
        setCategoriaGrupo(false);
    }

    // AÇÕES AO ABRIR FORM DE CADASTRO
    const handleOpenForm = (id, nome, id_apl, id_grupo) => {
        if(id){
            setEdit(id);
            setId(id);
            setNome(nome);
            setTodosSistemas(id_apl == 1 ? true :false);
            setCategoriaGrupo(id_grupo ? true : false);
        }else{
            setEdit(true);
            reset_form();
        }

        // SCROLL AUTOMÁTICO ATÉ O FORMULÁRIO DE EDIÇÃO
        scrollToCol('categoria_insert');

        callback({
            edit: true
        })
    }

    // AÇÕES AO FECHAR FORM DE CADASTRO
    const handleCloseForm = () => {
        setEdit(false);
        reset_form();

        callback({
            edit: false
        });
    }

    // AÇÕES AO ENVIAR FORM DE CADASTRO
    const handleSendForm = () => {
        setId('');
        setNome('');        
        setEdit(false);
    }

    // VERIFICA STATUS DO FORM
    const handleFormStatus = (e) => {
        setButtonStatus(e);
    }

    // RECARREGA LISTA AO RECEBER O RETORNO DA API DE CADASTRO
    const handleReloadForm = (e) => {
        setReload(!reload);
    }

    // ATIVAR / DESATIVAR ITEM
    const handleSetItemActive = (id, ativo) => {
        toast('Categoria ' + (ativo ? 'ativada' : 'desativada'));

        axios({
            method: 'post',
            url: window.host + "/systems/integration-react/api/registry.php?do=set_categoryActive&token=" + window.token,
            data: {
                id: id,
                active: ativo
            },
            headers: { "Content-Type": "multipart/form-data" }
        }).then(() => {
            // handleReloadForm();
        })
    }

    // FILTRO DE INATIVOS
    const handleSetFilterInactive = () => {
        setFilterInactive(!filterInactive);
        handleReloadForm();
    }

    // TOAST
    const handleToast = () => {
        if(id){
            return 'Categoria editada com sucesso!';
        }else{
            return 'Categoria cadastrada com sucesso!';
        }
    }

    // DATA FORM
    const data = {
        id: id,
        id_apl: todosSistemas ? 1 : (id_apl ? id_apl : window.rs_id_apl),
        para: (categoriaGrupo ? 2 : undefined),
        empreendimento_id: (categoriaGrupo ? undefined : emp),
        name: nome
    }

    return(
        <>
            <Gerenciador 
                id="sistema"
                title="Categorias"
                search={
                    <Input
                        name="filter_categoria"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                    />
                }
                icon={
                    <Icon
                        type="new"
                        title="Nova categoria"
                        onClick={() => handleOpenForm()}
                    />
                }
                switch={
                    <Input
                        type={'checkbox'}
                        name="categorias_inativas"
                        label="Inativas"
                        inverse={true}
                        onClick={handleSetFilterInactive}
                    />
                }
                disabled={disabled}
            >
                <Table
                    id="table_categoria"
                    api={window.host + "/systems/integration-react/api/registry.php?do=get_category&token=" + window.token}
                    params={{
                        filter_active: (filterInactive ? [0,1] : [1]),
                        empreendimento_id: emp,
                        id_grupo: id_grupo,
                        id_apl: (id_apl ? id_apl : undefined)
                    }}
                    onLoad={handleSetItems}
                    reload={reload + emp + (id_apl ? id_apl : '')}
                    text_limit={(window.isMobile ? 20 : 30)}
                >
                    <Tbody>
                        {(items.length > 0 ?
                            items.filter((item) => {
                                if (!filter) return true;
                                if (item.label.toLowerCase().includes(filter.toLocaleLowerCase())) {
                                    return true
                                }
                            }).map((item, i) => {
                                let sistemas = '';

                                if(item?.id_apl == 1){
                                    sistemas = '(Todos os sistemas)';
                                }else{
                                    if(item?.id_apl == 223){
                                        sistemas = '(Jobs)';
                                    }else if(item?.id_apl == 224){
                                        sistemas = '(Chamados)';
                                    }else if(item?.id_apl == 225){
                                        sistemas = '(Fases)';
                                    }else if(item?.id_apl == 226){
                                        sistemas = '(Visitas)';
                                    }else if(item?.id_apl == 227){
                                        sistemas = '(Obras)';
                                    }else if(item?.id_apl == 229){
                                        sistemas = '(Comunicados)';
                                    }else if(item?.id_apl == 230){
                                        sistemas = '(Checklist)';
                                    }else if(item?.id_apl == 231){
                                        sistemas = '(Notificações)';
                                    }
                                }

                                return(
                                    <Tr
                                        key={'categoria_'+item.value}
                                        cursor="pointer"
                                        active={(active === item.value ? true : false)}
                                    >
                                        <Td
                                            onClick={() => handleSetActive(item.value)}
                                        >
                                            {item.label} <span className="text-secondary">{sistemas}</span>
                                        </Td>

                                        <Td width={1} align="center">
                                            <Icon 
                                                type={'edit'}
                                                active={(edit === item.value ? true : false)}
                                                onClick={() => handleOpenForm(item?.value, item?.label, item?.id_apl, item?.id_grupo)}
                                                animated
                                            />

                                            <Switch
                                                type="check"
                                                title={(item.ativo == 1 ? 'Desativar' : 'Ativar')}
                                                checked={(item.ativo == 1 ? true : false)}
                                                onChange={() => handleSetItemActive(item?.value, (item.ativo == 1 ? 0 : 1))}
                                                animated
                                            />
                                        </Td>
                                    </Tr>
                                )
                            })
                        :
                            <></>
                        )}
                    </Tbody>
                </Table>
            </Gerenciador>

            {/* FORMULÁRIO DE CADASTRO/EDIÇÃO */}
            {(edit ? 
                <Gerenciador 
                    id="categoria_insert"
                    title={(id ? ('Editar '+nome) : 'Nova Categoria')}
                    search={false}
                    icon={
                        <Icon
                            type="reprovar"
                            title="Fechar"
                            onClick={handleCloseForm}
                        />
                    }
                    disabled={disabled}
                >
                    <Form
                        api={window.host+"/systems/integration-react/api/registry.php?do=set_category&token=" + window.token}
                        data={data}
                        status={handleFormStatus}
                        response={handleReloadForm}
                        callback={() => handleSendForm(true)}
                        toast={handleToast}
                    >
                        <Input
                            type="text"
                            name="categoria_nome"
                            label="Nome"
                            value={nome}
                            onChange={(e) => setNome(e.target.value)}
                        />

                        <InputContainer>
                            <Switch
                                id="todos_sistemas"
                                name="todos_sistemas"
                                label="Disponível em todos os sistemas:"
                                checked={todosSistemas}
                                onChange={() => setTodosSistemas(!todosSistemas)}
                            />
                        </InputContainer>

                        {(window.rs_id_grupo ?
                            <InputContainer>
                                <Switch
                                    id="categoria_grupo"
                                    name="categoria_grupo"
                                    label="Disponível para todos do grupo:"
                                    checked={categoriaGrupo}
                                    onChange={() => setCategoriaGrupo(!categoriaGrupo)}
                                />
                            </InputContainer>
                        :'')}

                        <Button
                            type="submit"
                            status={buttonStatus}
                        >
                            Salvar
                        </Button>
                    </Form>
                </Gerenciador>
            :'')}
        </>
    )
}
