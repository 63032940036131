import { useState, useContext } from "react";

import Table from "../../../components/body/table";
import Thead from "../../../components/body/table/thead";
import Th from "../../../components/body/table/thead/th";
import Tr from "../../../components/body/table/tr";
import Tbody from "../../../components/body/table/tbody";
import Td from "../../../components/body/table/tbody/td";
import Row from '../../../components/body/row';
import Col from '../../../components/body/col';
import { useEffect } from "react";
import SelectReact from '../../../components/body/select';
import Input from "../../../components/body/form/input";
import { get_date } from "../../../_assets/js/global";
import { GlobalContext } from "../../../context/Global";
import Icon from "../../../components/body/icon";

export default function Contrato({filters}){
    // GLOBAL CONTEXT
    const { handleSetFilter } = useContext(GlobalContext);

    // VARIÁVEIS
    var date = new Date();
    
    // HABILITA O LOADING DA TABELA NOVAMENTE AO TROCAR AS TELAS
    useEffect(() => {
        handleSetFilter(true);
    }, []);

    // ESTADOS
    const [items, setItems] = useState([]);
    const [dateStart, setDateStart] = useState(new Date(get_date('date_sql', get_date('date', new Date(date.getFullYear(), date.getMonth(), 1), 'date'), 'date_sub_day', 29)));
    const [dateEnd, setDateEnd] = useState(new Date());
    const [period, setPeriod] = useState(1);

    // SETA ITENS VINDOS DA API
    const handleSetItems = (e) => {
        setItems(e);
    }

    // GRUPOS (PROVISÓRIO)
    const grupos = [
        {value: 1, label: 'Ancar'},
        {value: 2, label: 'Alqia'},
        {value: 3, label: 'Carrefour'},
        {value: 4, label: 'Argo'},
        {value: 5, label: 'AD'},
    ];

    // OPTIONS FILTRO PERÍODO
    const optionsPeriodo = [
        {value: 1, label: '30 dias'},
        {value: 2, label: '60 dias'},
        {value: 3, label: 'Personalizado'}
    ]

    // SETA FILTRO DATA INÍCIO
    const handleDateStart = (e) => {
        setDateStart(e);
        setPeriod(3);
    }

    // SETA FILTRO DATA FIM
    const handleDateEnd = (e) => {
        setDateEnd(e);
        setPeriod(3);
    }

    // SETA PERÍODO
    const handleSetPeriod = (e) => {
        let sub_days = 0;

        setPeriod(e.value);

        if(e.value === 1){
            sub_days = 29;
        }else if(e.value === 2){
            sub_days = 59;
        }

        setDateStart(new Date(get_date('date_sql', get_date('date', new Date(date.getFullYear(), date.getMonth(), 1), 'date'), 'date_sub_day', sub_days)));
        setDateEnd(new Date());
    }

    // OPTIONS STATUS (PROVISÓRIO)
    const optionsStatus = [
        {value: 1, label: 'Pendente'},
        {value: 2, label: 'Agendado'},
        {value: 3, label: 'Contato'},
        {value: 4, label: 'Positivo'},
        {value: 5, label: 'Negativo'},
        {value: 6, label: 'Ticket'}
    ];

    // ENVIA FILTROS PARA O COMPONENTE PAI
    useEffect(() => {
        if(filters){
            filters(
                <>
                    <SelectReact
                        placeholder="Período"
                        options={optionsPeriodo}
                        onChange={(e) => handleSetPeriod(e)}
                        value={period}
                    />

                    <Input
                        type="period"
                        valueStart={dateStart}
                        valueEnd={dateEnd}
                        onChangeStart={handleDateStart}
                        onChangeEnd={handleDateEnd}
                    />
                </>
            );
        }
    },[period, dateStart, dateEnd]);

    return(
        <Row>
            <Col lg="auto">
                <Table
                    id="acessos"
                    api={window.host+'/api/sql.php?do=select&component=empreendimento&token='+window.token}        
                    params={{
                        limit: 50
                    }}     
                    onLoad={handleSetItems}
                >
                    <Thead>
                        <Tr>
                            <Th
                                id="filter_adm"
                                name="filter_adm"
                                items={grupos}
                                // onChange={handleEmpreendimento}
                            >
                                ADM
                            </Th>

                            <Th
                                id="filter_empreendimento"
                                name="filter_empreendimento"
                                api={{
                                    url: window.host+'/api/sql.php?do=select&component=empreendimento&token='+window.token
                                }}                        
                                // onChange={handleEmpreendimento}
                            >
                                Shopping
                            </Th>

                            <Th align="center" minWidth={150}>Início</Th>
                            <Th align="center" minWidth={150}>Renovação</Th>
                            <Th align="center" minWidth={150}>Última Renovação</Th>
                        </Tr>
                    </Thead>

                    <Tbody>
                        {(items.length > 0 ?
                            items.filter((elem) => elem.value != 522).map((item, i) => {
                                let class_aux = '';
                                let date_aux;

                                // REGISTROS ALEATÓRIOS PARA FICAREM EM VERMELHO (PROVISÓRIO)
                                if(i == 2 || i == 7 || i == 9 || i == 14 || i == 20 || i == 25){
                                    date_aux = '01/09/2023';
                                    class_aux = 'text-danger font-weight-bold';
                                }else{                                    
                                    date_aux = '01/01/2024';
                                }

                                return(
                                    <Tr key={'empreendimento_'+item.value}>
                                        <Td className={class_aux}>{grupos[Math.floor(Math.random() * 4)].label}</Td>

                                        <Td className={class_aux}>{item.label}</Td>

                                        <Td className={class_aux} align="center">
                                            01/06/2021
                                        </Td>

                                        <Td className={class_aux} align="center">
                                            {date_aux}
                                        </Td>
                                        
                                        <Td className={class_aux} align="center">
                                            20/08/2023
                                        </Td>
                                    </Tr>
                                )
                            })
                        :
                            <></>
                        )}
                    </Tbody>
                </Table>
            </Col>
        </Row>
    )
}
