import { useState } from 'react';

import Gerenciador from '../../../components/body/gerenciador';
import Input from '../../../components/body/form/input';
import Table from '../../../components/body/table';
import Tbody from '../../../components/body/table/tbody';
import Tr from '../../../components/body/table/tr';
import Td from '../../../components/body/table/tbody/td';
import Form from '../../../components/body/form';
import Icon from '../../../components/body/icon';
import Button from '../../../components/body/button';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import Switch from '../../../components/body/switch';

export default function Grupo({callback}){
    // ESTADOS
    const [items, setItems] = useState([]);
    const [active, setActive] = useState(false);
    const [filter, setFilter] = useState(null);
    const [filterInactive, setFilterInactive] = useState(false);
    const [edit, setEdit] = useState(false);
    const [buttonStatus, setButtonStatus] = useState('');    

    // ESTADOS DO FORM
    const [loadingForm, setLoadingForm] = useState(true);
    const [nome, setNome] = useState('');
    const [id, setId] = useState('');
    const [reload, setReload] = useState(false);

    // SETA GRUPO ATIVO
    function handleSetActive(id){
        let id_aux = (active == id ? '' : id); // VERIFICA SE O ITEM ESTÁ ATIVO PARA MANDAR O ID VAZIO E RESETAR O FILTRO SE FOR O CASO

        setActive(id_aux);
        setEdit(false);

        if(callback){
            callback({
                items: items,
                active: id_aux,
                edit: false
            })
        }
    }

    // CALLBACK DA API DA TABLE
    const handleSetItems = (e) => {
        setItems(e);

        if(callback){
            callback({
                items: e
            })
        }
    }

    // RESETAR FORM
    function reset_form(){
        setId('');
        setNome('');
    }

    // AÇÕES AO ABRIR FORM DE CADASTRO
    const handleOpenForm = (id) => {
        if(id){
            setEdit(id);
            setLoadingForm(true);

            axios({
                method: 'get',
                url: window.host+'/systems/gerenciador/api/settings.php?do=fetchColumn&token='+window.token,
                params: {
                    columns: [
                        {column: 'group', filter: {id: id}}
                    ]
                }
            }).then((response) => {
                setId(response?.data?.group?.data[0]?.id);
                setNome(response?.data?.group?.data[0]?.nome);    
                setLoadingForm(false);        
            })
        }else{
            setEdit(true);
            reset_form();
            setLoadingForm(false);
        }

        callback({
            items: items,
            edit: true
        })
    }

    // AÇÕES AO FECHAR FORM DE CADASTRO
    const handleCloseForm = () => {
        setEdit(false);
        reset_form();

        callback({
            items: items,
            edit: false
        })

        setTimeout(() => {
            setLoadingForm(true);
        },200);
    }

    // VERIFICA STATUS DO FORM
    const handleFormStatus = (e) => {
        setButtonStatus(e);
    }

    // RECARREGA LISTA AO RECEBER O RETORNO DA API DE CADASTRO
    const handleReloadForm = (e) => {
        setReload(!reload);
    }

    // ATIVAR / DESATIVAR ITEM
    const handleSetItemActive = (id, ativo) => {
        toast('Grupo ' + (ativo ? 'ativado' : 'desativado'));

        axios({
            method: 'post',
            url: window.host+"/systems/gerenciador/api/settings.php?do=insUpdColumn&token=" + window.token,
            data: {
                columns: [{
                    column: 'group',
                    params: {
                        id: id,
                        ativo: ativo
                    }
                }]
            },
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(() => {
            // handleReloadForm();
        })
    }

    // FILTRO DE INATIVOS
    const handleSetFilterInactive = () => {
        setFilterInactive(!filterInactive);
        handleReloadForm();
    }

    // DATA FORM
    const data = {
        columns: [{
            column: 'group',
            params: {
                id: id,
                nome: nome,
                ativo: 1
            }
        }]
    }

    return(
        <>
            <Gerenciador 
                id="grupo"
                title="Grupos"
                search={
                    <Input
                        name="filter_grupo"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                    />
                }
                icon={
                    (window.rs_permission_apl === 'edicao' || window.rs_permission_apl === 'master' ?
                        <Icon
                            type="new"
                            title="Novo grupo"
                            onClick={() => handleOpenForm()}
                        />
                    :'')
                }
                switch={
                    <Input
                        type={'checkbox'}
                        name="grupos_inativos"
                        label="Inativos"
                        inverse={true}
                        onClick={handleSetFilterInactive}
                    />
                }
            >
                <Table
                    id="table_group"
                    api={window.host+'/systems/gerenciador/api/settings.php?do=fetchColumn&token='+window.token}
                    params={{
                        columns: [
                            {column: 'group', filter: {ativo: (filterInactive ? [1,0] : [1]), nome: filter}}
                        ]
                    }}
                    key_aux={'group'}                    
                    onLoad={handleSetItems}
                    reload={reload}
                    search={filter}
                    text_limit={(window.isMobile ? 20 : 30)}
                >
                    <Tbody>
                        {(items.length > 0 ?
                            items.map((item, i) => {
                                return(
                                    <Tr
                                        key={'grupo_'+item.id}
                                        cursor="pointer"
                                        active={(active === item.id ? true : false)}
                                    >
                                        <Td
                                            onClick={() => handleSetActive(item.id)}
                                        >
                                            {item.nome}
                                        </Td>

                                        <Td width={1} align="center">
                                            {(window.rs_permission_apl === 'edicao' || window.rs_permission_apl === 'master' ?
                                                <>
                                                    <Icon 
                                                        type={'edit'}
                                                        active={(edit === item.id ? true : false)}
                                                        onClick={() => handleOpenForm(item.id)}
                                                        animated
                                                    />

                                                    <Switch
                                                        type="check"
                                                        title={(item.ativo == 1 ? 'Desativar' : 'Ativar')}
                                                        checked={(item.ativo == 1 ? true : false)}
                                                        onChange={() => handleSetItemActive(item.id, (item.ativo == 1 ? 0 : 1))}
                                                        animated
                                                    />
                                                </>
                                            :'')}
                                        </Td>
                                    </Tr>
                                )
                            })
                        :
                            <></>
                        )}
                    </Tbody>
                </Table>
            </Gerenciador>

            {/* FORMULÁRIO DE CADASTRO/EDIÇÃO */}
            {(edit ? 
                <Gerenciador 
                    id="grupo_insert"
                    title={(id ? ('Editar '+nome) : 'Novo grupo')}
                    search={false}
                    icon={
                        <Icon
                            type="reprovar"
                            title="Fechar"
                            onClick={handleCloseForm}
                        />
                    }
                >
                    <Form
                        api={window.host+"/systems/gerenciador/api/settings.php?do=insUpdColumn&token=" + window.token}
                        data={data}
                        status={handleFormStatus}
                        response={handleReloadForm}
                        // toast={handleToast}
                    >
                        <Input
                            type="text"
                            name="grupo_nome"
                            label="Nome"
                            value={nome}
                            loading={loadingForm}
                            onChange={(e) => setNome(e.target.value)}
                        />

                        <Button
                            type="submit"
                            loading={loadingForm}
                            status={buttonStatus}
                        >
                            Salvar
                        </Button>
                    </Form>
                </Gerenciador>
            :'')}
        </>
    )
}
