import Modal from "../../../components/body/modal";
import ModalTitle from "../../../components/body/modal/modalHeader/modalTitle";
import ModalBody from "../../../components/body/modal/modalBody";
import ModalHeader from "../../../components/body/modal/modalHeader";
import Td from "../../../components/body/table/tbody/td";
import Table from "../../../components/body/table";
import Tbody from "../../../components/body/table/tbody";
import Tr from "../../../components/body/table/tr";
import Thead from "../../../components/body/table/thead";
import Th from "../../../components/body/table/thead/th";
import axios from "axios";
import { useState, useEffect } from "react";
import Icon from "../../../components/body/icon";
import { cd, cdh } from "../../../_assets/js/global";

export default function ModalBusca(props) {
  

    //ESTADOs
    const [news, setNews] = useState();
    const [documentos, setDocumentos] = useState();
    const [comunicado, setComunicado] = useState();
    const [detalhesDoc, setDetalhesDoc] = useState();

    const handleClose = () => {
        props?.onHide(false);
        setDetalhesDoc([])
    }
    
    //FUNÇÃO QUE PEGA QUEM DEU LIKE NA API
    function searchDoc() {
        axios.get(window.host + "/homes/api/home7.php?do=get_quickAccess&token=" + window.token, {
            params: {
                filter_multiple_columns: props?.search,
                id_apl: props?.search
            }
        })
            .then((response) => {
                setDocumentos(response.data)
            })
    }

    //FUNÇÃO QUE PEGA QUEM DEU LIKE NA API
    function searchNews() {
        axios.get(window.host + "/homes/api/home3.php?do=cadastros&", {
            params: {
                ativo: 1,
                permissao: 3,
                page: 1,
                limit: 6,
                // hashtag_filtro: search,
                filter_multiple_columns: props?.search,
                token: window.token
            }
        }).then((response) => {
            setNews(response.data)
        })
    }
    //FUNÇÃO QUE PEGA QUEM DEU LIKE NA API
    function searchComunicado() {
        axios.get(
            window.host + "/systems/comunicado/api/lista.php?", {
            params: {
                p: "lista",
                s: "1",
                page: "1",
                limit: "10",
                lista_metodo: "array",
                filtro_nome: "",
                filtro_loja: "",
                filtro_data_inicio: "",
                filtro_data_fim: "",
                filtro_tipo_comunicado: "Comunicados",
                filtro_usuario: 79507,
                token: window.token,
                filter_multiple_columns: props?.search,
            }
        }).then((response) => setComunicado(response.data))
    }
    ///->
    useEffect(() => {

        searchComunicado();
       // searchDoc();
        // searchNews();

    }, [props?.search]);

    function getDetalhesDoc(level) {
        axios.get(window.host + "/systems/treinamento/api/lista.php?&_", {
            params: {
                do: "get_foldersFiles",
                filter_multiple_columns: props?.search,
                sistema_id: 218,
                aux_id: '',
                level: level,
                limit: false,
                token: window.token,
            }
        }).then((response) => setDetalhesDoc([...detalhesDoc, ...response.data]))
    }

    useEffect(() => {      
        setDetalhesDoc([])
        getDetalhesDoc(4);
        getDetalhesDoc(3);
    }, [props?.search])

    return (
        <>
            <Modal show={props?.show} onHide={handleClose} large={true} >
                <ModalHeader>
                    <ModalTitle>
                        Resultado da pesquisa
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Table
                    // key={visualizacao.id}
                    >
                        <Thead>
                            <Tr>
                                <Th>Sistema</Th>
                                <Th align="right">Nome</Th>
                                <Th>Cadastro</Th>
                                <Th>Atualização</Th>
                                <Th>Ações</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {/* {(
                                news &&
                                news.map((item, i) => {
                                    return (
                                        <Tr key={`${item.id} - ${i} - news`}>
                                            <Td>News</Td>
                                            <Td>{item.nome}</Td>
                                            <Td>{cd(item.data_publicacao)}</Td>
                                            <Td>{item.data_atualizacao ? cd(item.data_atualizacao) : '-'}</Td>
                                            <Td><Icon type="view" onClick={handleClose} /></Td>
                                        </Tr>
                                    )
                                })
                            )} */}
                            {(
                                comunicado &&
                                comunicado.map((item, i) => {
                                    return (
                                        <Tr key={`${item.id} - ${i} - comunicados`}>
                                            <Td>Comunicado</Td>
                                            <Td>{item.nome}</Td>
                                            <Td>{item.data}</Td>
                                            <Td>{item.data_atualizacao ? cd(item.data_atualizacao) : '-'}</Td>
                                            <Td>
                                                <a href={`https://dev.malltech.com.br/systems/comunicado/?p=lista&s=1&c=${item.id}`} target="_blank">
                                                    <Icon type="view" />
                                                </a>
                                            </Td>
                                        </Tr>
                                    )
                                })
                            )}
                            {/* {(
                                documentos &&
                                documentos.map((item, i) => {
                                    return (
                                        <Tr key={`${item.id} - ${i} - documentos`}>
                                            <Td>Documentos</Td>
                                            <Td>{item.nome_aux}</Td>
                                            <Td>{cdh(item.cad)}</Td>
                                            <Td>{item.data_atualizacao ? item.data_atualizacao : '-'}</Td>
                                            <Td>
                                                {
                                                    item.tipo === 'documento' ?
                                                        <Icon type="view" onClick={() => (props?.handleModal(218, item.aux_id, item.level, item.nome_aux), handleClose())} />
                                                        :
                                                        <a href={item.url} target="_blank">
                                                            <Icon type="view" />
                                                        </a>
                                                }
                                            </Td>
                                        </Tr>
                                    )
                                })
                            )} */}
                            {(
                                detalhesDoc &&
                                detalhesDoc.map((item, i) => {
                                    return (
                                        <Tr key={`${item.file_id} - ${i} - documentos-detalhe`}>
                                            <Td>Documentos</Td>
                                            <Td>{item.file_name}</Td>
                                            <Td>{item.cad !== "-" ? cd(item.cad) : item.cad}</Td>
                                            <Td>{item.cad_update !== "-" ? cd(item.cad_update) : item.cad_update}</Td>
                                            <Td>
                                                <a href={process.env.REACT_APP_URL_UPLOAD + "/" + item.anexo} target="_blank" rel="noreferrer">
                                                    <Icon type="view" />
                                                </a>
                                            </Td>
                                        </Tr>
                                    )
                                })
                            )}
                        </Tbody>
                    </Table>
                </ModalBody>
            </Modal>
        </>
    )
}