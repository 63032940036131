import { useEffect, useState } from "react";
import Table from "../../../components/body/table";
import Tbody from "../../../components/body/table/tbody";
import Td from "../../../components/body/table/tbody/td";
import Thead from "../../../components/body/table/thead";
import Th from "../../../components/body/table/thead/th";
import Tr from "../../../components/body/table/tr";
import SelectReact from "../../../components/body/select";
import FilterCheckbox from "../../../components/body/filterCheckbox";
import Input from "../../../components/body/form/input";
import "./style.css";
import Icon from "../../../components/body/icon";

import TemperaturaTable from "./tempheader";

export default function Lista({ filters, icons }) {

    //ESTADOS
    const [lista, setLista] = useState([
        {
            id: 1, data: "06/07/2023", data_2: "1", loja: "Filial 1", categoria: "Ambiente", horario: "10:00", minimo: "22,5", maximo: "30,0", momento: "30,0", umidade: "40", responsavel: "Isabella Silva",
            horario_2: "14:00", minimo_2: "22,5", maximo_2: "30,0", momento_2: "30,0", umidade_2: "70", responsavel_2: "Isabella Silva",
            horario_3: "18:00", minimo_3: "22,5", maximo_3: "30,0", momento_3: "30,0", umidade_3: "80", responsavel_3: "Isabella Silva",
        },
        {
            id: 2, data: "06/07/2023", data_2: "2", loja: "Filial 2", categoria: "Ambiente", horario: "10:00", minimo: "22,5", maximo: "30,0", momento: "30,0", umidade: "80", responsavel: "Isabella Silva",
            horario_2: "14:00", minimo_2: "22,5", maximo_2: "30,0", momento_2: "30,0", umidade_2: "80", responsavel_2: "Isabella Silva",
            horario_3: "18:00", minimo_3: "22,5", maximo_3: "30,0", momento_3: "30,0", umidade_3: "80", responsavel_3: "Isabella Silva",
        },
        {
            id: 3, data: "06/07/2023", data_2: "3", loja: "Filial 3", categoria: "Ambiente", horario: "10:00", minimo: "22,5", maximo: "30,0", momento: "30,0", umidade: "80", responsavel: "Isabella Silva",
            horario_2: "14:00", minimo_2: "22,5", maximo_2: "30,0", momento_2: "30,0", umidade_2: "80", responsavel_2: "Isabella Silva",
            horario_3: "18:00", minimo_3: "22,5", maximo_3: "30,0", momento_3: "30,0", umidade_3: "80", responsavel_3: "Isabella Silva",
        },
        {
            id: 4, data: "06/07/2023", data_2: "4", loja: "Filial 4", categoria: "Ambiente", horario: "10:00", minimo: "22,5", maximo: "30,0", momento: "30,0", umidade: "80", responsavel: "Isabella Silva",
            horario_2: "14:00", minimo_2: "22,5", maximo_2: "30,0", momento_2: "30,0", umidade_2: "80", responsavel_2: "Isabella Silva",
            horario_3: "18:00", minimo_3: "22,5", maximo_3: "30,0", momento_3: "30,0", umidade_3: "70", responsavel_3: "Isabella Silva",
        },
        {
            id: 5, data: "06/07/2023", data_2: "5", loja: "Filial 5", categoria: "Termolabil", horario: "10:00", minimo: "22,5", maximo: "30,0", momento: "30,0", umidade: "80", responsavel: "Isabella Silva",
            horario_2: "14:00", minimo_2: "22,5", maximo_2: "30,0", momento_2: "30,0", umidade_2: "80", responsavel_2: "Francisco Silva",
            horario_3: "18:00", minimo_3: "22,5", maximo_3: "30,0", momento_3: "30,0", umidade_3: "75", responsavel_3: "Isabella Silva",
        },
        {
            id: 6, data: "06/07/2023", data_2: "6", loja: "Filial 6", categoria: "Termolabil", horario: "10:00", minimo: "22,5", maximo: "30,0", momento: "30,0", umidade: "42", responsavel: "Isabella Silva",
            horario_2: "14:00", minimo_2: "22,5", maximo_2: "30,0", momento_2: "30,0", umidade_2: "45", responsavel_2: "Francisco Silva",
            horario_3: "18:00", minimo_3: "22,5", maximo_3: "30,0", momento_3: "30,0", umidade_3: "76", responsavel_3: "Isabella Silva",
        },
    ]);
    const [filter, setFilter] = useState(1);
    const [loja, setLoja] = useState('');
    const [categoria, setCategoria] = useState(1);
    const [nome, setNome] = useState('');
    const [date, setDate] = useState('');

    //FILTROS
    const handleFilterLoja = (e) => {
        setLoja(e)
    }
    const handleFilterCategoria = (e) => {
        setCategoria(e.value)
    }
    const handleFilterNome = (e) => {
        setNome(e)
    }
    const handleFilterDate = (e) => {
        setDate(e)
    }

    //
    const thead = [
        { enabled: true, label: 'Data', id: 'data', name: 'data', type: 'date' },
        { enabled: (filter === 1 ? true : false), label: 'Loja', id: 'loja', name: 'loja', },
        { enabled: (filter === 1 ? true : false), label: 'Categoria', id: 'categoria', name: 'categoria' },
        { enabled: true, label: 'Horário', id: 'horario', name: 'horario' },
        { enabled: true, label: 'Mínimo', id: 'minimo', name: 'minimo' },
        { enabled: true, label: 'Máximo', id: 'maximo', name: 'maximo' },
        { enabled: true, label: 'Momento', id: 'momento', name: 'momento' },
        { enabled: true, label: 'Umidade', id: 'umidade', name: 'umidade' },
        { enabled: true, label: 'Responsável', id: 'responsavel', name: 'responsavel' },

        { enabled: true, label: 'Horário', id: 'horario_2', name: 'horario_2' },
        { enabled: true, label: 'Mínimo', id: 'minimo_2', name: 'minimo_2' },
        { enabled: true, label: 'Máximo', id: 'maximo_2', name: 'maximo_2' },
        { enabled: true, label: 'Momento', id: 'momento_2', name: 'momento_2' },
        { enabled: true, label: 'Umidade', id: 'umidade_2', name: 'umidade_2' },
        { enabled: true, label: 'Responsável', id: 'responsavel_2', name: 'responsavel_2' },

        { enabled: true, label: 'Horário', id: 'horario_3', name: 'horario_3' },
        { enabled: true, label: 'Mínimo', id: 'minimo_3', name: 'minimo_3' },
        { enabled: true, label: 'Máximo', id: 'maximo_3', name: 'maximo_3' },
        { enabled: true, label: 'Momento', id: 'momento_3', name: 'momento_3' },
        { enabled: true, label: 'Umidade', id: 'umidade_3', name: 'umidade_3' },
        { enabled: true, label: 'Responsável', id: 'responsavel_3', name: 'responsavel_3' },


    ]

    useEffect(() => {
        if (icons) {
            icons(
                <Icon type="print" />
            )
        }
        if (filters) {
            filters(
                <>
                    <SelectReact
                        value={filter}
                        onChange={(e) => (setFilter(e.value), setCategoria(e.value == 2 ? 1 : ''))}
                        options={[{ value: 1, label: "Loja" }, { value: 2, label: "Ambiente" }]}
                    />
                    {
                        filter == 1 ?
                            <FilterCheckbox
                                name="filter_store"
                                api={window.host + "/api/sql.php?do=select&component=loja&np=true&filial=true&limit=false&token=" + window.token}
                                onChangeClose={handleFilterLoja}
                            >
                                Loja
                            </FilterCheckbox>
                            :
                            <SelectReact
                                placeholder="Categoria"
                                name="filter_category"
                                // api={window.host + "/systems/integration-react/api/registry.php?do=get_category&grupo_id=true&empreendimento_id=" + '' + "&filter_id_module=" + ('') + "&filter_id_apl_default=0&token=" + window.token}
                                onChange={handleFilterCategoria}
                                value={categoria}
                                options={[{ value: '', label: " " }, { value: 1, label: "Ambiente" }, { value: 2, label: "Termolábil" }]}
                            />
                    }

                    <FilterCheckbox
                        name="filter_nome"
                        // api={window.host + "/systems/integration-react/api/registry.php?do=get_category&grupo_id=true&empreendimento_id=" + filterEmpreendimento + "&filter_id_module=" + (filterModule == undefined ? '' : filterModule) + "&filter_id_apl_default=0&token=" + window.token}
                        onChangeClose={handleFilterNome}
                        value={nome}
                        reload={nome}
                    >
                        Nome
                    </FilterCheckbox>
                    <Input
                        type="date"
                        icon={false}
                        required={false}
                        value={date}
                        onChange={(e) => handleFilterDate(e)}
                    />
                </>
            )
        }
        if (filter === 1) {
            setCategoria('')
        }

    }, [loja, nome, categoria, date, filter]);

    let rows

    function count(num) {
        let rows = []
        for (let j = num + 1; j <= 31; j++) {
            rows.push(j);
        }
        return rows
    }

    if (categoria === 1) {
        rows = count(4)
    }
    if (categoria === 2) {
        rows = count(2)
    }

    return (
        <>
            <TemperaturaTable
                logo={'https://th.bing.com/th/id/R.77781f01eb29686fae846b7b55143ef7?rik=uEvllXPGKJlSYg&pid=ImgRaw&r=0'}
                categoria={"Ambiente"}
                nome={"Depósito medicamentos"}
                razao_social={"SEROMA FARMÁCIAS E PERFUMES LTDA"}
                mes={"Setembro"}
                ano={"2023"}
                temperatura={"Temperatura: 15 - 30°C"}
                umidade={"Umidade: 45 - 70%"}
            >
                <Table
                //thead={thead}
                //fullHeight={true}
                >
                    <Thead>
                        <Tr className="controle-tem">

                            <Th
                                align="center"
                                colspan={filter === 1 ? "9%" : "7%"}
                                className="controle-tem-ultima"
                            >
                                Manhã
                            </Th>

                            <Th
                                align="center"
                                colspan="6%"
                                className="controle-tem-ultima"
                            >
                                Tarde
                            </Th>
                            <Th
                                align="center"
                                colspan="6%"
                            >
                                Tarde
                            </Th>
                        </Tr>
                        <Tr>
                            {/* manhã */}
                            <Th>Data</Th>
                            <Th hide={categoria ? true : false} >Loja</Th>
                            <Th hide={categoria ? true : false} >Categoria</Th>
                            <Th>Horário</Th>
                            <Th>Mínimo</Th>
                            <Th>Máximo</Th>
                            <Th>Momento</Th>
                            <Th>Umidade</Th>
                            <Th className="controle-tem-ultima" >Responsável</Th>
                            {/* tarde 1 */}
                            <Th>Horário</Th>
                            <Th>Mínimo</Th>
                            <Th>Máximo</Th>
                            <Th>Momento</Th>
                            <Th>Umidade</Th>
                            <Th className="controle-tem-ultima" >Responsável</Th>
                            {/* tarde 2 */}
                            <Th>Horário</Th>
                            <Th>Mínimo</Th>
                            <Th>Máximo</Th>
                            <Th>Momento</Th>
                            <Th>Umidade</Th>
                            <Th>Responsável</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            lista &&
                            lista
                                .filter((item) => item.categoria === "Ambiente" && categoria == 1
                                    || item.categoria === "Termolabil" && categoria == 2
                                    || categoria == '')
                                .map((item, i) => {

                                    let color;
                                    if (item.umidade < 45 || item.umidade > 75) {
                                        color = "text-danger"
                                    }
                                    let color_2;
                                    if (item.umidade_2 < 45 || item.umidade_2 > 75) {
                                        color_2 = "text-danger"
                                    }
                                    let color_3;
                                    if (item.umidade_3 < 45 || item.umidade_3 > 75) {
                                        color_3 = "text-danger"
                                    }

                                    return (
                                        <>
                                            <Tr key={i}>
                                                <Td>  {categoria ? i + 1 : item.data} </Td>
                                                <Td hide={categoria ? true : false} >{item.loja}</Td>
                                                <Td hide={categoria ? true : false} >{item.categoria}</Td>
                                                <Td>{item.horario}</Td>
                                                <Td>{`${item.minimo}°C`}</Td>
                                                <Td>{`${item.maximo}°C`}</Td>
                                                <Td>{`${item.momento}°C`}</Td>
                                                <Td className={color} >{`${item.umidade}%`}</Td>
                                                <Td className="controle-tem-ultima" >{item.responsavel}</Td>
                                                <Td>{item.horario_2}</Td>
                                                <Td>{`${item.minimo_2}°C`}</Td>
                                                <Td>{`${item.maximo_2}°C`}</Td>
                                                <Td>{`${item.momento_2}°C`}</Td>
                                                <Td className={color_2} >{`${item.umidade_2}%`}</Td>
                                                <Td className="controle-tem-ultima">{item.responsavel_2}</Td>
                                                <Td>{item.horario_3}</Td>
                                                <Td>{`${item.minimo_3}°C`}</Td>
                                                <Td>{`${item.maximo_3}°C`}</Td>
                                                <Td>{`${item.momento_3}°C`}</Td>
                                                <Td className={color_3} >{`${item.umidade_3}%`}</Td>
                                                <Td>{item.responsavel_3}</Td>
                                            </Tr>
                                        </>
                                    )
                                })
                        }
                        {
                            categoria &&
                            rows &&
                            rows.map((item, i) => {
                                return (
                                    <Tr key={i}>
                                        <Td>{item}</Td>
                                        <Td>-</Td>
                                        <Td>-</Td>
                                        <Td>-</Td>
                                        <Td>-</Td>
                                        <Td>-</Td>
                                        <Td className="controle-tem-ultima">-</Td>
                                        <Td>-</Td>
                                        <Td>-</Td>
                                        <Td>-</Td>
                                        <Td>-</Td>
                                        <Td>-</Td>
                                        <Td className="controle-tem-ultima">-</Td>
                                        <Td>-</Td>
                                        <Td>-</Td>
                                        <Td>-</Td>
                                        <Td>-</Td>
                                        <Td>-</Td>
                                        <Td>-</Td>
                                    </Tr>
                                )
                            })
                        }
                    </Tbody>
                </Table>
            </TemperaturaTable>
        </>
    )
}