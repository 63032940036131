import { useEffect, useState, useContext } from "react";
import { GlobalContext } from "../../../context/Global";
import { cdh, get_date } from '../../../_assets/js/global.js';
import axios from "axios";

import Row from "../../../components/body/row";
import Col from "../../../components/body/col";
import Dashboard from "../../../components/body/dashboard";
import Tr from '../../../components/body/table/tr';
import Th from '../../../components/body/table/thead/th';
import Td from '../../../components/body/table/tbody/td';
import SelectReact from "../../../components/body/select";
import PageError from '../../../components/body/pageError';
import Modal from "../../../components/body/modal";
import ModalHeader from "../../../components/body/modal/modalHeader";
import ModalTitle from "../../../components/body/modal/modalHeader/modalTitle";
import ModalBody from "../../../components/body/modal/modalBody";
import Table from "../../../components/body/table";
import Tbody from "../../../components/body/table/tbody";
import Thead from "../../../components/body/table/thead";
import Usuarios from "./Usuarios";
import Lojas from "./Lojas";
import Supervisores from "./Supervisores";
import Categorias from "./Categorias";
import Subcategorias from "./Subcategorias";

export default function DashboardPage(props) {
    // CONTEXT
    const { loadingCalendar, handleSetFirstLoad, handleSetFixFilterModule, handleSetFilter, handleSetFilterModule, filterModule } = useContext(GlobalContext); 

    // ESTADOS
    const [monthSelected, setMonthSelected] = useState(window.currentMonth);
    const [yearSelected, setYearSelected] = useState(window.currentYear);
    const [supervisorActive, setSupervisorActive] = useState('');
    const [lojaActive, setLojaActive] = useState('');
    const [usuarioActive, setUsuarioActive] = useState('');
    const [categoriaActive, setCategoriaActive] = useState('');
    const [subcategoriaActive, setSubcategoriaActive] = useState('');
    const [optionsModule, setOptionsModule] = useState([]);
    const [pageError, setPageError] = useState(false);

    // ESTADOS MODAL
    const [showModal, setShowModal] = useState(false);
    const [supervisorId, setSupervisorId] = useState([]);
    const [lojaId, setLojaId] = useState([]);
    const [usuarioId, setUsuarioId] = useState([]);
    const [categoriaId, setCategoriaId] = useState([]);
    const [subcategoriaId, setSubcategoriaId] = useState([]);
    const [titulo, setTitulo] = useState([]);
    const [status, setStatus] = useState('');
    const [statusName, setStatusName] = useState('');
    const [itemsInfo, setItemsInfo] = useState([]);

    // PEGA ID DO MÓDULO
    useEffect(() => {
        if(optionsModule == 0 && (props.chamados || props.fases || props.visitas)){
            axios.get(window.host+"/systems/integration-react/api/list.php?do=get_module&token="+window.token).then((response) => {      
                if(response.data.length>0){
                    setOptionsModule(response.data);
                    if(props.visitas){
                        handleSetFilterModule(global.modulo.visitas);
                    }else{
                        if(window.rs_sistema_id != 238){ // SE O SISTEMA ID FOR DIFERENTE DO "CHAMADOS EMPRESA REACT" TROCA
                            handleSetFilterModule((response.data[0] ? response.data[0].value : 0)); // SELECIONA O PRIMEIRO (CASO TENHA) COMO PADRÃO AO CARREGAR A PÁGINA
                        }else{ // SE O SISTEMA ID FOR DO "CHAMADOS EMPRESA REACT" CRAVA O ID DO MÓDULO
                            handleSetFilterModule(2361);
                        }
                    }
                }else{
                    setPageError(true);
                }
            });
        }
    },[]);

    // HABILITA O LOADING DOS CARDS PARA QUANDO VOLTAR PRA TELA DO CALENDÁRIO
    useEffect(() => {
        handleSetFilter(true);
        loadingCalendar(true);
        handleSetFixFilterModule(false);
        handleSetFirstLoad(true);
    },[]);

    // OPTIONS MESES
    const optionsMonths = [
        { value: 1, label: 'Janeiro'},
        { value: 2, label: 'Fevereiro'},
        { value: 3, label: 'Março'},
        { value: 4, label: 'Abril'},
        { value: 5, label: 'Maio'},
        { value: 6, label: 'Junho'},
        { value: 7, label: 'Julho'},
        { value: 8, label: 'Agosto'},
        { value: 9, label: 'Setembro'},
        { value: 10, label: 'Outubro'},
        { value: 11, label: 'Novembro'},
        { value: 12, label: 'Dezembro'}
    ]

    // OPTIONS ANO
    var optionsYear = [];
    for(var i=0; i<5; i++){
        optionsYear.push(
            {value: window.currentYear-i, label: window.currentYear-i}
        )
    }

    // MANDA OS FILTROS PRO COMPONENTE PAI E TRAVA OS FILTROS ENQUANTO AS COLUNAS CARREGAM
    useEffect(() => {
        if (props?.icons) {
            props.icons('');
        }

        if (props?.filters) {
            props.filters(
                <>
                    {((props.chamados || props.fases) && window.rs_sistema_id != 238 ? // SE FOR CHAMADOS, E NÃO ESTIVER NO SISTEMA "CHAMADOS EMPRESA REACT" MOSTRA O FILTRO DE MÓDULO
                        <SelectReact
                            options={optionsModule}
                            placeholder="Nível"
                            name="filter_module"
                            value={filterModule}
                            onChange={(e) => (
                                handleSetFilterModule(e.value)
                            )}
                        />
                    :'')}

                    <SelectReact
                        placeholder="Mês"
                        options={optionsMonths}
                        value={monthSelected}
                        onChange={(e) => setMonthSelected(e.value)}
                    />

                    <SelectReact
                        placeholder="Ano"
                        options={optionsYear}
                        value={yearSelected}
                        onChange={(e) => setYearSelected(e.value)}
                    />
                </>
            )
        }
    }, [monthSelected, yearSelected]);

    // BUSCA OS JOBS REFERENTE AO NÚMERO CLICADO
    function getInfo(supervisor_id, loja_id, usuario_id, categoria_id, subcategoria_id, titulo, status){
        setShowModal(true);
        setSupervisorId(supervisor_id);
        setLojaId(loja_id);
        setUsuarioId(usuario_id);
        setCategoriaId(categoria_id);
        setSubcategoriaId(subcategoria_id);
        setTitulo(titulo);
        setStatus(status);

        let status_aux = '';
        if(status === -2){
            status_aux = 'Atrasado';
        }else if(status === 1){
            status_aux = 'Concluído';
        }else if(status === 3){
            status_aux = 'Concluído c/ Atraso';
        }else if(status === 2){
            status_aux = 'Não tem';
        }

        setStatusName(status_aux);
    }
 
    // FECHA MODAL
    const handleCloseModal = () => {
        setShowModal(false);
        setTimeout(() => {
            setItemsInfo([]);
        },500);
    }

    // INFO MODAL
    const handleSetItemsInfo = (e) => {
        setItemsInfo(e);
    }

    const handleCallback = (e) => {
        if(e.getInfo){
            getInfo(
                e.getInfo?.supervisor_id,
                e.getInfo?.loja_id,
                e.getInfo?.usuario_id,
                e.getInfo?.categoria_id,
                e.getInfo?.subcategoria_id,
                e.getInfo?.titulo,
                e.getInfo?.status
            );
        }

        if(e.filterCol){
            if(e.filterCol.categoria_id || e.filterCol.categoria_id === ''){
                setCategoriaActive(e.filterCol.categoria_id);
            }

            if(e.filterCol.loja_id || e.filterCol.loja_id === ''){
                setLojaActive(e.filterCol.loja_id);
            }

            if(e.filterCol.subcategoria_id || e.filterCol.subcategoria_id === ''){
                setSubcategoriaActive(e.filterCol.subcategoria_id);
            }

            if(e.filterCol.supervisor_id || e.filterCol.supervisor_id === ''){
                setSupervisorActive(e.filterCol.supervisor_id);
            }

            if(e.filterCol.usuario_id || e.filterCol.usuario_id === ''){
                setUsuarioActive(e.filterCol.usuario_id);
            }
        }
    }

    // SÓ EXECUTA O CÓDIGO DO DASHBOARD DEPOIS QUE TIVER UM MÓDULO SETADO OU NO SISTEMA JOBS
    if(filterModule || (!props.chamados && !props.fases && !props.visitas)){
        return (  
            <> 
                <Modal show={showModal} onHide={handleCloseModal} xl={true}> 
                    <ModalHeader>
                        <ModalTitle>
                            Jobs
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <Table
                            id="jobs_info"
                            api={window.host+'/systems/integration-react/api/list.php?do=get_list'}
                            params={{
                                token: window.token,
                                filter_type: 5,
                                filter_subtype: 'dashboard_info',
                                filter_month: monthSelected,
                                filter_year: yearSelected,
                                filter_supervisor: [(supervisorActive ? supervisorActive : supervisorId)],
                                filter_id_store: [(lojaActive ? lojaActive : lojaId)],
                                filter_id_user: [(usuarioActive ? usuarioActive : usuarioId)],
                                filter_category: [(categoriaActive ? categoriaActive : categoriaId)],
                                filter_subcategory: [(subcategoriaActive ? subcategoriaActive : subcategoriaId)],
                                filter_title: titulo,
                                filter_id_module: filterModule,
                                filter_status: [status],
                                limit: 50
                            }}
                            border={false}
                            onLoad={handleSetItemsInfo}
                            reload={showModal}
                        >
                            <Thead>
                                <Tr>
                                    {(lojaId ? 
                                        <Th>Loja</Th>
                                    :'')}

                                    {(usuarioId ? 
                                        <Th>Usuário</Th>
                                    :'')}

                                    <Th>Título</Th>
                                    <Th>Categoria</Th>
                                    <Th>Subcategoria</Th>
                                    <Th>Data</Th>
                                    <Th>Data Finalizado</Th>
                                    <Th>Status</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {itemsInfo.map((item, i) => {
                                    return(
                                        <Tr>
                                            {(lojaId ? 
                                                <Td>{item?.loja}</Td>
                                            :'')}

                                            {(usuarioId ? 
                                                <Td>{item?.usuario}</Td>
                                            :'')}

                                            <Td>{item?.job}</Td>
                                            <Td>{item?.categoria}</Td>
                                            <Td>{item?.subcategoria}</Td>
                                            <Td>{(item?.data_job ? cdh(item?.data_job) : '')}</Td>
                                            <Td>{item?.dataHora_execucao_formatada}</Td>
                                            <Td>{statusName}</Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </ModalBody>
                </Modal>

                <Row wrap={(window.isMobile ? true : false)}>
                    <Supervisores
                        filters={{
                            monthSelected: monthSelected,
                            yearSelected: yearSelected,
                            filterModule: filterModule,
                            supervisorActive: supervisorActive,
                            lojaActive: lojaActive,
                            usuarioActive: usuarioActive,
                            categoriaActive: categoriaActive
                        }}
                        callback={handleCallback}
                    />

                    <Lojas
                        filters={{
                            monthSelected: monthSelected,
                            yearSelected: yearSelected,
                            filterModule: filterModule,
                            supervisorActive: supervisorActive,
                            lojaActive: lojaActive,
                            usuarioActive: usuarioActive,
                            categoriaActive: categoriaActive
                        }}
                        callback={handleCallback}
                    />

                    <Usuarios
                        filters={{
                            monthSelected: monthSelected,
                            yearSelected: yearSelected,
                            filterModule: filterModule,
                            supervisorActive: supervisorActive,
                            lojaActive: lojaActive,
                            usuarioActive: usuarioActive,
                            categoriaActive: categoriaActive
                        }}
                        callback={handleCallback}
                    />

                    <Categorias
                        filters={{
                            monthSelected: monthSelected,
                            yearSelected: yearSelected,
                            filterModule: filterModule,
                            supervisorActive: supervisorActive,
                            lojaActive: lojaActive,
                            usuarioActive: usuarioActive,
                            categoriaActive: categoriaActive
                        }}
                        callback={handleCallback}
                    />

                    <Subcategorias
                        filters={{
                            monthSelected: monthSelected,
                            yearSelected: yearSelected,
                            filterModule: filterModule,
                            supervisorActive: supervisorActive,
                            lojaActive: lojaActive,
                            usuarioActive: usuarioActive,
                            categoriaActive: categoriaActive
                        }}
                        callback={handleCallback}
                    />
                </Row>
            </>
        )
    }else{
        if(pageError){
            return(
                <PageError
                    title="Nenhum módulo configurado"
                    text={`Tente novamente em alguns minutos.\nCaso o problema persista, entre em contato com o suporte.`}
                />
            )
        }
    }
}
