import axios from "axios";
import { useState, useEffect } from "react";
import parse from "html-react-parser";
import Loader from "../../../../../components/body/loader";
import { cdh } from "../../../../../_assets/js/global";
import Icon from "../../../../../components/body/icon";
import toast from "react-hot-toast";

export default function PostMessage({ id, reload }) {
    // ESTADOS
    const [comment, setComment] = useState([]);
    const [loading, setLoading] = useState(true);

    function getComment() {
        axios.get(window.host+"/homes/api/home3.php", {
            params: {
                do: "comentarios",
                noticia_id: id,
                token: window.token
            }
        }).then((response) => {
            setComment(response.data)
            setLoading(false)
        })
    }

    //FUNÇÃO APAGAR COMENTÁRIO
    function removeComment(comment_id, news_id) {
        if (window.confirm("Deseja remover esse cometário?")) {
            axios({
                url: window.host + "/homes/api/home3.php?do=removerComentario&token=" + window.token,
                method: "post",
                data: {
                    comentario_id: comment_id,
                    noticia_id: news_id
                },
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(() => {
                getComment();
                setComment([]);
                toast("Comentário removido com sucesso!")
            })
        }
    };


    useEffect(() => {
        getComment();
    }, [id, reload]);

    return (
        <>
            {loading == true ?
                <div style={{ paddingTop: "20px" }}>
                    <Loader />
                </div>
                :
                comment &&
                comment.map((item) => {
                    return (
                        <div className="read-message-div" key={item.id} >
                            {(
                                item.img ?
                                <img src="https://templates.iqonic.design/socialv/bs5/react/build/static/media/02.996984acbcab88366af7.jpg" />
                                : <div className="read-message-user-initial"> {item.nome_usuario.slice(0, 1)} </div>
                            )}
                            <div className="read-message-div-info" >
                                {/* <div className="read-message-div-h6"> */}
                                <h6 className="read-message-div-h6">{item.nome_usuario}

                                    {(
                                        item.usuario_id == window.rs_id_usr ?
                                            <Icon type="trash" title={'Remover comentário'} onClick={() => removeComment(item.id, item.noticia_id)} />
                                        : " "
                                    )}
                                </h6>
                                {/* </div> */}
                                <p>
                                    {parse(item.comentario)}
                                </p>
                                <div className="read-message-div-info-span">
                                    <span>Curtir</span>
                                    <span>Responder</span>
                                    <span className="span-black">{cdh(item.cad)}</span>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}