import Icon from "../../icon";
import "./style.css";
import { useState, useContext } from "react";
import axios from "axios";
import ViewModal from "./modalView";
import Form from "../../../../components/body/form"
import { GlobalContext } from "../../../../context/Global";
import { useEffect } from "react";
import toast from "react-hot-toast";
import ActionNewsMainContainer from "../actionNews/actionNewsMainContainer";
import ActionPerformedDiv from "../actionNews/actionPerformedDiv";
import ActionDetailSpan from "../actionNews/actionDetailSpan";
import BtnActionsDiv from "../actionNews/btnsActionDiv";
import ButtonActionNews from "../actionNews/btnActionsNews";
import UserLogo from "../actionNews/userLogo";
import UserCommentSection from "../actionNews/userCommentSection";
import UserCommentDiv from "../actionNews/userCommentDiv";
import UserNameSpan from "../actionNews/userNameSpan";
import UserParagraph from "../actionNews/userParagraph";
import InputEmoji from "../actionNews/inputNews/indexEmoji";
import parse from "html-react-parser";
import LikesModal from "./modalCurtida";


export default function ActionSection({ item }) {

    //ESTADO QUE ARMAZENA DADOS NA API
    const [comments, setComments] = useState([]);

    //FUNÇÃO QUE PEGA O ID DA NEWS
    function getComments(newsId) {
        axios.get(window.host + "/homes/api/home3.php?do=comentarios&noticia_id=" + newsId + "&token=" + window.token)
            .then((response) => {
                setComments(response.data)
                setCountComment(response.data.length)
            })
    };

    // - AÇÕES COMENTAR 
    //CONTAR COMENTÁRIOS
    const [countComment, setCountComment] = useState(false);

    // MOSTRAR O INPUT PARA COMENTAR
    const [showCommentsSection, setShowCommentsSection] = useState(false);

    //SALVAR OS COMENTÁRIOS
    const [userComment, setUserComment] = useState();
    const data = {
        id: item.id,
        loja_id: window.rs_id_lja,
        usuario_id: window.rs_id_usr,
        comentario: userComment,
    };

    //ATUALIZAR COMENTÁRIOS NA TELA
    const { formSuccess } = useContext(GlobalContext);

    useEffect(() => {
        getComments(item.id);
        setUserComment("");
        getViews();
    }, [formSuccess]);

    //ESTADO MOSTRAR COMENTÁRIOS
    const [showComments, setShowComments] = useState(false);

    //FUNÇÃO APAGAR COMENTÁRIO
    function removeComment(news_id, comment_id) {
        if (window.confirm("Deseja remover esse cometário?")) {
            axios({
                url: window.host + "/homes/api/home3.php?do=removerComentario&token=" + window.token,
                method: "post",
                data: {
                    comentario_id: comment_id,
                    noticia_id: news_id
                },
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(() => {
                getComments(item.id)
                toast("Comentário removido com sucesso!")
            })
        }
    };

    //AÇÕES CURTIR
    //ESTADO CURTIR
    const [stateLike, setStateLike] = useState(item.curtida);
    function likeUnlike() {
        if (stateLike == 0) {
            setStateLike(1)
            setCountLike(countLike + 1)
        } else if (stateLike == 1) {
            setStateLike(0)
            setCountLike(countLike - 1)
        }
    };

    //MODAL MOSTRAR QUEM DEU LIKE
    const [showLikes, setShowLikes] = useState(false);
    const handleShowLikes = () => setShowLikes(!showLikes);

    //ESTADOS 
    const [reload, setReload] = useState(false);

    //FECHAR MODAL LIKES
    const handleCloseModalLikes = (e) => {
        setShowLikes(e);
        setReload(true);
        setTimeout(() => {
            setReload(false)
        }, 500)
    };


    //ESTADO CONTAR LIKE
    const [countLike, setCountLike] = useState(item.curtidas);

    //FUNÇÃO QUE MANDA CURTIDAS PARA API
    function likeIt(news_id, likeUnlike) {
        axios({
            url: window.host + "/homes/api/home3.php?do=curtidaNoticia&token=" + window.token,
            method: "post",
            data: {
                id: news_id,
                loja_id: window.rs_id_lja,
                usuario_id: window.rs_id_usr,
                curtida: likeUnlike == 1 ? 0 : 1,
            },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }).then(() => {
        }).catch((_error) => { })
    }

    //VISUALIZADO - FALTA ENTENDER COMO FUNCIONA O CHECKED
    const [visualizado, setVisualizado] = useState(false);

    // MANDAR QUEM VISUALIZOU PARA A API
    function checkVisualizar(viewd) {
        axios({
            url: window.host + "/homes/api/home3.php?do=usuarioVisualizacao&token=" + window.token,
            method: "post",
            data: {
                id: viewd,
                usuario_id: window.rs_id_usr
            },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then((response) => {
            setChecked(response.config.data);
        })
    };


    // ESTADO QUE SALVA OS USUARIOS QUE VISUALIZARAM PARA MARCAR COMO CHECKED
    const [checked, setChecked] = useState([]);

    // PEGAR OS IDs DOS USUÁRIOS QUE MARCAM COMO VISUALIZADO, E COMPARAR SE O USUARIO QUE VISUALIZA A TELA É UM DELES -- a comparação está no className do Icon
    function getViews() {
        axios({
            url: window.host + "/homes/api/home3.php?do=listaVisualizacao&token=" + window.token,
            method: "post",
            data: { id: item.id },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }).then((response) => {
            setChecked(response.data.map((item) => { return (item.usuario_id) })) ///  --> vou desestruturar
            // for (var { usuario_id: usuarioId } of (response.data)) {
            //     setChecked([...checked, usuarioId]);
            // }; 
            // --> desestruturei, porém não deu a resposta que eu esperava
        }).catch((_error) => { })
    };

    return (
        <ActionNewsMainContainer>
            <LikesModal
                showLikes={showLikes}
                handleShowLikes={handleShowLikes}
                likedId={item.id}
                onHide={handleCloseModalLikes} />
            <ActionPerformedDiv>
                <div>
                    <ActionDetailSpan onClick={handleShowLikes}>
                        <Icon type="like" animated /> {countLike} curtidas
                    </ActionDetailSpan>

                    <ActionDetailSpan onClick={() => (showComments ? setShowComments(false) : (getComments(item.id), setShowComments(true)))}>
                        <Icon type="message" animated /> {countComment ? countComment : item.comentarios} comentarios
                    </ActionDetailSpan>
                </div>
                <div>
                    {(
                        item.documento_download === null || " " ? ""
                            : <ActionDetailSpan><Icon type="download" /></ActionDetailSpan>
                    )}
                    <Icon
                        title={checked.includes(Number(window.rs_id_usr)) ? "Visualização confirmada" : "Confirmar visualização"}
                        type="check"
                        animated
                        className={visualizado === true || checked.length > 0 && checked.includes(Number(window.rs_id_usr)) ? "text-success" : "text-secondary"}
                        onClick={() => (
                            checkVisualizar(item.id),
                            setVisualizado(!visualizado)
                        )}
                    />
                    <ViewModal nId={item.id} />
                </div>
            </ActionPerformedDiv>
            <BtnActionsDiv>
                <ButtonActionNews onClick={() => (
                    likeIt(item.id, stateLike),
                    setStateLike(likeUnlike)
                )}>
                    <Icon className={stateLike == 0 ? "" : "text-primary"} type="like" animated />
                    {(stateLike == 0 ? "Curtir" : "Curtido")}
                </ButtonActionNews>
                <ButtonActionNews onClick={() => setShowCommentsSection(!showCommentsSection)}>
                    <Icon type="message" animated />
                    Comentar
                </ButtonActionNews>
                <ButtonActionNews>
                    <Icon type="share" animated />
                    Compartilhar
                </ButtonActionNews>
            </BtnActionsDiv>
            {(
                !showCommentsSection ? " " :
                    <div className="divFormNews">
                        <Form className="formNews"
                            data={data}
                            api={window.host + "/homes/api/home3.php?do=comentarNoticia&token=" + window.token}
                        >
                            <UserLogo>
                                {window.rs_name_usr.slice(0, 1)}
                            </UserLogo>
                            <InputEmoji
                                type="text"
                                name="comentario"
                                placeholder="Adicione um comentário..."
                                value={userComment}
                                onChange={(e) => setUserComment(e.target.value)}
                            />
                        </Form>
                    </div>
            )}
            {(
                !showComments ? " " :
                    comments.map((comment) => {
                        return (
                            <UserCommentSection key={comment.id}>
                                <UserLogo>
                                    {comment.nome_usuario.charAt(0)}
                                </UserLogo>
                                <UserCommentDiv>
                                    <UserNameSpan>
                                        {comment.nome_usuario}
                                        {(
                                            comment.usuario_id == window.rs_id_usr ?
                                                <Icon type="trash" onClick={() => removeComment(item.id, comment.id)} />
                                                : " "
                                        )}
                                    </UserNameSpan>
                                    <UserParagraph>
                                        {parse(comment.comentario)}
                                    </UserParagraph>
                                </UserCommentDiv>
                            </UserCommentSection>
                        )
                    })
            )}
        </ActionNewsMainContainer >
    )
}