import { useEffect, useState } from "react";
import Icon from "../../icon";
import Modal from "../../modal";
import ModalBody from "../../modal/modalBody";
import ModalHeader from "../../modal/modalHeader";
import ModalTitle from "../../modal/modalHeader/modalTitle";
import Table from "../../table";
import Tbody from "../../table/tbody";
import Td from "../../table/tbody/td";
import Thead from "../../table/thead";
import Th from "../../table/thead/th";
import Tr from "../../table/tr";
import axios from "axios";

export default function LikesModal({ showLikes, likedId, onHide }) {

    const handleCloseModalLikes = () => {
        onHide(false)
    }

    //ESTADO QUE ARMAZENA QUEM DEU LIKE
    const [iD, setID] = useState([])

    //FUNÇÃO QUE PEGA QUEM DEU LIKE NA API
    function getWhoLikes() {
        axios({
            url: window.host + "/homes/api/home3.php?do=listaCurtida&token=" + window.token,
            method: "post",
            data: { id: likedId },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }).then((response) => {
            setID(response.data)
        })
    }
    useEffect(() => {
        getWhoLikes()
    }, [likedId])

    return (
        <>
            <Modal show={showLikes} onHide={handleCloseModalLikes} >
                <ModalHeader>
                    <ModalTitle>
                        Curtidas
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Table key={iD.id}>
                        <Thead>
                            <Tr>
                                <Th>Nome</Th>
                                <Th align="right">Data</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {(
                                iD ?
                                    iD.map((item) => {
                                        return (
                                            <Tr key={item.id}>
                                                <Td>{item.usuario}</Td>
                                                <Td align="right">{item.data}</Td>
                                            </Tr>
                                        )
                                    })
                                    : ""
                            )}

                        </Tbody>
                    </Table>
                </ModalBody>
            </Modal>
        </>
    )
}