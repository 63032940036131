import './reprovar.scss';

import { useState, useContext, useEffect } from 'react';

import Modal from '../../../body/modal';
import ModalHeader from '../../../body/modal/modalHeader';
import ModalTitle from '../../../body/modal/modalHeader/modalTitle';
import ModalBody from '../../../body/modal/modalBody';
import Icon from '../../icon';
import Form from '../../form';
import Textarea from '../../form/textarea';
import Button from '../../button';

import { GlobalContext } from "../../../../context/Global";
import Editar from '../editar';
import Input from '../../form/input';
import { cd } from '../../../../_assets/js/global';

export default function Reprovar(props){
    // CONTEXT
    const { filterModule, refresh, refreshCalendar, loadingCalendar } = useContext(GlobalContext);
    
    const [showModal, setShowModal] = useState(false);
    const [showModalPlano, setShowModalPlano] = useState(false);
    const [openNewJob, setOpenNewJob] = useState(false);
    const [observacao, setObservacao] = useState('');
    const [motivo, setMotivo] = useState('');
    const [plano, setPlano] = useState(false);
    const [submit, setSubmit] = useState(false);

    const data = {
        ativ_desc: 'Reprovou o '+(props.chamados?'chamado':'job'),
        id_job: props.id_job,
        id_mov: props.id_job_status,
        status: 2,
        msg: observacao,
        data_aux: undefined,
        tipo: (props.tipo ? props.tipo : 'next'),
        tipo_fase: (props.tipo_fase ? props.tipo_fase : 'Check'),
        communication: (props.chamados ? 0 : 1)
    }

    // FECHAR MODAL DO FORM
    function handleCloseModal(){
        setShowModal(false);
        setObservacao('');
        setPlano(false);
        
        if(!plano && submit){
            setTimeout(() => {
                refreshCalendar();
                loadingCalendar();
            },500);
        }
    }

    // CALLBACK DO MODAL DO PLANO DE AÇÃO
    function planoDeAcao(){
        setShowModal(false);
        setOpenNewJob(true);
        handleCloseModal();
    }

    // FUNÇÃO PARA ABRIR MODAL
    function handleShowModal(){
        if(props.motivo === false){
            setOpenNewJob(true);
        }else{
            setShowModal(true);
        }
    }

    useEffect(() => {
        if(submit){
            handleCloseModal();
            setTimeout(() => {
                setSubmit(false);
            },2000);
        }        
    },[submit]);

    // CALLBACK DO ENVIO
    const handleReprova = () => {
        props?.callback(props?.id_job_status);
    }

    return(
        <>
            {/* MODAL DE REPROVAÇÃO */}
            {(props.motivo !== false ?
                <Modal show={ showModal } onHide={() => handleCloseModal()}>
                    <ModalHeader>
                        <ModalTitle>
                            {(props.modalTitle?props.modalTitle:'Motivo')}
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <Form
                            api={window.host+'/systems/integration-react/api/list.php?do=set_statusSupervisor&filter_id_module='+filterModule+'&token='+window.token}
                            data={data}
                            toast="Card reprovado"
                            callback={() => (plano?planoDeAcao():setSubmit(true))}  
                            callback={handleReprova}           
                        >                            
                            <Textarea name="motivo" placeholder="Observação" value={observacao} onChange={(e) => (setObservacao(e.target.value), setMotivo(e.target.value))} />

                            {(!props.chamados && !props.fases && !props.visitas ?
                                <Input
                                    type="checkbox"
                                    id="plano_de_acao"
                                    name="plano_de_acao"
                                    label="Plano de Ação"
                                    checked={plano}
                                    onChange={(e) => setPlano(e.target.checked)}
                                    padding={false}
                                    required={false}
                                />
                            :'')}

                            <Button type="submit">
                                Salvar
                            </Button>
                        </Form>
                    </ModalBody>
                </Modal>
            :'')}

            {/* MODAL PARA CRIAR NOVO JOB COM PLANO DE AÇÃO */}
            {(openNewJob?
                <Editar
                    modalTitle={'Plano de Ação'+(props.job?' - '+props.job:'')}
                    icon={false}
                    show={true}
                    plano={true}
                    onClose={(e) => (openNewJob?setTimeout(() => {setOpenNewJob(false)},500) : {})}
                    frequency={4}
                    dateStart={window.currentDateWithoutHour}
                    job={(props.job ? props.job : false)}
                    category={2426}
                    subcategory={4251}
                    description={`Job reprovado: `+props.job+` (`+props.id_job_status+`)\nReprovado em: `+cd(window.currentDate)+` às `+window.currentHour+`:`+window.currentMinutes+`\nReprovado por: `+window.rs_name_usr+`\nMotivo: `+motivo}
                    id_lja={props?.id_lja}
                    id_usr={props?.id_usr}
                    chamados={props?.chamados}
                    fases={props?.fases}
                    visitas={props?.visitas}
                />
            :'')}

            <Icon
                type={(props.icon ? props.icon : 'times-circle')}
                title={(props.title?props.title:'Reprovar')}
                animated
                onClick={() => handleShowModal()}
            />
        </>
    )
}
