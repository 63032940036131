import { useState, useEffect } from "react";
import Row from "../../../components/body/row";
import Col from "../../../components/body/col";
import Chart from "../../../components/body/chart";
import SelectReact from "../../../components/body/select"
import { get_date, cd } from "../../../_assets/js/global";
import FilterCheckbox from "../../../components/body/filterCheckbox";
import Input from "../../../components/body/form/input";
import Icon from "../../../components/body/icon";

export default function DashboardAvaliacao({ icons, filters }) {

    //hoje
    var date = new Date();
    //ESTADOS FILTROS
    const [tipo, setTipo] = useState(1);
    const [loja, setLoja] = useState('');
    const [funcionario, setFuncionario] = useState(1);
    const [periodo, setPeriodo] = useState((new Date(date.getFullYear(), date.getMonth(), 1)));

    //
    const options_main = [
        { value: 1, label: "Empresa" }, { value: 2, label: "Funcionário" }
    ]
    const options_funcionario = [
        { value: 1, label: "Fernanda Costa" }, { value: 2, label: "Amanda Souza" }
    ]

    // FILTRA LOJA
    const handleFilterLoja = (selectedVals) => {
        setLoja(selectedVals);
    }

    useEffect(() => {
        if (icons) {
            icons(
                <>
                     <Icon type="print" />
                  {/*  <Icon type="pdf" /> */}
                </>
            )
        }
        if (filters) {
            filters(
                <>
                    <SelectReact
                        name="tipo"
                        value={tipo}
                        onChange={(e) => setTipo(e.value)}
                        options={options_main}
                    />
                    {
                        tipo === 1 ?

                            <FilterCheckbox
                                id="filter_store"
                                name="filter_store"
                                api={{
                                    url: window.host + '/systems/integration-react/api/list.php?do=headerFilter&token=' + window.token,
                                    params: {
                                        filters: [{ filter: 'store' }],
                                        // empreendimento_id: filterEmpreendimento,
                                        limit: 50,
                                        np: true
                                    },
                                    key: 'store',
                                    // reload: filterEmpreendimento
                                }}
                                onChangeClose={handleFilterLoja}
                                // hide={(tipoCalendario == 3 && subTipoCalendario == 'store') || tipoCalendario == 2 ? false : (((window.rs_permission_apl === 'lojista' || window.rs_permission_apl === 'operador' || window.rs_permission_apl === 'gerente' || window.rs_permission_apl === 'checker')) || (tipoCalendario != 3 && tipoCalendario != 2) || subTipoCalendario == 'user' ? true : false)}
                                value={loja}
                            >
                                Lojas
                            </FilterCheckbox>
                            :
                            <SelectReact
                                options={options_funcionario}
                                placeholder
                                name="funcionario"
                                value={funcionario}
                                onChange={(e) => setFuncionario(e.value)}
                            />
                    }
                      <Input
                        type="date"
                        format="mm/yyyy"
                        name="filter_date"
                        required={false}
                        value={periodo}
                        onChange={(e) => setPeriodo(e)}
                    />
                </>
            )
        }
    }, [tipo, funcionario, loja, periodo]);

    return (
        <Row>
            {
                tipo === 1 ?
                    <Col lg={8}>
                        <Chart
                            id="desempenho-empresa"
                            title="Avaliação de Desempenho - Empresa"
                            type="LineChart"
                            headers={['Mês', 'EE', 'AE', 'PM', 'I']}
                            body={{
                                type: "custom",
                                content: [
                                    [get_date('month_name', get_date('date_sql', cd(periodo), 'date_sub_month', 11), 'date_sql'), 100, 50, 100, 80],
                                    [get_date('month_name', get_date('date_sql', cd(periodo), 'date_sub_month', 10), 'date_sql'), 50, 90, 80, 70],
                                    [get_date('month_name', get_date('date_sql', cd(periodo), 'date_sub_month', 9), 'date_sql'), 80, 40, 70, 80],
                                    [get_date('month_name', get_date('date_sql', cd(periodo), 'date_sub_month', 8), 'date_sql'), 90, 120, 150, 90],
                                    [get_date('month_name', get_date('date_sql', cd(periodo), 'date_sub_month', 7), 'date_sql'), 120, 100, 90, 40],
                                    [get_date('month_name', get_date('date_sql', cd(periodo), 'date_sub_month', 6), 'date_sql'), 90, 200, 140, 60],
                                    [get_date('month_name', get_date('date_sql', cd(periodo), 'date_sub_month', 5), 'date_sql'), 80, 150, 50, 70],
                                    [get_date('month_name', get_date('date_sql', cd(periodo), 'date_sub_month', 4), 'date_sql'), 95, 40, 90, 80],
                                    [get_date('month_name', get_date('date_sql', cd(periodo), 'date_sub_month', 3), 'date_sql'), 75, 90, 120, 50],
                                    [get_date('month_name', get_date('date_sql', cd(periodo), 'date_sub_month', 2), 'date_sql'), 130, 70, 100, 30],
                                    [get_date('month_name', get_date('date_sql', cd(periodo), 'date_sub_month', 1), 'date_sql'), 80, 60, 30, 40],
                                    [get_date('month_name', get_date('date_sql', cd(periodo), 'date_sub_month', 0), 'date_sql'), 130, 90, 90, 20]
                                    // [get_date('month_name', get_date('date_sql', cd(dataInicial), 'date_sub_month', 5), 'date_sql'), (monthJobs[0]?.qtd_feito ? monthJobs[0]?.qtd_feito : 0), (monthChamados[0]?.qtd_pendente ? monthChamados[0]?.qtd_pendente : 0), (monthComunicados[0]?.qtd_total ? monthComunicados[0]?.qtd_total : 0), (monthChecklist[0]?.qtd_aprovado ? monthChecklist[0]?.qtd_aprovado : 0)],
                                    // [get_date('month_name', get_date('date_sql', cd(dataInicial), 'date_sub_month', 4), 'date_sql'), (monthJobs[1]?.qtd_feito ? monthJobs[1]?.qtd_feito : 0), (monthChamados[1]?.qtd_pendente ? monthChamados[1]?.qtd_pendente : 0), (monthComunicados[1]?.qtd_total ? monthComunicados[1]?.qtd_total : 0), (monthChecklist[1]?.qtd_aprovado ? monthChecklist[1]?.qtd_aprovado : 0)],
                                    // [get_date('month_name', get_date('date_sql', cd(dataInicial), 'date_sub_month', 3), 'date_sql'), (monthJobs[2]?.qtd_feito ? monthJobs[2]?.qtd_feito : 0), (monthChamados[2]?.qtd_pendente ? monthChamados[2]?.qtd_pendente : 0), (monthComunicados[2]?.qtd_total ? monthComunicados[2]?.qtd_total : 0), (monthChecklist[2]?.qtd_aprovado ? monthChecklist[2]?.qtd_aprovado : 0)],
                                    // [get_date('month_name', get_date('date_sql', cd(dataInicial), 'date_sub_month', 2), 'date_sql'), (monthJobs[3]?.qtd_feito ? monthJobs[3]?.qtd_feito : 0), (monthChamados[3]?.qtd_pendente ? monthChamados[3]?.qtd_pendente : 0), (monthComunicados[3]?.qtd_total ? monthComunicados[3]?.qtd_total : 0), (monthChecklist[3]?.qtd_aprovado ? monthChecklist[3]?.qtd_aprovado : 0)],
                                    // [get_date('month_name', get_date('date_sql', cd(dataInicial), 'date_sub_month', 1), 'date_sql'), (monthJobs[4]?.qtd_feito ? monthJobs[4]?.qtd_feito : 0), (monthChamados[4]?.qtd_pendente ? monthChamados[4]?.qtd_pendente : 0), (monthComunicados[4]?.qtd_total ? monthComunicados[4]?.qtd_total : 0), (monthChecklist[4]?.qtd_aprovado ? monthChecklist[4]?.qtd_aprovado : 0)],
                                    // [get_date('month_name', get_date('date_sql', cd(dataInicial), 'date_sub_month', 0), 'date_sql'), (monthJobs[5]?.qtd_feito ? monthJobs[5]?.qtd_feito : 0), (monthChamados[5]?.qtd_pendente ? monthChamados[5]?.qtd_pendente : 0), (monthComunicados[5]?.qtd_total ? monthComunicados[5]?.qtd_total : 0), (monthChecklist[5]?.qtd_aprovado ? monthChecklist[5]?.qtd_aprovado : 0)]
                                ]
                            }}
                            height={(window.isMobile ? 400 : 650)}
                        // loading={loadingMonth}
                        />
                    </Col>
                    :
                    <Col lg={6}>
                        <Chart
                            id="desempenho-funcionario"
                            title="Avaliação de Desempenho - Fernanda Costa"
                            type="LineChart"
                            headers={['Mês', 'EE', 'AE', 'PM', 'I']}
                            body={{
                                type: "custom",
                                content: [
                                    ['15', 10, 50, 10, 80],
                                    ['30', 50, 90, 80, 70],
                                    ['45', 80, 40, 70, 80],
                                    ['60', 90, 10, 15, 90],
                                    ['90', 120, 10, 90, 40],
                                    ['120', 90, 20, 140, 60],
                                    ['150', 80, 10, 50, 70],
                                    ['180', 95, 40, 90, 80],
                                ]
                            }}
                            height={(window.isMobile ? 400 : 650)}
                        // loading={loadingMonth}
                        />
                    </Col>
            }
        </Row>
    )
}
