import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import Chat from "../../../components/body/chat";
import Message from "../../../components/body/chat/message";
import Loader from "../../../components/body/loader";
import Modal from "../../../components/body/modal";
import ModalBody from "../../../components/body/modal/modalBody";
import ModalHeader from "../../../components/body/modal/modalHeader";
import ModalTitle from "../../../components/body/modal/modalHeader/modalTitle";
import Icon from "../../../components/body/icon";

export default function Historico(props){
    // ESTADOS
    const [show, setShow] = useState(false);
    const [mensagens, setMensagens] = useState([]);
    const [loader, setLoader] = useState(true);

    // FECHA MODAL
    const handleCloseModal = () => {
        setShow(false);
        props.onHide(false);

        setTimeout(() => {
            setLoader(true);
        },500);
    }

    // GET MENSAGENS
    useEffect(() => {
        if(props?.show){
            setShow(true);

            axios({
                method: 'get',
                url: window.host+'/systems/trade/api/trade.php?do=mensagem_historico&token='+window.token,
                params: {
                    id: props?.id,
                    filtro_data: props?.filtro_data,
                    filtro_data_inicio: props?.filtro_data_inicio,
                    filtro_data_fim: props?.filtro_data_fim
                }
            }).then((response) => {
                setMensagens(response.data);
                // setLoader(false);
            });
        }
    },[props?.show]);

    return(
        <>
            <Modal show={show} onHide={handleCloseModal}>
                <ModalHeader>
                    <ModalTitle>
                        Histórico
                    </ModalTitle> 
                </ModalHeader>
                <ModalBody>
                    <p>
                        <b>Contrato:</b> {props.contrato}<br />
                        <b>Loja: </b>{props.loja}<br />
                        <b>Grupo: </b>{props.grupo}<br />
                        <b>Data: </b>{props.data}
                    </p>
                    <Chat 
                        anexo={false}
                        loader={loader}
                    >
                        {(mensagens.length > 0 ?
                            mensagens.map((mensagem) => {
                                return(
                                    <Message
                                        date={mensagem.data}
                                        sender={mensagem.nome}
                                        text={mensagem.mensagem}
                                        align={(mensagem.self == 1 ? 'right' : 'left')}
                                    />
                                )
                            })                         
                        :'')}
                    </Chat>
                </ModalBody>
            </Modal>

            <Icon
                type="mensagem"
                title="Hsitórico de mensagens"
                onClick={() => setShow(true)}
            />
        </>
    )
}