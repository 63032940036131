import { useState, useEffect } from 'react';

import Gerenciador from '../../../components/body/gerenciador';
import Input from '../../../components/body/form/input';
import SelectReact from '../../../components/body/select';
import Table from '../../../components/body/table';
import Tbody from '../../../components/body/table/tbody';
import Tr from '../../../components/body/table/tr';
import Td from '../../../components/body/table/tbody/td';
import Form from '../../../components/body/form';
import Icon from '../../../components/body/icon';
import Button from '../../../components/body/button';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import Switch from '../../../components/body/switch';
import CheckboxGroup from '../../../components/body/form/checkboxGroup';
import { scrollToCol } from '../../../_assets/js/global';

export default function Usuarios({emp, store, type, callback, disabled}){
    // ESTADOS
    const [items, setItems] = useState([]);
    const [active, setActive] = useState(null);
    const [filter, setFilter] = useState(null);
    const [filterInactive, setFilterInactive] = useState(false);
    const [edit, setEdit] = useState(false);
    const [page, setPage] = useState(0);
    const [buttonStatus, setButtonStatus] = useState('');

    // ESTADOS DO FORM
    const [loadingForm, setLoadingForm] = useState(true);
    const [reload, setReload] = useState(false);
    const [id, setId] = useState('');
    const [nome, setNome] = useState('');
    const [loja, setLoja] = useState(store);
    const [optionsLoja, setOptionsLoja] = useState([]);
    const [login, setLogin] = useState('');
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [senhaConfirmacao, setSenhaConfirmacao] = useState('');
    const [telefone, setTelefone] = useState('');
    const [cpf, setCpf] = useState('');
    const [rg, setRg] = useState('');
    const [nascimento, setNascimento] = useState('');
    const [sexo, setSexo] = useState('');
    const [civil, setCivil] = useState('');
    const [cargo, setCargo] = useState('');
    const [optionsCargo, setOptionsCargo] = useState([]);
    const [departamento, setDepartamento] = useState('');
    const [optionsDepartamento, setOptionsDepartamento] = useState([]);
    const [permissaoEmpreendimento, setPermissaoEmpreendimento] = useState([]);
    const [permissaoEmpreendimentoValues, setPermissaoEmpreendimentoValues] = useState([]);
    const [permissaoEmpreendimentoChanged, setPermissaoEmpreendimentoChanged] = useState(false);
    const [permissaoLoja, setPermissaoLoja] = useState([]);
    const [permissaoLojaValues, setPermissaoLojaValues] = useState([]);
    const [permissaoLojaVisible, setPermissaoLojaVisible] = useState(false);
    const [permissaoLojaLoading, setPermissaoLojaLoading] = useState(true);
    const [permissaoLojaChanged, setPermissaoLojaChanged] = useState(false);
    const [permissaoUsuario, setPermissaoUsuario] = useState([]);
    const [permissaoUsuarioValues, setPermissaoUsuarioValues] = useState([]);
    const [permissaoUsuarioVisible, setPermissaoUsuarioVisible] = useState(false);
    const [permissaoUsuarioLoading, setPermissaoUsuarioLoading] = useState(true);
    const [permissaoUsuarioChanged, setPermissaoUsuarioChanged] = useState(false);

    // SETA USUÁRIO ATIVO
    function handleSetActive(id, id_loja){
        setActive(id);
        setEdit(false);
        setPage(0);

        if(callback){
            callback({
                active: id,
                store: id_loja
            })
        }

        // SCROLL AUTOMÁTICO ATÉ A COLUNA DE SISTEMAS
        scrollToCol('sistemas');
    }

    // RESETA O FILTRO E O ITEM ATIVO CASO TROQUE O EMPREENDIMENTO OU LOJA
    useEffect(() => {
        setActive(null);
        setFilter(null);
        setEdit(false);
        setPage(0);
    },[emp, store]);

    // CALLBACK DA API DA TABLE
    const handleSetItems = (e) => {
        setItems(e)
    }

    // CALLBACK PARA SETAR O PAGE
    const handleSetPage = (e) => {
        setPage(e.page);
    }

    // RESETAR FORM
    function reset_form(){
        setId('');
        setNome('');
        setEmail('');
        setLogin('');        
        setSenha('');
        setSenhaConfirmacao('');
        setTelefone('');
        setCpf('');
        setRg('');
        setNascimento('');
        setSexo('');
        setCivil('');
        setLoja(store);
        setCargo('');
        setDepartamento('');
        setPermissaoEmpreendimento([]);
        setPermissaoEmpreendimentoValues([]);
        setPermissaoEmpreendimentoChanged(false);
        setPermissaoLoja([]);
        setPermissaoLojaValues([]);
        setPermissaoLojaChanged(false);
        setPermissaoUsuario([]);
        setPermissaoUsuarioValues([]);
        setPermissaoUsuarioChanged(false);
    }

    // AÇÕES AO ABRIR FORM DE CADASTRO
    const handleOpenForm = (id) => {
        reset_form();
        
        if(id){
            setEdit(id);
            setLoadingForm(true);

            axios({
                method: 'get',
                url: window.host+'/systems/gerenciador/api/settings.php?do=fetchColumn&token='+window.token,
                params: {
                    columns: [
                        {column: 'user', filter: {id: id}},
                        {column: 'user_permission_enterprise', filter: {id_usuario: id}}
                    ]
                }
            }).then((response) => {
                // SETA INFORMAÇÕES DO USUÁRIO NO FORMULÁRIO
                setId(response?.data?.user?.data[0]?.id);
                setNome(response?.data?.user?.data[0]?.nome);
                setEmail(response?.data?.user?.data[0]?.email)
                setLogin(response?.data?.user?.data[0]?.login);
                setLoja(response?.data?.user?.data[0]?.id_loja);
                setCargo(response?.data?.user?.data[0]?.id_cargo);
                setDepartamento(response?.data?.user?.data[0]?.id_departamento);
                setTelefone(response?.data?.user?.data[0]?.telefone);
                setCpf(response?.data?.user?.data[0]?.cpf);
                setRg(response?.data?.user?.data[0]?.rg);
                setNascimento(response?.data?.user?.data[0]?.nascimento);
                setSexo(response?.data?.user?.data[0]?.sexo);
                setCivil(response?.data?.user?.data[0]?.civil);
                setLoadingForm(false);     
                
                // SETA PERMISSÕES DE EMPREENDIMENTOS
                let permissao_empreendimento_values = [];
                response?.data?.user_permission_enterprise?.data.map((item, i) => {
                    if(item.permissao_usuario === 1){
                        permissao_empreendimento_values.push(item.id);
                    }
                });
                setPermissaoEmpreendimento(response?.data?.user_permission_enterprise?.data);
                setPermissaoEmpreendimentoValues(permissao_empreendimento_values);
            });
        }else{
            setEdit(true);
            reset_form();
            setLoadingForm(false);
        }

        // SCROLL AUTOMÁTICO ATÉ O FORMULÁRIO DE EDIÇÃO
        scrollToCol('usuario_insert');

        callback({
            edit: true
        })
    }

    // AÇÕES AO FECHAR FORM DE CADASTRO
    const handleCloseForm = () => {
        setEdit(false);
        reset_form();

        callback({
            edit: false
        })

        setTimeout(() => {
            setLoadingForm(true);
        },200);
    }

    // VERIFICA STATUS DO FORM
    const handleFormStatus = (e) => {
        setButtonStatus(e);
    }

    // RECARREGA LISTA AO RECEBER O RETORNO DA API DE CADASTRO
    const handleReloadForm = (e) => {
        if(e.user.status === 'Warning'){
            if(e.user.msg === 'Login inválido.'){
                toast('Login indisponível, tente novamente com um diferente.')
            }
        }else{
            setPage(0);
            setReload(!reload);
        }
    }

    // ATIVAR / DESATIVAR ITEM
    const handleSetItemActive = (id, ativo) => {
        toast('Usuário ' + (ativo ? 'ativado' : 'desativado'));

        axios({
            method: 'post',
            url: window.host+"/systems/gerenciador/api/settings.php?do=insUpdColumn&token=" + window.token,
            data: {
                columns: [{
                    column: 'user',
                    params: {
                        id: id,
                        ativo: ativo
                    }
                }]
            },
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(() => {
            // handleReloadForm();
        })
    }

    // FILTRO DE INATIVOS
    const handleSetFilterInactive = () => {
        setFilterInactive(!filterInactive);
        handleReloadForm();
    }

    // GET PERMISSÕES ATUALIZADAS CASO MARQUE ALGUM EMPREENDIMENTO
    function get_permissao_loja(){
        axios({
            method: 'get',
            url: window.host+'/systems/gerenciador/api/settings.php?do=fetchColumn&token='+window.token,
            params: {
                columns: [
                    {column: 'user_permission_store', filter: {id_usuario: id, id_emp_aux: (permissaoEmpreendimentoChanged ? permissaoEmpreendimentoValues : null), limit: -1}}
                ]
            }
        }).then((response) => {
            let permissao_loja_values = [];
            response?.data?.user_permission_store?.data.map((item, i) => {
                if(item.permissao_usuario === 1){
                    permissao_loja_values.push(item.id);
                }
            });
            setPermissaoLoja(response?.data?.user_permission_store?.data);
            setPermissaoLojaValues(permissao_loja_values);
            setPermissaoLojaLoading(false);
        });
    }

    function get_permissao_usuario(){
        axios({
            method: 'get',
            url: window.host+'/systems/gerenciador/api/settings.php?do=fetchColumn&token='+window.token,
            params: {
                columns: [
                    {column: 'user_permission_user', filter: {id_usuario: id, id_emp_aux: (permissaoEmpreendimentoChanged ? permissaoEmpreendimentoValues : null), limit: -1}}
                ]
            }
        }).then((response) => {
            let permissao_usuario_values = [];
            response?.data?.user_permission_user?.data.map((item, i) => {
                if(item.permissao_usuario === 1){
                    permissao_usuario_values.push(item.id);
                }
            });
            setPermissaoUsuario(response?.data?.user_permission_user?.data);
            setPermissaoUsuarioValues(permissao_usuario_values);
            setPermissaoUsuarioLoading(false);
        });
    }

    // CHAMA AS PERMISSÕES DE LOJA E USUÁRIO SEMPRE QUE ALTERAR A PERMISSÃO DE EMPREENDIMENTO
    useEffect(() => {
        if(permissaoLojaVisible){
            setPermissaoLojaLoading(true);
            get_permissao_loja();
        }

        if(permissaoUsuarioVisible){
            setPermissaoUsuarioLoading(true);
            get_permissao_usuario();
        }
    },[permissaoEmpreendimentoValues]);

    // CHAMADAS AO ABRIR O FORMULÁRIO DE EDIÇÃO
    useEffect(() => {
        // BUSCA LOJAS
        if(optionsLoja.length == 0){
            axios({
                method: 'get',
                url: window.host+'/api/sql.php?do=select&component=loja&token='+window.token
            }).then((response) => {
                setOptionsLoja(response.data);
            });
        }

        // BUSCA DEPARTAMENTOS
        if(optionsDepartamento.length == 0){
            axios({
                method: 'get',
                url: window.host+'/api/sql.php?do=select&component=departamento&token='+window.token
            }).then((response) => {
                setOptionsDepartamento(response.data);
            });
        }

        // BUSCA CARGOS
        if(optionsCargo.length == 0){
            axios({
                method: 'get',
                url: window.host+'/api/sql.php?do=select&component=cargo&token='+window.token
            }).then((response) => {
                setOptionsCargo(response.data);
            });
        }
    },[edit]);

    // CHECK PERMISSÃO EMPREENDIMENTOS
    const handleCheckEmpreendimento = (e) => {
        setPermissaoEmpreendimentoValues(e);
        setPermissaoEmpreendimentoChanged(true);
    }

    // CHECK PERMISSÃO LOJAS
    const handleCheckLoja = (e) => {
        setPermissaoLojaValues(e);
        setPermissaoLojaChanged(true);
    }

    // CHECK PERMISSÃO USUÁRIOS
    const handleCheckUsuario = (e) => {
        setPermissaoUsuarioValues(e);
        setPermissaoUsuarioChanged(true);
    }

    // AÇÕES AO ABRIR BOX DE PERMISSÃO DE LOJAS
    const handleCollapseLoja = (e) => {
        if(e){
            setPermissaoLojaVisible(true);
            if(permissaoLoja.length === 0){
                setPermissaoLojaLoading(true);
                get_permissao_loja();
            }
        }else{
            setPermissaoLojaVisible(false);
        }
    }

    // AÇÕES AO ABRIR BOX DE PERMISSÃO DE USUÁRIOS
    const handleCollapseUsuario = (e) => {
        if(e){
            setPermissaoUsuarioVisible(true);
            if(permissaoUsuario.length === 0){
                setPermissaoUsuarioLoading(true);
                get_permissao_usuario();
            }
        }else{
            setPermissaoUsuarioVisible(false);
        }
    }

    // OPTIONS SEXO
    const optionsSexo = [
        {value: 'Feminino', label: 'Feminino'},
        {value: 'Masculino', label: 'Masculino'},
        {value: 'Outros', label: 'Outros'},
    ]

    // OPTIONS ESTADO CIVIL
    const optionsCivil = [
        {value: 'Solteiro(a)', label: 'Solteiro(a)'},
        {value: 'Casado(a)', label: 'Casado(a)'},
        {value: 'Separado(a)', label: 'Separado(a)'},
        {value: 'Divorcidado(a)', label: 'Divorcidado(a)'},
        {value: 'Viúvo(a)', label: 'Viúvo(a)'}
    ]

    // DATA FORM
    const data = {
        columns: [{
            column: 'user',
            params: {
                id: id,
                nome: nome,
                id_emp: (emp ? emp : window.rs_id_emp),
                id_loja: loja,
                login: login,
                email: email,
                senha: (senha ? senha : undefined),
                telefone: (telefone ? telefone : undefined),
                cpf: (cpf ? cpf : undefined),
                rg: (rg ? rg : undefined),
                data_nascimento: (nascimento ? nascimento : undefined),
                sexo: (sexo ? sexo : undefined),
                estado_civil: (civil ? civil : undefined),
                id_cargo: cargo,
                id_departamento: departamento,
                permissao: {
                    id_emp: (permissaoEmpreendimentoChanged ? (permissaoEmpreendimentoValues.length > 0 ? permissaoEmpreendimentoValues.toString() : -1) : undefined),
                    id_loja: (permissaoLojaChanged ? (permissaoLojaValues.length > 0 ? permissaoLojaValues.toString() : -1) : undefined),
                    id_usuario: (permissaoUsuarioChanged ? (permissaoUsuarioValues.length > 0 ? permissaoUsuarioValues.toString() : -1) : undefined),
                },
                ativo: 1
            }
        }]
    }

    return(
        <>
            <Gerenciador 
                id="usuarios"
                title="Usuários"
                search={
                    <Input
                        name="filter_usuarios"
                        value={filter}
                        onChange={(e) => (setFilter(e.target.value), setPage(0))}
                    />
                }
                icon={
                    ((!window.rs_id_lja || window.rs_id_lja == 0) && (window.rs_permission_apl === 'edicao' || window.rs_permission_apl === 'master') ? 
                        <Icon
                            type="new"
                            title="Novo usuário"
                            onClick={() => handleOpenForm()}
                        />
                    :'')
                }
                switch={
                    <Input
                        type={'checkbox'}
                        name="usuários_inativos"
                        label="Inativos"
                        inverse={true}
                        onClick={handleSetFilterInactive}
                    />
                }
                disabled={disabled}
            >
                <Table
                    id="table_usuarios"
                    api={window.host+'/systems/gerenciador/api/settings.php?do=fetchColumn&token='+window.token}
                    params={{
                        columns: [
                            {column: 'user', filter: {ativo: (filterInactive ? [1,0] : [1]), id_emp: (emp ? emp : window.rs_id_emp), id_loja: store, nome: filter, page: page}}
                        ]
                    }}
                    key_aux={'user'}
                    onLoad={handleSetItems}
                    callback={handleSetPage}
                    reload={emp + store + type + reload}
                    search={filter}
                    text_limit={(window.isMobile ? 20 : 30)}
                >
                    <Tbody>
                        {(items.length > 0 ?
                            items.map((item, i) => {
                                return(
                                    <Tr
                                        key={'usuario_'+item.id}
                                        cursor="pointer"
                                        active={(active === item.id ? true : false)}
                                    >
                                        <Td
                                            onClick={() => handleSetActive(item.id, item?.id_loja)}
                                        >
                                            {item.nome}
                                            {(store ? '' : <span className="text-secondary"> {(item?.id_loja ? '(Lojista)' : '(Administração)')}</span>)}
                                        </Td>

                                        <Td width={1} align="center">
                                            {((!window.rs_id_lja || window.rs_id_lja == 0) && (window.rs_permission_apl === 'edicao' || window.rs_permission_apl === 'master') ?
                                                <>
                                                    <Icon 
                                                        type={'edit'}
                                                        active={(edit === item.id ? true : false)}
                                                        onClick={() => handleOpenForm(item.id)}
                                                        animated
                                                    />

                                                    <Switch
                                                        type="check"
                                                        title={(item.ativo == 1 ? 'Desativar' : 'Ativar')}
                                                        checked={(item.ativo == 1 ? true : false)}
                                                        onChange={() => handleSetItemActive(item.id, (item.ativo == 1 ? 0 : 1))}
                                                        animated
                                                    />
                                                </>
                                            :'')}
                                        </Td>
                                    </Tr>
                                )
                            })
                        :
                            <></>
                        )}
                    </Tbody>
                </Table>
            </Gerenciador>

            {/* FORMULÁRIO DE CADASTRO/EDIÇÃO */}
            {(edit ? 
                <Gerenciador 
                    id="usuario_insert"
                    title={(id ? ('Editar '+nome) : 'Novo usuário')}
                    search={false}
                    icon={
                        <Icon
                            type="reprovar"
                            title="Fechar"
                            onClick={handleCloseForm}
                        />
                    }
                    disabled={disabled}
                >
                    <Form
                        api={window.host+"/systems/gerenciador/api/settings.php?do=insUpdColumn&token=" + window.token}
                        data={data}
                        status={handleFormStatus}
                        response={handleReloadForm}
                        toast={'Usuário salvo com sucesso'}
                    >
                        <Input
                            type="text"
                            name="usuario_nome"
                            label="Nome"
                            value={nome}
                            loading={loadingForm}
                            onChange={(e) => setNome(e.target.value)}
                        />

                        <Input
                            type="email"
                            name="usuario_email"
                            label="E-mail"
                            value={email}
                            loading={loadingForm}
                            onChange={(e) => setEmail(e.target.value)}
                        />

                        <Input
                            type="text"
                            name="usuario_login"
                            label="Login"
                            value={login}
                            loading={loadingForm}
                            onChange={(e) => setLogin(e.target.value)}
                        />

                        <Input
                            type="password"
                            name="usuario_senha"
                            label="Senha"
                            value={senha}
                            loading={loadingForm}
                            onChange={(e) => setSenha(e.target.value)}
                            placeholder={(id ? '*******' : '')}
                            required={(id ? false : true)}
                        />

                        <Input
                            type="password"
                            name="usuario_senha_confirmacao"
                            label="Repita a senha"
                            value={senhaConfirmacao}
                            loading={loadingForm}
                            onChange={(e) => setSenhaConfirmacao(e.target.value)}
                            placeholder={(id ? '*******' : '')}
                            required={(id ? false : true)}
                        />

                        <Input
                            type="tel"
                            name="usuario_telefone"
                            label="Telefone"
                            mask="(99) 9999-99999"
                            value={telefone}
                            loading={loadingForm}
                            onChange={(e) => setTelefone(e.target.value)}
                            required={false}
                        />

                        <Input
                            type="text"
                            name="usuario_cpf"
                            label="CPF"
                            mask="999.999.999-99"
                            value={cpf}
                            loading={loadingForm}
                            onChange={(e) => setCpf(e.target.value)}
                            required={false}
                        />

                        <Input
                            type="text"
                            name="usuario_rg"
                            label="RG"
                            mask="99.999.999-9"
                            value={rg}
                            loading={loadingForm}
                            onChange={(e) => setRg(e.target.value)}
                            required={false}
                        />

                        <Input
                            type="date"
                            name="usuario_nascimento"
                            label="Data de Nascimento"
                            value={nascimento}
                            loading={loadingForm}
                            onChange={(e) => setNascimento(e.target.value)}
                            required={false}
                        />

                        <SelectReact
                            name="usuario_sexo"
                            label="Sexo"
                            options={optionsSexo}
                            value={sexo}
                            loading={loadingForm}
                            onChange={(e) => setSexo(e.value)}
                            required={false}
                        />

                        <SelectReact
                            name="usuario_civil"
                            label="Estado Civíl"
                            options={optionsCivil}
                            value={civil}
                            loading={loadingForm}
                            onChange={(e) => setCivil(e.value)}
                            required={false}
                        />

                        <SelectReact
                            name="usuario_loja"
                            label="Loja"
                            options={optionsLoja}
                            value={loja}
                            loading={loadingForm}
                            onChange={(e) => setLoja(e.value)}
                            required={false}
                        />

                        <SelectReact
                            name="usuario_cargo"
                            label="Cargo"
                            options={optionsCargo}
                            value={cargo}
                            loading={loadingForm}
                            onChange={(e) => setCargo(e.value)}
                            required={false}
                        />

                        <SelectReact
                            name="usuario_departamento"
                            label="Departamento"
                            options={optionsDepartamento}
                            value={departamento}
                            loading={loadingForm}
                            onChange={(e) => setDepartamento(e.value)}
                            required={false}
                        />

                        {(id ?
                            <>
                                {(permissaoEmpreendimento.length > 0 ?
                                    <CheckboxGroup
                                        group="empreendimento"
                                        options={permissaoEmpreendimento}
                                        value={permissaoEmpreendimentoValues}
                                        loading={loadingForm}
                                        required={false}
                                        callback={handleCheckEmpreendimento}
                                    />
                                :'')}

                                <CheckboxGroup
                                    group="loja"
                                    options={permissaoLoja}
                                    value={permissaoLojaValues}
                                    loading={loadingForm}
                                    loadingItems={permissaoLojaLoading}
                                    required={false}
                                    collapse={handleCollapseLoja}
                                    callback={handleCheckLoja}                                    
                                />

                                <CheckboxGroup
                                    group="usuario"
                                    options={permissaoUsuario}
                                    value={permissaoUsuarioValues}
                                    loading={loadingForm}
                                    loadingItems={permissaoUsuarioLoading}
                                    required={false}
                                    collapse={handleCollapseUsuario}
                                    callback={handleCheckUsuario}
                                />
                            </>
                        :'')}

                        <Button
                            type="submit"
                            loading={loadingForm}
                            status={buttonStatus}
                            title={(senha !== senhaConfirmacao ? 'As senhas não coincidem' : '')}
                            disabled={(senha !== senhaConfirmacao ? true : false)}
                        >
                            Salvar
                        </Button>
                    </Form>
                </Gerenciador>
            :'')}
        </>
    )
}
