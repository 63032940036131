import { useEffect, useState, useContext } from "react";
import { TreinamentoContext } from "../../../../context/Treinamento";

import Input from "../../../../components/body/form/input";
import Table from "../../../../components/body/table";
import Tbody from "../../../../components/body/table/tbody";
import Thead from "../../../../components/body/table/thead";
import Th from "../../../../components/body/table/thead/th";
import Tr from "../../../../components/body/table/tr";
import Items from "./items";
import axios from "axios";

export default function Sidebar(){
    // CONTEXT
    const {
        filterName,
        handleSetFilterName
    } = useContext(TreinamentoContext);

    //ESTADOS
    const [items, setItems] = useState([]);    

    function loadItems() {
        axios.get(window.host+'/systems/treinamento/api/lista.php?do=get_category&token='+window.token).then((response) => {
            setItems(response.data);
        })
    }

    // CARREGAMENTO INICIAL
    useEffect(() => {
        loadItems();
    },[]);

    return(
        <Table fullWidth={true} >
            <Thead>
                <Tr>
                    <Th colspan={2}>
                        <Input
                            placeholder="Pesquisar..."
                            type="text"
                            name="pesquisar"
                            value={filterName}
                            onChange={(e) => handleSetFilterName(e.target.value)}
                        />
                    </Th>
                </Tr>
            </Thead>
            <Tbody>
                {items.map((item) => {
                    return (
                        <Items
                            key={item.id}
                            id={item.id}
                            nome={item.nome}
                        />
                    )
                })}
            </Tbody>
        </Table>
    )
}