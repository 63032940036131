import { useEffect, useState } from "react";
import { cd, get_date } from '../../../_assets/js/global.js';
import Row from "../../../components/body/row";
import Col from "../../../components/body/col";
import CalendarTitle from "../../../components/body/calendarTitle";
import CalendarFilter from "../../../components/body/calendarTitle/calendarFilter";
import SelectReact from "../../../components/body/select";
import Dashboard from "../../../components/body/dashboard";
import Tr from "../../../components/body/table/tr";
import Th from "../../../components/body/table/thead/th";
import Icon from "../../../components/body/icon";
import Td from "../../../components/body/table/tbody/td";
import Input from "../../../components/body/form/input/index.jsx";

export default function DashboardTreinamento() {

    //ESTADOS 
    const [monthSelected, setMonthSelected] = useState(window.currentMonth);
    const [yearSelected, setYearSelected] = useState(window.currentYear);
    const [filterPeriodo, setFilterPeriodo] = useState('1');
    const [dataInicio, setDataInicio] = useState(`0${monthSelected}/01/${yearSelected}`); //SÓ VAI FUNCIONAR PARA MESES COM ZERO
    const [dataFim, setDataFim] = useState(`0${monthSelected}/31/${yearSelected}`);
    const [dataAnteriorInicio, setDataAnteriorInicio] = useState(`0${(monthSelected - 1)}/01/${yearSelected}`);
    const [dataAnteriorFim, setDataAnteriorFim] = useState(`0${(monthSelected - 1)}/31/${yearSelected}`);
    const [dataAnteAnteriorInicio, setDataAnteAnteriorInicio] = useState(`0${(monthSelected - 2)}/01/${yearSelected}`);// mudar as colunas mês anterior e mês anteanterior
    const [dataAnteAnteriorFim, setDataAnteAnteriorFim] = useState(`0${(monthSelected - 2)}/31/${yearSelected}`);// mudar as colunas mês anterior e mês anteanterior 

    const [lojaActive, setLojaActive] = useState('');
    const [dashLoja1, setDashLoja1] = useState([]);
    const [dashLoja2, setDashLoja2] = useState([]);
    const [dashLoja3, setDashLoja3] = useState([]);

    const [funcionarioActive, setFuncionarioActive] = useState('');
    const [dashFuncionario1, setDashFuncionario1] = useState([]);
    const [dashFuncionario2, setDashFuncionario2] = useState([]);
    const [dashFuncionario3, setDashFuncionario3] = useState([]);

    const [treinamentoActive, setTreinamentoActive] = useState('');
    const [dashTreinamento1, setDashTreinamento1] = useState([]);
    const [dashTreinamento2, setDashTreinamento2] = useState([]);
    const [dashTreinamento3, setDashTreinamento3] = useState([]);

    // OPÇÕES SELECT REACT
    const optionsPeriodo = [
        { value: 1, label: "Por mês" },
        { value: 2, label: "Por período" }
    ];
    const optionsMonth = [
        { value: 1, label: "Janeiro" },
        { value: 2, label: "Fevereiro" },
        { value: 3, label: "Março" },
        { value: 4, label: "Abril" },
        { value: 5, label: "Maio" },
        { value: 6, label: "Junho" },
        { value: 7, label: "Julho" },
        { value: 8, label: "Agosto" },
        { value: 9, label: "Setembro" },
        { value: 10, label: "Outubro" },
        { value: 11, label: "Novembro" },
        { value: 12, label: "Dezembro" }
    ];
    const optionsYear = [
        { value: 2018, label: "2018" },
        { value: 2019, label: "2019" },
        { value: 2020, label: "2020" },
        { value: 2021, label: "2021" },
        { value: 2022, label: "2022" },
        { value: 2023, label: "2023" }
    ];

    // GET RESULTADOS DA PRIMEIRA COLUNA DE LOJAS
    const handleSetDashLoja1 = (e) => {
        if (e.length > 0) {
            setDashLoja1(e);
        } else {
            setDashLoja1(
                <Tr empty={true}></Tr>
            )
        }
    }

    // GET RESULTADOS DA SEGUNDA COLUNA DE LOJAS
    const handleSetDashLoja2 = (e) => {
        if (e.length > 0) {
            setDashLoja2(e);
        } else {
            setDashLoja2(
                <Tr empty={true}></Tr>
            )
        }
    }

    // GET RESULTADOS DA TERCEIRA COLUNA DE LOJAS
    const handleSetDashLoja3 = (e) => {
        if (e.length > 0) {
            setDashLoja3(e);
        } else {
            setDashLoja3(
                <Tr empty={true}></Tr>
            )
        }
    }

    // GET RESULTADOS DA PRIMEIRA COLUNA DE FUNCIONÁRIOS
    const handleSetDashFuncionario1 = (e) => {
        if (e.length > 0) {
            setDashFuncionario1(e);
        } else {
            setDashFuncionario1(
                <Tr empty={true}></Tr>
            )
        }
    }

    // GET RESULTADOS DA SEGUNDA COLUNA DE FUNCIONÁRIOS
    const handleSetDashFuncionario2 = (e) => {
        if (e.length > 0) {
            setDashFuncionario2(e);
        } else {
            setDashFuncionario2(
                <Tr empty={true}></Tr>
            )
        }
    }

    // GET RESULTADOS DA TERCEIRA COLUNA DE FUNCIONÁRIOS
    const handleSetDashFuncionario3 = (e) => {
        if (e.length > 0) {
            setDashFuncionario3(e);
        } else {
            setDashFuncionario3(
                <Tr empty={true}></Tr>
            )
        }
    }

    // GET RESULTADOS DA PRIMEIRA COLUNA DE TREINAMENTO
    const handleSetDashTreinamento1 = (e) => {
        if (e.length > 0) {
            setDashTreinamento1(e);
        } else {
            setDashTreinamento1(
                <Tr empty={true}></Tr>
            )
        }
    }

    // GET RESULTADOS DA SEGUNDA COLUNA DE TREINAMENTO
    const handleSetDashTreinamento2 = (e) => {
        if (e.length > 0) {
            setDashTreinamento2(e);
        } else {
            setDashTreinamento2(
                <Tr empty={true}></Tr>
            )
        }
    }

    // GET RESULTADOS DA TERCEIRA COLUNA DE TREINAMENTO
    const handleSetDashTreinamento3 = (e) => {
        if (e.length > 0) {
            setDashTreinamento3(e);
        } else {
            setDashTreinamento3(
                <Tr empty={true}></Tr>
            )
        }
    }

    return (
        <>
            <Row
                wrap={(window.isMobile ? true : false)}
                className="mb-4"
                fixed={true}
            >
                <Col lg={12}>
                    <CalendarTitle>
                        <CalendarFilter>
                            <SelectReact
                                options={optionsPeriodo}
                                placeholder="?"
                                value={filterPeriodo}
                                onChange={(e) => setFilterPeriodo(e.value)}
                            />
                            {(
                                filterPeriodo == 1 ?
                                    <>
                                        <SelectReact
                                            options={optionsMonth}
                                            placeholder="Mês"
                                            value={monthSelected}
                                            onChange={(e) => setMonthSelected(e.value)}
                                        />
                                        <SelectReact
                                            options={optionsYear}
                                            placeholder="Ano"
                                            value={yearSelected}
                                            onChange={(e) => setYearSelected(e.value)}
                                        />
                                    </>
                                    :
                                    <>
                                        <Input
                                            type="date"
                                            name="filter_date"
                                        // required={false}
                                        // value={dataInicio}
                                        // onChange={(e) => setDataInicio(e)}
                                        />
                                        <Input
                                        // type="period"
                                        // name="filter_period"
                                        // required={false}
                                        // valueStart={dataInicio}
                                        // valueEnd={dataFim}
                                        // onChangeStart={(e) => setDataInicio(e)}
                                        // onChangeEnd={(e) => setDataFim(e)}
                                        />
                                    </>
                            )}
                        </CalendarFilter>
                    </CalendarTitle>
                </Col>
            </Row>
            <Row wrap={(window.isMobile ? true : false)}>
                <Col>
                    <Dashboard
                        thead={
                            <Tr>
                                <Th>Nome</Th>
                                <Th>Part.</Th>
                                <Th><Icon type="reprovar" className="text-danger" /></Th>
                                <Th><Icon type="check" className="text-success" /></Th>
                                <Th>Total</Th>
                            </Tr>
                        }
                        cols={
                            {
                                col_1: {
                                    title: 'Loja - ' + get_date('month_name', get_date('date', '01/' + monthSelected + '/' + yearSelected, 'date_sub_month', 0)),
                                    api: {
                                        url: window.host + '/systems/treinamento/api/dashboard.php?do=get_loja',
                                        params: {
                                            token: window.token,
                                            page: 0,
                                            data_inicio: (dataInicio ? cd(dataInicio) : ''),
                                            data_fim: (dataFim ? cd(dataFim) : ''),
                                            filtro_loja: lojaActive,
                                            filtro_funcionario: funcionarioActive,
                                            filtro_treinamento: treinamentoActive,
                                            reload: (monthSelected + yearSelected + dataInicio + dataFim)
                                        }
                                    },
                                    tbody: (
                                        dashLoja1.length > 0 ?
                                            dashLoja1.map((item) => {
                                                return (
                                                    <Tr
                                                        key={item.loja_id}
                                                        active={(lojaActive == item.loja_id ? true : false)}
                                                        onClick={() => (
                                                            setLojaActive(lojaActive == item.loja_id ? '' : item.loja_id),
                                                            setFuncionarioActive(''),
                                                            setTreinamentoActive('')
                                                        )}
                                                        cursor="pointer"
                                                    >
                                                        <Td>{item.loja}</Td>
                                                        <Td>{item.part}</Td>
                                                        <Td>{item.errado}</Td>
                                                        <Td>{item.certo}</Td>
                                                        <Td>{item.total}</Td>
                                                    </Tr>
                                                )
                                            })
                                            : <Tr empty={true}></Tr>
                                    ),
                                    callback: handleSetDashLoja1
                                },

                                col_2: {
                                    title: 'Loja - ' + get_date('month_name', get_date('date', '01/' + monthSelected + '/' + yearSelected, 'date_sub_month', 1)),
                                    api: {
                                        url: window.host + '/systems/treinamento/api/dashboard.php?do=get_loja',
                                        params: {
                                            token: window.token,
                                            page: 0,
                                            data_inicio: (dataAnteriorInicio ? cd(dataAnteriorInicio) : ''),
                                            data_fim: (dataAnteriorFim ? cd(dataAnteriorFim) : ''),
                                            filtro_loja: lojaActive,
                                            filtro_funcionario: funcionarioActive,
                                            filtro_treinamento: treinamentoActive,
                                            reload: (monthSelected + yearSelected + dataInicio + dataFim)
                                        }
                                    },
                                    tbody: (
                                        dashLoja2.length > 0 ?
                                            dashLoja2.map((item) => {
                                                return (
                                                    <Tr key={item.loja_id} >
                                                        <Td>{item.loja}</Td>
                                                        <Td>{item.part}</Td>
                                                        <Td>{item.errado}</Td>
                                                        <Td>{item.certo}</Td>
                                                        <Td>{item.total}</Td>
                                                    </Tr>
                                                )
                                            })
                                            : <Tr empty={true}></Tr>
                                    ),
                                    callback: handleSetDashLoja2
                                },
                                col_3: {
                                    title: 'Loja - ' + get_date('month_name', get_date('date', '01/' + monthSelected + '/' + yearSelected, 'date_sub_month', 2)),
                                    api: {
                                        url: window.host + '/systems/treinamento/api/dashboard.php?do=get_loja',
                                        params: {
                                            token: window.token,
                                            page: 0,
                                            data_inicio: (dataAnteAnteriorInicio ? cd(dataAnteAnteriorInicio) : ''),
                                            data_fim: (dataAnteAnteriorFim ? cd(dataAnteAnteriorFim) : ''),
                                            filtro_loja: lojaActive,
                                            filtro_funcionario: funcionarioActive,
                                            filtro_treinamento: treinamentoActive,
                                            reload: (monthSelected + yearSelected + dataInicio + dataFim)
                                        }
                                    },
                                    tbody: (
                                        dashLoja3.length > 0 ?
                                            dashLoja3.map((item) => {
                                                return (
                                                    <Tr key={item.loja_id} >
                                                        <Td>{item.loja}</Td>
                                                        <Td>{item.part}</Td>
                                                        <Td>{item.errado}</Td>
                                                        <Td>{item.certo}</Td>
                                                        <Td>{item.total}</Td>
                                                    </Tr>
                                                )
                                            })
                                            : <Tr empty={true}></Tr>
                                    ),
                                    callback: handleSetDashLoja3
                                }
                            }
                        }
                    >
                    </Dashboard>
                </Col>
                <Col>
                    <Dashboard
                        thead={
                            <Tr>
                                <Th>Nome</Th>
                                <Th>Part.</Th>
                                <Th><Icon type="reprovar" className="text-danger" /></Th>
                                <Th><Icon type="check" className="text-success" /></Th>
                                <Th>Total</Th>
                            </Tr>
                        }
                        cols={
                            {
                                col_1: {
                                    title: 'Funcionário - ' + get_date('month_name', get_date('date', '01/' + monthSelected + '/' + yearSelected, 'date_sub_month', 0)),
                                    api: {
                                        url: window.host + '/systems/treinamento/api/dashboard.php?do=get_funcionario',
                                        params: {
                                            token: window.token,
                                            page: 0,
                                            data_inicio: (dataInicio ? cd(dataInicio) : ''),
                                            data_fim: (dataFim ? cd(dataFim) : ''),
                                            filtro_loja: lojaActive,
                                            filtro_funcionario: funcionarioActive,
                                            filtro_treinamento: treinamentoActive,
                                            reload: (monthSelected + yearSelected + dataInicio + dataFim + lojaActive)
                                        }
                                    },
                                    tbody: (
                                        dashFuncionario1.length > 0 ?
                                            dashFuncionario1.map((item) => {
                                                return (
                                                    <Tr
                                                        key={item.funcionario_id}
                                                        active={(funcionarioActive == item.funcionario_id ? true : false)}
                                                        onClick={() => (
                                                            setFuncionarioActive(funcionarioActive == item.funcionario_id ? '' : item.funcionario_id),
                                                            setTreinamentoActive('')
                                                        )}
                                                        cursor="pointer"
                                                    >
                                                        <Td>{item.funcionario}</Td>
                                                        <Td>{item.part}</Td>
                                                        <Td>{item.errado}</Td>
                                                        <Td>{item.certo}</Td>
                                                        <Td>{item.total}</Td>
                                                    </Tr>
                                                )
                                            })
                                            : <Tr empty={true}></Tr>
                                    ),
                                    callback: handleSetDashFuncionario1
                                },

                                col_2: {
                                    title: 'Funcionario - ' + get_date('month_name', get_date('date', '01/' + monthSelected + '/' + yearSelected, 'date_sub_month', 1)),
                                    api: {
                                        url: window.host + '/systems/treinamento/api/dashboard.php?do=get_funcionario',
                                        params: {
                                            token: window.token,
                                            page: 0,
                                            data_inicio: (dataAnteriorInicio ? cd(dataAnteriorInicio) : ''),
                                            data_fim: (dataAnteriorFim ? cd(dataAnteriorFim) : ''),
                                            filtro_loja: lojaActive,
                                            filtro_funcionario: funcionarioActive,
                                            filtro_treinamento: treinamentoActive,
                                            reload: (monthSelected + yearSelected + dataInicio + dataFim + lojaActive)
                                        }
                                    },
                                    tbody: (
                                        dashFuncionario2.length > 0 ?
                                            dashFuncionario2.map((item) => {
                                                return (
                                                    <Tr key={item.funcionario_id} >
                                                        <Td>{item.funcionario}</Td>
                                                        <Td>{item.part}</Td>
                                                        <Td>{item.errado}</Td>
                                                        <Td>{item.certo}</Td>
                                                        <Td>{item.total}</Td>
                                                    </Tr>
                                                )
                                            })
                                            : <Tr empty={true}></Tr>
                                    ),
                                    callback: handleSetDashFuncionario2
                                },
                                col_3: {
                                    title: 'Funcionario - ' + get_date('month_name', get_date('date', '01/' + monthSelected + '/' + yearSelected, 'date_sub_month', 2)),
                                    api: {
                                        url: window.host + '/systems/treinamento/api/dashboard.php?do=get_funcionario',
                                        params: {
                                            token: window.token,
                                            page: 0,
                                            data_inicio: (dataAnteAnteriorInicio ? cd(dataAnteAnteriorInicio) : ''),
                                            data_fim: (dataAnteAnteriorFim ? cd(dataAnteAnteriorFim) : ''),
                                            filtro_loja: lojaActive,
                                            filtro_funcionario: funcionarioActive,
                                            filtro_treinamento: treinamentoActive,
                                            reload: (monthSelected + yearSelected + dataInicio + dataFim + lojaActive)
                                        }
                                    },
                                    tbody: (
                                        dashFuncionario3.length > 0 ?
                                            dashFuncionario3.map((item) => {
                                                return (
                                                    <Tr key={item.funcionario_id} >
                                                        <Td>{item.funcionario}</Td>
                                                        <Td>{item.part}</Td>
                                                        <Td>{item.errado}</Td>
                                                        <Td>{item.certo}</Td>
                                                        <Td>{item.total}</Td>
                                                    </Tr>
                                                )
                                            })
                                            : <Tr empty={true}></Tr>
                                    ),
                                    callback: handleSetDashFuncionario3
                                }
                            }
                        }
                    >
                    </Dashboard>
                </Col>
                <Col>
                    <Dashboard
                        thead={
                            <Tr>
                                <Th>Nome</Th>
                                <Th>Part.</Th>
                                <Th><Icon type="reprovar" className="text-danger" /></Th>
                                <Th><Icon type="check" className="text-success" /></Th>
                                <Th>Total</Th>
                            </Tr>
                        }
                        cols={
                            {
                                col_1: {
                                    title: 'Treinamento - ' + get_date('month_name', get_date('date', '01/' + monthSelected + '/' + yearSelected, 'date_sub_month', 0)),
                                    api: {
                                        url: window.host + '/systems/treinamento/api/dashboard.php?do=get_treinamento',
                                        params: {
                                            token: window.token,
                                            page: 0,
                                            data_inicio: (dataInicio ? cd(dataInicio) : ''),
                                            data_fim: (dataFim ? cd(dataFim) : ''),
                                            filtro_loja: lojaActive,
                                            filtro_funcionario: funcionarioActive,
                                            filtro_treinamento: treinamentoActive,
                                            reload: (monthSelected + yearSelected + dataInicio + dataFim + lojaActive + funcionarioActive)
                                        }
                                    },
                                    tbody: (
                                        dashTreinamento1.length > 0 ?
                                            dashTreinamento1.map((item) => {
                                                return (
                                                    <Tr
                                                        key={item.treinamento_id}
                                                        active={(treinamentoActive == item.treinamento_id ? true : false)}
                                                        onClick={() => (
                                                            setTreinamentoActive(treinamentoActive == item.treinamento_id ? '' : item.treinamento_id)
                                                        )}
                                                        cursor="pointer"
                                                    >
                                                        <Td>{item.treinamento}</Td>
                                                        <Td>{item.part}</Td>
                                                        <Td>{item.errado}</Td>
                                                        <Td>{item.certo}</Td>
                                                        <Td>{item.total}</Td>
                                                    </Tr>
                                                )
                                            })
                                            : <Tr empty={true}></Tr>
                                    ),
                                    callback: handleSetDashTreinamento1
                                },

                                col_2: {
                                    title: 'Treinamento - ' + get_date('month_name', get_date('date', '01/' + monthSelected + '/' + yearSelected, 'date_sub_month', 1)),
                                    api: {
                                        url: window.host + '/systems/treinamento/api/dashboard.php?do=get_treinamento',
                                        params: {
                                            token: window.token,
                                            page: 0,
                                            data_inicio: (dataAnteriorInicio ? cd(dataAnteriorInicio) : ''),
                                            data_fim: (dataAnteriorFim ? cd(dataAnteriorFim) : ''),
                                            filtro_loja: lojaActive,
                                            filtro_funcionario: funcionarioActive,
                                            filtro_treinamento: treinamentoActive,
                                            reload: (monthSelected + yearSelected + dataInicio + dataFim + lojaActive + funcionarioActive)
                                        }
                                    },
                                    tbody: (
                                        dashTreinamento2.length > 0 ?
                                            dashTreinamento2.map((item) => {
                                                return (
                                                    <Tr key={item.treinamento_id} >
                                                        <Td>{item.treinamento}</Td>
                                                        <Td>{item.part}</Td>
                                                        <Td>{item.errado}</Td>
                                                        <Td>{item.certo}</Td>
                                                        <Td>{item.total}</Td>
                                                    </Tr>
                                                )
                                            })
                                            : <Tr empty={true}></Tr>
                                    ),
                                    callback: handleSetDashTreinamento2
                                },
                                col_3: {
                                    title: 'Treinamento - ' + get_date('month_name', get_date('date', '01/' + monthSelected + '/' + yearSelected, 'date_sub_month', 2)),
                                    api: {
                                        url: window.host + '/systems/treinamento/api/dashboard.php?do=get_treinamento',
                                        params: {
                                            token: window.token,
                                            page: 0,
                                            data_inicio: (dataAnteAnteriorInicio ? cd(dataAnteAnteriorInicio) : ''),
                                            data_fim: (dataAnteAnteriorFim ? cd(dataAnteAnteriorFim) : ''),
                                            filtro_loja: lojaActive,
                                            filtro_funcionario: funcionarioActive,
                                            filtro_treinamento: treinamentoActive,
                                            reload: (monthSelected + yearSelected + dataInicio + dataFim + lojaActive + funcionarioActive)
                                        }
                                    },
                                    tbody: (
                                        dashTreinamento3.length > 0 ?
                                            dashTreinamento3.map((item) => {
                                                return (
                                                    <Tr key={item.treinamento_id} >
                                                        <Td>{item.treinamento}</Td>
                                                        <Td>{item.part}</Td>
                                                        <Td>{item.errado}</Td>
                                                        <Td>{item.certo}</Td>
                                                        <Td>{item.total}</Td>
                                                    </Tr>
                                                )
                                            })
                                            : <Tr empty={true}></Tr>
                                    ),
                                    callback: handleSetDashTreinamento3
                                }
                            }
                        }
                    >
                    </Dashboard>
                </Col>
            </Row>
        </>
    )
}
