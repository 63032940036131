import { useState, useContext  } from 'react';

import './devolver.scss';

import Modal from '../../modal';
import ModalHeader from '../../modal/modalHeader';
import ModalTitle from '../../modal/modalHeader/modalTitle';
import ModalBody from '../../modal/modalBody';
import Icon from '../../icon';
import Form from '../../form';
import Textarea from '../../form/textarea';
import Button from '../../button';

import { GlobalContext } from "../../../../context/Global";

export default function Devolver(props){
    // CONTEXT
    const { filterModule } = useContext(GlobalContext);

    const [showModal, setShowModal] = useState(false);
    const [observacao, setObservacao] = useState('');

    const data = {
        ativ_desc:'Devolveu o chamado',
        id_job: props.id_job,
        id_mov: props.id_job_status,
        status: 3,
        msg: observacao,
        data_aux: undefined,
        tipo: 'prev',
        tipo_fase: 'Check',
        nivel_msg: (props.chamados ? 2 : 0)
    }

    // CALLBACK AO REALIZAR ENVIO
    const handleDevolver = () => {
        props?.callback(props?.id_job_status);
    }

    return(
        <>
            <Modal show={ showModal } onHide={() => setShowModal(false)}>
                <ModalHeader>
                    <ModalTitle>
                        Motivo
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Form
                        api={window.host+'/systems/integration-react/api/list.php?do=set_statusSupervisor&filter_id_module='+filterModule+'&token='+window.token}
                        data={data}
                        toast="Chamado devolvido ao operador"
                        callback={handleDevolver}
                    >                            
                        <Textarea name="motivo" placeholder="Observação" value={observacao} onChange={(e) => (setObservacao(e.target.value))} />

                        <Button
                            type="submit"
                            onClick={() => (setShowModal(false))}
                        >
                            Salvar
                        </Button>
                    </Form>
                </ModalBody>
            </Modal>

            <Icon type="reabrir" title="Devolver ao operador" onClick={() => setShowModal(true)} />
        </>
    )
}
