import { useContext, useEffect, useState } from "react";

import { JobsContext } from "../../../context/Jobs";
import { GlobalContext } from "../../../context/Global";
import axios from "axios";
import Row from '../../../components/body/row';
import Col from '../../../components/body/col';
import Card from './components/Card';
import Icon from '../../../components/body/icon';
import Chart from '../../../components/body/chart';
import FilterCheckbox from '../../../components/body/filterCheckbox';
import { cd, get_date } from "../../../_assets/js/global";
import SelectReact from "../../../components/body/select";

export default function Grafico({filters, icons}){
    // CONTEXT GLOBAL
    const { handleSetFilter, handleSetFixFilterModule, loadingCalendar, handleSetFirstLoad } = useContext(GlobalContext);

    // CONTEXT JOBS
    const { optionsEmpreendimentos, handleSetFilterEmpreendimento, filterEmpreendimento, optionsUsuario } = useContext(JobsContext);

    // HABILITA O LOADING DOS CARDS PARA QUANDO VOLTAR PRA TELA DO CALENDÁRIO
    useEffect(() => {
        handleSetFilter(true);
        loadingCalendar(true);
        handleSetFixFilterModule(false);
        handleSetFirstLoad(true);
    }, []);

    var date = new Date();

    // ESTADOS
    const [loja, setLoja] = useState([]);
    const [usuario, setUsuario] = useState([]);
    const [supervisor, setSupervisor] = useState([]);
    const [dataInicial, setDataInicial] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [dataFinal, setDataFinal] = useState(new Date());
    const [filterMonth, setFilterMonth] = useState(window.currentMonth);
    const [filterYear, setFilterYear] = useState(window.currentYear);
    const [usuariosAtivos, setUsuariosAtivos] = useState(0);
    const [usuariosOnline, setUsuariosOnline] = useState(0);

    // ESTADOS CARDS CABEÇALHO
    const [cardJobs, setCardJobs] = useState([]);
    const [cardChamados, setCardChamados] = useState([]);
    const [cardComunicados, setCardComunicados] = useState([]);
    const [cardChecklist, setCardChecklist] = useState([]);

    // ESTADOS DOS ÚLTIMOS 6 MESES
    const [monthJobs, setMonthJobs] = useState([]);
    const [monthChamados, setMonthChamados] = useState([]);
    const [monthComunicados, setMonthComunicados] = useState([]);
    const [monthChecklist, setMonthChecklist] = useState([]);
    const [monthUsuarios, setMonthUsuarios] = useState([]);
    const [loadingMonth, setLoadingMonth] = useState(true);

    // OPTIONS MESES
    const optionsMonths = [
        { value: 1, label: 'Janeiro' },
        { value: 2, label: 'Fevereiro' },
        { value: 3, label: 'Março' },
        { value: 4, label: 'Abril' },
        { value: 5, label: 'Maio' },
        { value: 6, label: 'Junho' },
        { value: 7, label: 'Julho' },
        { value: 8, label: 'Agosto' },
        { value: 9, label: 'Setembro' },
        { value: 10, label: 'Outubro' },
        { value: 11, label: 'Novembro' },
        { value: 12, label: 'Dezembro' }
    ]

    // OPTIONS ANO
    var optionsYear = [];
    for (var i = 0; i < 5; i++) {
        optionsYear.push(
        { value: window.currentYear - i, label: window.currentYear - i }
        )
    }

     // FILTRO DE MÊS
    const handleFilterMonth = (e) => {
        setFilterMonth(e);
        setDataInicial(new Date(filterYear, (e - 1), 1));
        const endDate = new Date(filterYear, (e - 1), 1);

        const endOfMonth = new Date(
          endDate.getUTCFullYear(),
          endDate.getUTCMonth() + 1,
          0
        );
        setDataFinal(endOfMonth);
    }

    // FILTRO DE ANO
    const handleFilterYear = (e) => {
        setFilterYear(e);
        setDataInicial(new Date(e, (filterMonth - 1), 1));
        setDataFinal(new Date(e, (filterMonth - 1), get_date('last_day', (e + '-' + (filterMonth - 1) + '-01'), 'date_sql')));
    }

    // FILTRA SUPERVISOR
    const handleSetSupervisor = (e) => {
        setSupervisor(e);
    }

    // FILTRA LOJA
    const handleSetLoja = (e) => {
        setLoja(e);
    }

    // GET CARDS
    function get_card(system){
        let do_aux;

        if(system === 'jobs'){
            do_aux = 'getJob';
        }else if(system === 'chamados'){
            do_aux = 'getCalled';
        }else if(system === 'comunicados'){
            do_aux = 'getCommunication';
        }else if(system === 'checklist'){
            do_aux = 'getChecklist';
        }

        axios({
            url: window.host + '/systems/integration-react/api/request.php?type=Dashboard',
            params: {
                do: do_aux,
                token: window.token,
                filter_subtype: 'store',
                filter_date_start: (dataInicial ? get_date('date_sql',cd(dataInicial)) : ''),
                filter_date_end: (dataFinal ? get_date('date_sql',cd(dataFinal)) : ''),
                filter_id_emp: filterEmpreendimento,
                filter_supervisor: supervisor,
                filter_id_store: loja,
                filter_id_user: usuario,
            }
        }).then((response) => {
            if(response?.data?.data[0]){
                if (system === 'jobs') {
                    setCardJobs(response.data.data[0]);
                }else if(system === 'chamados'){
                    setCardChamados(response.data.data[0]);
                }else if(system === 'comunicados'){
                    setCardComunicados(response.data.data[0]);
                }else if(system === 'checklist'){
                    setCardChecklist(response.data.data[0]);
                }                
            }
        });
    }

    // GET ADM
    function get_adm(){    
        axios({
            url: window.host + '/systems/integration-react/api/request.php?type=Dashboard',
            params: {
                do: "getUserAdmOnline",
                token: window.token,
                filter_id_emp: filterEmpreendimento
            }
        }).then((response) => {
            if(response?.data?.data[0]){
                setUsuariosAtivos(response?.data?.data[0].qtd_total);
                setUsuariosOnline(response?.data?.data[0].qtd_onlines)
            }
        });
    }

    // GET ÚLTIMOS 6 MESES
    function get_months(system){
        setLoadingMonth(true);

        let do_aux;

        if(system === 'jobs'){
            do_aux = 'getJob';
        }else if(system === 'chamados'){
            do_aux = 'getCalled';
        }else if(system === 'comunicados'){
            do_aux = 'getCommunication';
        }else if(system === 'checklist'){
            do_aux = 'getChecklist';
        }else if(system === 'usuarios'){
            do_aux = 'getUserAdmOnline';
        }

        const endDate = new Date(dataInicial);

        const endOfMonth = new Date(
          endDate.getUTCFullYear(),
          endDate.getUTCMonth() + 1,
          0
        );
        axios({
            url: window.host + '/systems/integration-react/api/request.php?type=Dashboard',
            params: {
                do: do_aux,
                token: window.token,
                filter_type: 'year_month',
                filter_date_start: (dataInicial ? get_date('date_sql', cd(dataInicial), 'date_sub_month', 5) : ''),
                filter_date_end: (dataFinal ? get_date('date_sql',cd(dataFinal)) : ''),
                filter_id_emp: filterEmpreendimento,
                filter_supervisor: supervisor,
                filter_id_store: loja,
                filter_id_user: usuario,
            }
        }).then((response) => {
            if(response?.data?.data[0]){
                if(system === 'jobs'){
                    setMonthJobs(response.data.data);
                }else if(system === 'chamados'){
                    setMonthChamados(response.data.data);
                }else if(system === 'comunicados'){
                    setMonthComunicados(response.data.data);
                }else if(system === 'checklist'){
                    setMonthChecklist(response.data.data);
                }else if(system === 'usuarios'){
                    setMonthUsuarios(response?.data?.data);
                }
            }
            setLoadingMonth(false);
        });
    }

    // CHAMADAS INICIAIS
    useEffect(() => {
        get_card('jobs');
        get_card('chamados');
        get_card('comunicados');
        get_card('checklist');
        get_adm();
        get_months('jobs');
        get_months('chamados');
        get_months('comunicados');
        get_months('checklist');
        get_months('usuarios');
    },[dataInicial, dataFinal, supervisor, loja, filterEmpreendimento]);

    // MANDA OS FILTROS PRO COMPONENTE PAI
    useEffect(() => {
        if (icons) {
            icons('');
        }

        if (filters) {
            filters(
              <>
                {optionsEmpreendimentos.length > 1 ? (
                  <FilterCheckbox
                    options={optionsEmpreendimentos}
                    placeholder="Empreendimento"
                    name="filter_id_emp"
                    value={filterEmpreendimento}
                    onChangeClose={(e) => handleSetFilterEmpreendimento(e)}
                  >
                    Empreendimentos
                  </FilterCheckbox>
                ) : (
                  ""
                )}

                <FilterCheckbox
                  id="filter_store"
                  name="filter_store"
                  api={{
                    url:
                      window.host +
                      "/systems/integration-react/api/list.php?do=headerFilter&token=" +
                      window.token,
                    params: {
                      filters: [{ filter: "store" }],
                      empreendimento_id: filterEmpreendimento,
                      limit: 50,
                      np: true,
                    },
                    key: "store",
                    reload: filterEmpreendimento,
                  }}
                  onChangeClose={(e) => handleSetLoja(e)}
                  value={loja}
                >
                  Lojas
                </FilterCheckbox>

                <FilterCheckbox
                  name="filter_user"
                  options={optionsUsuario}
                  // onChangeClose={(handleFilterUser)}
                  value={usuario}
                >
                  Usuários
                </FilterCheckbox>

                <FilterCheckbox
                  name="filter_supervisor"
                  api={
                    window.host +
                    "/api/sql.php?do=select&component=supervisor_2&grupo_id=true&empreendimento_id=" +
                    filterEmpreendimento +
                    "&token=" +
                    window.token
                  }
                  onChangeClose={(e) => handleSetSupervisor(e)}
                  // value={filterSupervisor}
                >
                  Regional
                </FilterCheckbox>

                <SelectReact
                  options={optionsMonths}
                  placeholder="Mês"
                  name="filter_month"
                  defaultValue={""}
                  value={filterMonth}
                  onChange={(e) => handleFilterMonth(e.value)}
                />

                <SelectReact
                  placeholder="Ano"
                  options={optionsYear}
                  value={filterYear}
                  onChange={(e) => handleFilterYear(e.value)}
                />
              </>
            );
        }
    }, [filterEmpreendimento, optionsEmpreendimentos, filterMonth, filterYear, supervisor, loja]);

    return(
        <>
            <Row>
                <Col lg={3}>
                    <Card
                        title={'Jobs'}
                        background={'#f24f7c'}
                        icon={<Icon type={'check'} />}
                        info={[
                            {label: 'Atrasados', number: (cardJobs.qtd_atrasado ? cardJobs.qtd_atrasado : 0)},
                            {label: 'No prazo', number: (cardJobs.qtd_feito ? cardJobs.qtd_feito : 0)},
                            {label: 'Feitos c/ Atraso', number: (cardJobs.qtd_feito_com_atraso ? cardJobs.qtd_feito_com_atraso : 0)}
                        ]}
                    />
                </Col>

                <Col lg={3}>
                    <Card
                        title={'Chamados'}
                        background={'#716cb0'}
                        icon={<Icon type={'clipboard'} />}
                        info={[
                            {label: 'Abertos', number: (cardChamados.qtd_pendente ? cardChamados.qtd_pendente : 0)},
                            {label: 'Finalizados', number: (cardChamados.qtd_finalizado ? cardChamados.qtd_finalizado : 0)},
                            {label: 'Tempo médio', number: (cardChamados.tempo_medio_min ? Math.round(cardChamados.tempo_medio_min)+'min.' : 0)}
                        ]}
                    />
                </Col>

                <Col lg={3}>
                    <Card
                        title={'Comunicados'}
                        background={'#33b0e0'}
                        icon={<Icon type={'document'} />}
                        info={[
                            {label: 'Enviados', number: (cardComunicados.qtd_total ? cardComunicados.qtd_total : 0)},
                            {label: 'Lidos', number: (cardComunicados.qtd_lido ? cardComunicados.qtd_lido : 0)},
                            {label: '% Leitura', number: (cardComunicados.qtd_lido && cardComunicados.qtd_total ? Math.round(((cardComunicados.qtd_lido / cardComunicados.qtd_total) * 100)) : 0)}
                        ]}
                    />
                </Col>

                <Col lg={3}>
                    <Card
                        title={'Checklist'}
                        background={'#3bc0c3'}
                        icon={<Icon type={'checklist'} />}
                        info={[
                            {label: 'Conforme', number: (cardChecklist.qtd_aprovado ? cardChecklist.qtd_aprovado : 0)},
                            {label: 'Não conforme', number: (cardChecklist.qtd_reprovado ? cardChecklist.qtd_reprovado : 0)},
                            {label: 'Não se aplica', number: (cardChecklist.qtd_nao_aplica ? cardChecklist.qtd_nao_aplica : 0)}
                        ]}
                    />
                </Col>
            </Row>

            <Row>
                {/* ÚLTIMOS 6 MESES */}
                <Col lg={8}>                    
                    <Chart
                        id="teste1"
                        title="Últimos 6 meses"
                        type="ComboChart"
                        headers={['Mês', 'Jobs no prazo', 'Chamados abertos', 'Comunicados enviados', 'Checklists conformes', 'Usuários ativos']}
                        body={{
                            type: "custom",
                            content: [
                                [get_date('month_name', get_date('date_sql', cd(dataInicial), 'date_sub_month', 5), 'date_sql'), (monthJobs[0]?.qtd_feito ? monthJobs[0]?.qtd_feito : 0), (monthChamados[0]?.qtd_pendente ? monthChamados[0]?.qtd_pendente : 0), (monthComunicados[0]?.qtd_total ? monthComunicados[0]?.qtd_total : 0), (monthChecklist[0]?.qtd_aprovado ? monthChecklist[0]?.qtd_aprovado : 0), (monthUsuarios[0]?.qtd_total ? monthUsuarios[0]?.qtd_total : 0)],
                                [get_date('month_name', get_date('date_sql', cd(dataInicial), 'date_sub_month', 4), 'date_sql'), (monthJobs[1]?.qtd_feito ? monthJobs[1]?.qtd_feito : 0), (monthChamados[1]?.qtd_pendente ? monthChamados[1]?.qtd_pendente : 0), (monthComunicados[1]?.qtd_total ? monthComunicados[1]?.qtd_total : 0), (monthChecklist[1]?.qtd_aprovado ? monthChecklist[1]?.qtd_aprovado : 0), (monthUsuarios[1]?.qtd_total ? monthUsuarios[1]?.qtd_total : 0)],
                                [get_date('month_name', get_date('date_sql', cd(dataInicial), 'date_sub_month', 3), 'date_sql'), (monthJobs[2]?.qtd_feito ? monthJobs[2]?.qtd_feito : 0), (monthChamados[2]?.qtd_pendente ? monthChamados[2]?.qtd_pendente : 0), (monthComunicados[2]?.qtd_total ? monthComunicados[2]?.qtd_total : 0), (monthChecklist[2]?.qtd_aprovado ? monthChecklist[2]?.qtd_aprovado : 0), (monthUsuarios[2]?.qtd_total ? monthUsuarios[2]?.qtd_total : 0)],
                                [get_date('month_name', get_date('date_sql', cd(dataInicial), 'date_sub_month', 2), 'date_sql'), (monthJobs[3]?.qtd_feito ? monthJobs[3]?.qtd_feito : 0), (monthChamados[3]?.qtd_pendente ? monthChamados[3]?.qtd_pendente : 0), (monthComunicados[3]?.qtd_total ? monthComunicados[3]?.qtd_total : 0), (monthChecklist[3]?.qtd_aprovado ? monthChecklist[3]?.qtd_aprovado : 0), (monthUsuarios[3]?.qtd_total ? monthUsuarios[3]?.qtd_total : 0)],
                                [get_date('month_name', get_date('date_sql', cd(dataInicial), 'date_sub_month', 1), 'date_sql'), (monthJobs[4]?.qtd_feito ? monthJobs[4]?.qtd_feito : 0), (monthChamados[4]?.qtd_pendente ? monthChamados[4]?.qtd_pendente : 0), (monthComunicados[4]?.qtd_total ? monthComunicados[4]?.qtd_total : 0), (monthChecklist[4]?.qtd_aprovado ? monthChecklist[4]?.qtd_aprovado : 0), (monthUsuarios[4]?.qtd_total ? monthUsuarios[4]?.qtd_total : 0)],
                                [get_date('month_name', get_date('date_sql', cd(dataInicial), 'date_sub_month', 0), 'date_sql'), (monthJobs[5]?.qtd_feito ? monthJobs[5]?.qtd_feito : 0), (monthChamados[5]?.qtd_pendente ? monthChamados[5]?.qtd_pendente : 0), (monthComunicados[5]?.qtd_total ? monthComunicados[5]?.qtd_total : 0), (monthChecklist[5]?.qtd_aprovado ? monthChecklist[5]?.qtd_aprovado : 0), (monthUsuarios[5]?.qtd_total ? monthUsuarios[5]?.qtd_total : 0)]
                            ]
                        }}
                        height={(window.isMobile ? 400 : 553)}
                        loading={loadingMonth}
                    />
                </Col>

                <Col lg={4}>
                    <div className="d-flex flex-column h-100">
                        <Chart
                            id="teste2"
                            title="Últimos 6 meses"
                            type="LineChart"
                            headers={['Mês', 'Jobs no prazo', 'Chamados abertos', 'Comunicados enviados', 'Checklists conformes', 'Usuários ativos']}
                            body={{
                                type: "custom",
                                content: [
                                    [get_date('month_name', get_date('date_sql', cd(dataInicial), 'date_sub_month', 5), 'date_sql'), (monthJobs[0]?.qtd_feito ? monthJobs[0]?.qtd_feito : 0), (monthChamados[0]?.qtd_pendente ? monthChamados[0]?.qtd_pendente : 0), (monthComunicados[0]?.qtd_total ? monthComunicados[0]?.qtd_total : 0), (monthChecklist[0]?.qtd_aprovado ? monthChecklist[0]?.qtd_aprovado : 0), (monthUsuarios[0]?.qtd_total ? monthUsuarios[0]?.qtd_total : 0)],
                                    [get_date('month_name', get_date('date_sql', cd(dataInicial), 'date_sub_month', 4), 'date_sql'), (monthJobs[1]?.qtd_feito ? monthJobs[1]?.qtd_feito : 0), (monthChamados[1]?.qtd_pendente ? monthChamados[1]?.qtd_pendente : 0), (monthComunicados[1]?.qtd_total ? monthComunicados[1]?.qtd_total : 0), (monthChecklist[1]?.qtd_aprovado ? monthChecklist[1]?.qtd_aprovado : 0), (monthUsuarios[1]?.qtd_total ? monthUsuarios[1]?.qtd_total : 0)],
                                    [get_date('month_name', get_date('date_sql', cd(dataInicial), 'date_sub_month', 3), 'date_sql'), (monthJobs[2]?.qtd_feito ? monthJobs[2]?.qtd_feito : 0), (monthChamados[2]?.qtd_pendente ? monthChamados[2]?.qtd_pendente : 0), (monthComunicados[2]?.qtd_total ? monthComunicados[2]?.qtd_total : 0), (monthChecklist[2]?.qtd_aprovado ? monthChecklist[2]?.qtd_aprovado : 0), (monthUsuarios[2]?.qtd_total ? monthUsuarios[2]?.qtd_total : 0)],
                                    [get_date('month_name', get_date('date_sql', cd(dataInicial), 'date_sub_month', 2), 'date_sql'), (monthJobs[3]?.qtd_feito ? monthJobs[3]?.qtd_feito : 0), (monthChamados[3]?.qtd_pendente ? monthChamados[3]?.qtd_pendente : 0), (monthComunicados[3]?.qtd_total ? monthComunicados[3]?.qtd_total : 0), (monthChecklist[3]?.qtd_aprovado ? monthChecklist[3]?.qtd_aprovado : 0), (monthUsuarios[3]?.qtd_total ? monthUsuarios[3]?.qtd_total : 0)],
                                    [get_date('month_name', get_date('date_sql', cd(dataInicial), 'date_sub_month', 1), 'date_sql'), (monthJobs[4]?.qtd_feito ? monthJobs[4]?.qtd_feito : 0), (monthChamados[4]?.qtd_pendente ? monthChamados[4]?.qtd_pendente : 0), (monthComunicados[4]?.qtd_total ? monthComunicados[4]?.qtd_total : 0), (monthChecklist[4]?.qtd_aprovado ? monthChecklist[4]?.qtd_aprovado : 0), (monthUsuarios[4]?.qtd_total ? monthUsuarios[4]?.qtd_total : 0)],
                                    [get_date('month_name', get_date('date_sql', cd(dataInicial), 'date_sub_month', 0), 'date_sql'), (monthJobs[5]?.qtd_feito ? monthJobs[5]?.qtd_feito : 0), (monthChamados[5]?.qtd_pendente ? monthChamados[5]?.qtd_pendente : 0), (monthComunicados[5]?.qtd_total ? monthComunicados[5]?.qtd_total : 0), (monthChecklist[5]?.qtd_aprovado ? monthChecklist[5]?.qtd_aprovado : 0), (monthUsuarios[5]?.qtd_total ? monthUsuarios[5]?.qtd_total : 0)]
                                ]
                            }}
                            height={(document.getElementById('chart_teste1') ? (document.getElementById('chart_teste1').offsetHeight - 140) : '')}
                            className="flex-1"
                        />

                        <Card
                            info={[
                                {number: usuariosAtivos, label: 'Usuários ADM ativos'},
                                {number: usuariosOnline, label: 'Usuários ADM online'}
                            ]}
                            background="#fff"
                            dark={false}
                            icon={<Icon type="chart" />}
                        />
                    </div>
                </Col>
            </Row>

            <Row>
                <Col lg={3}>
                    <Chart
                        title="Chamados abertos"                
                        headers={['Lojas', 'Qtd. Atrasados']}
                        body={{
                            content: ['loja', 'qtd_atrasado']
                        }}
                        api={{
                            url: window.host + '/systems/integration-react/api/request.php',
                            params: {
                                do: 'getCalled',
                                type: 'Dashboard',
                                token: window.token,
                                filter_type: 'store',
                                only_data: true,
                                filter_date_start: (dataInicial ? dataInicial.toISOString().split('T')[0] : ''),
                                filter_date_end: (dataFinal ? dataFinal.toISOString().split('T')[0] : ''),
                                filter_supervisor: supervisor,
                                filter_id_emp: filterEmpreendimento,
                                filter_id_store: loja,
                                id_apl: 224                          
                            }
                        }}
                    />
                </Col>

                <Col lg={3}>
                    <Chart
                        title="Jobs atrasados"                
                        headers={['Lojas', 'Qtd. Atrasados']}
                        body={{
                            content: ['loja', 'qtd_atrasado']
                        }}
                        api={{
                            url: window.host + '/systems/integration-react/api/request.php',
                            params: {
                                do: 'getBelated',
                                type: 'Dashboard',
                                token: window.token,
                                filter_type: 'store',
                                only_data: true,
                                filter_date_start: (dataInicial ? dataInicial.toISOString().split('T')[0] : ''),
                                filter_date_end: (dataFinal ? dataFinal.toISOString().split('T')[0] : ''),
                                filter_supervisor: supervisor,
                                filter_id_emp: filterEmpreendimento,
                                filter_id_store: loja,
                                id_apl: 223
                            }
                        }}
                    />
                </Col>

                <Col lg={3}>
                    <Chart
                        title="Checklists Conformes"                
                        headers={['Lojas', 'Qtd. Aprovados']}
                        body={{
                            content: ['loja', 'qtd_aprovado']
                        }}
                        api={{
                            url: window.host + '/systems/integration-react/api/request.php',
                            params: {
                                token: window.token,
                                do: 'getChecklist',
                                type: 'Dashboard',
                                filter_type: 'store',
                                filter_date_start: (dataInicial ? dataInicial.toISOString().split('T')[0] : ''),
                                filter_date_end: (dataFinal ? dataFinal.toISOString().split('T')[0] : ''),
                                filter_supervisor: supervisor,
                                filter_id_emp: filterEmpreendimento,
                                filter_id_store: loja,
                                filter_id_user: usuario,
                                only_data: true,
                            }
                        }}
                    />
                </Col>

                <Col lg={3}>
                    <Chart
                        title="Usuários ativos"                
                        headers={['Lojas', 'Qtd. Atrasados']}
                        body={{
                            content: ['loja', 'qtd_total']
                        }}
                        api={{
                            url: window.host + '/systems/integration-react/api/request.php',
                            params: {
                                token: window.token,
                                do: 'getUserOnline',
                                type: 'Dashboard',
                                filter_type: 'store',
                                filter_date_start: (dataInicial ? dataInicial.toISOString().split('T')[0] : ''),
                                filter_date_end: (dataFinal ? dataFinal.toISOString().split('T')[0] : ''),
                                filter_supervisor: supervisor,
                                filter_id_store: loja,
                                filter_id_emp: filterEmpreendimento,
                                filter_id_user: usuario,
                                id_apl: 223,
                                only_data: true,
                                // filter_id_user: usuario,
                                // filter_id_module: filterModule,
                                // filter_category: categoria,
                                // filter_subcategory: subcategoria,
                                // filter_status: [-2],
                                // filter_frequency: frequencia,
                                // filter_active_2: [0, 1],
                            }
                        }}
                    />
                </Col>
            </Row>
        </>
    )
}
