import Row from "../../../components/body/row";
import Chart from "../../../components/body/chart";
import Col from "../../../components/body/col";
import { useEffect, useState } from "react";
import Icon from "../../../components/body/icon";

export default function Grafico({ icons, filters }) {

    //ESTADOS
    const [assunto, setAssunto] = useState('');
    const [motivo, setMotivo] = useState('');
    const [status, setStatus] = useState('');
    const [dataInicio, setDataInicio] = useState('');
    const [dataFinal, setDataFinal] = useState('');

    //ATUALIZAR FILTROS
    useEffect(() => {
        if (icons) {
            icons(<>
                <Icon type="print" />
            </>)
        }
        if (filters) {
            filters(
                <></>
            )
        }
    }, [motivo, assunto])

    return (
        <Row>
            {/* ASSUNTOS */}
            <Col lg={3}>
                <Chart
                    title="Assuntos"
                    headers={['Assunto', 'Quantidade']}
                    legend={{
                        position: 'right'
                    }}
                    body={{
                        content: ['nome', 'qtd_assunto']
                    }}
                    api={{
                        url: window.host + '/systems/sac/api/sac.php',
                        params: {
                            //token: window.token,
                            do: "sacRelatorioGrafico",
                            // assunto: assunto,
                            // motivo: motivo,
                            status: status,
                            data_inicio: dataInicio,
                            data_final: dataFinal,
                            type: "assunto"
                        }
                    }}
                />
            </Col>
            {/* MOTIVOS */}
            <Col lg={3}>
                <Chart
                    title="Motivos"
                    headers={['Motivos', 'Quantidade']}
                    legend={{
                        position: 'right'
                    }}
                    body={{
                        content: ['nome', 'qtd_motivo']
                    }}
                    api={{
                        url: window.host + '/systems/sac/api/sac.php',
                        params: {
                           // token: window.token,
                            do: "sacRelatorioGrafico",
                            // assunto: assunto,
                            // motivo: motivo,
                            status: status,
                            data_inicio: dataInicio,
                            data_final: dataFinal,
                            type: "motivo"
                        }
                    }}
                />
            </Col>
            {/* DEPARTAMENTOS */}
            <Col lg={3}>
                <Chart
                    title="Departamentos"
                    headers={['Departamentos', 'Quantidade']}
                    legend={{
                        position: 'right'
                    }}
                    body={{
                        content: ['nome', 'qtd_departamento']
                    }}
                    api={{
                        url: window.host + '/systems/sac/api/sac.php',
                        params: {
                           // token: window.token,
                            do: "sacRelatorioGrafico",
                            // assunto: assunto,
                            // motivo: motivo,
                            status: status,
                            data_inicio: dataInicio,
                            data_final: dataFinal,
                            type: "departamento"
                        }
                    }}
                />
            </Col>
        </Row>
    )
}