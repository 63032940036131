import { useState, useEffect } from "react";
import Td from "../../../../../components/body/table/tbody/td";
import Tr from "../../../../../components/body/table/tr";
import axios from "axios";
import Input from "../../../../../components/body/form/input";
import { Spinner } from "react-bootstrap";
import Tippy from "@tippyjs/react";
import Icon from "../../../../../components/body/icon";
import ModalDocumento from "../Modal";

export default function Item(props){
    // ESTADOS
    const [checked, setChecked] = useState(false);
    const [values, setValues] = useState([]);
    const [lote, setLote] = useState('');
    const [loading, setLoading] = useState(false);
    const [changed, setChanged] = useState(false);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if(props?.values){
            let values = [];
            let lote;
            props?.values.map((value, i) => {
                if(value.mov_ativo == 1){
                    values.push(value.id_subcategoria);
                }

                if(value.id_subcategoria == props?.documento?.value){
                    lote = value.lote;
                }
            });

            setValues(values);
            setLote(lote);

            if(values.includes(props?.documento?.value)){
                if(!changed){
                    setChecked(true);
                }
            }else{
                if(!changed){
                    setChecked(false);
                }
            }
        }else{
            setValues([]);
            setLote('');
        }
    },[props?.values]);

    // RESETA VALOR DO LOADER
    useEffect(() => {
        if(!props?.disabled){
            setLoading(false);
        }
    },[props?.disabled]);

    // RESETA VALOR DO CHANGED
    useEffect(() => {
        setChanged(false);
    },[props?.obraId, props?.idJob, props?.lojaId]);

    function handleCheck(e) {
        if(e.target.checked){
            setChecked(true);
        }else{
            setChecked(false);
        }
        
        setChanged(true);
        setLoading(true);
        props.callback('loading');
        props.callbackItem(false);

        axios({
            method: 'post',
            url: window.host+"/systems/integration-react/api/registry.php?do=set_registry&token=" + window.token,
            data: {
                lote: lote,
                id_group: props?.obraId,
                id_store: [{id:props?.lojaId}],
                id_category: props?.categoriaId,
                id_subcategory: props?.subcategoriaId,
                id_frequency: 4,
                type_registry: (props?.type ? props?.type : 'document'),
                ativo: (e.target.checked ? 1 : 0)
            },
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then((response) => {
            // RESPONSE
            props.callback('check');
            setLote(response.data.lote);
        });
    }

    // CLIQUE NO BOTÃO DE EDIÇÃO
    function handleEdit(){
        setShowModal(true);

        // props.callbackEdit({
        //     id: props?.documento?.value,
        //     nome: props?.documento?.label,
        //     categoria: props?.documento?.aux1,
        //     obrigatorio: props?.documento?.obrigatorio,
        //     anexo: props?.documento?.arquivo
        // });
    }

    const handleCloseModal = (e) => {
        if(e.novo){
            handleCheck({
                target: {
                    checked: true
                },
                reload: true
            });
        }else{
            if(e){
                props?.callback('done');

                if(e.obrigatorio){
                    handleCheck({
                        target: {
                            checked: true
                        },
                        reload: true
                    });
                }
            }
        }
        setShowModal(false);
    }

    // // ATIVA O ITEM DEPOIS DE SER CRIADO
    // useEffect(() => {
    //     if(props?.reload){
    //         if(props?.reload == props?.documento.value){
    //             handleCheck({
    //                 target: {
    //                     checked: true
    //                 },
    //                 reload: false
    //             });
    //         }
    //     }
    // },[props?.reload]);
    
    return(
        <>
            <ModalDocumento
                show={showModal}
                onHide={handleCloseModal}
                categoria={props?.documento?.aux1}
                nome={props?.documento?.label}
                obrigatorio={props?.documento?.obrigatorio}
                ativo={(values.includes(props?.documento?.value) ? true : false)}
                anexo={props?.documento?.arquivo}
                id={props?.documento?.value}
                type="file"
            />

            <Tr disabled={props?.disabled}>
                <Td>
                    <Tippy content={props?.documento?.label}>
                        <span>{props?.documento?.label}</span>
                    </Tippy>
                </Td>
                <Td width={1}>
                    {(props?.edit ? 
                        <Icon
                            type="edit"
                            title="Editar"
                            onClick={() => handleEdit()}
                        />
                    :'')}

                    {(loading ? 
                        <Spinner animation="border" size="sm" />
                    :
                        <Input
                            type="checkbox"
                            name="documento"
                            title={(checked ? 'Desabilitar' : 'Habilitar')}
                            onChange={(e) => handleCheck(e)}
                            value={props?.documento?.value}
                            checked={checked}
                            disabled={(props?.documento?.obrigatorio && checked ? true : false)}
                        />
                    )}
                </Td>
            </Tr>
        </>
    )
}
