import { useState } from 'react';
import { Routes, Route, useParams, Navigate } from 'react-router-dom';
import Navbar from '../../components/header/navbar';
import NavbarLink from '../../components/header/navbar/navbarLink';
import { decrypt } from '../../_assets/js/global';
import Container from '../../components/body/container';
import { JobsProvider } from '../../context/Jobs';

// PÁGINAS
import Grafico from './Grafico';
import Dashboard from './Dashboard';
import Grafico2 from './Grafico_2';

export default function Dashboards(props) {
  const params = useParams();

  var tokenUrl_aux = params['*'].split('/');
  var tokenUrl = tokenUrl_aux[tokenUrl_aux.length - 1];
  var decryptedReturn = JSON.parse(decrypt(tokenUrl));

  window.token = tokenUrl;
  window.rs_id_grupo = decryptedReturn.id_grupo;
  window.rs_id_emp = decryptedReturn.id_emp; 
  window.rs_id_usr = decryptedReturn.id_usr;
  window.rs_id_lja = decryptedReturn.id_lja;
  window.rs_name_usr = decryptedReturn.name_usr;
  window.rs_id_apl = decryptedReturn.id_apl;
  window.rs_sistema_id = decryptedReturn.sistema_id;
  window.rs_permission_apl = decryptedReturn.pus;
  window.rs_id_module = decryptedReturn.id_module;

  // ESTADOS (ÍCONES E FILTROS)
  const [icons, setIcons] = useState(null);
  const [filters, setFilters] = useState(null);

  // DEFINE FILTROS NAVBAR
  const handleSetFilters = (e) => {
    setFilters(e);
  }
  const handleSetIcons = (e) => {
    setIcons(e);
  }

  return (
    <>
        <JobsProvider
            chamados={props.chamados}
            fases={props.fases}
            visitas={props.visitas}
        >
            <Navbar
              filters={filters}
              icons={icons}
            >
              <NavbarLink link={'/systems/dashboards-react/grafico/' + window.token} name={'Gráficos'} icon="chart" />
              <NavbarLink link={'/systems/dashboards-react/grafico-2/' + window.token} name={'Gráficos'} icon="chart" />
              {/* <NavbarLink link={'/systems/dashboards-react/dashboard/' + window.token} name={'Dashboards'} icon="analytics" /> */}
            </Navbar>

            <Container>
              <Routes>
                  <Route path="/:token" index={true} element={<Navigate to={'grafico/'+window.token} replace />} />
                  <Route path="grafico/:token" element={<Grafico filters={handleSetFilters} />} />
                  <Route path="grafico-2/:token" element={<Grafico2 filters={handleSetFilters} icons={handleSetIcons} />} />
                  {/* <Route path="dashboard/:token" element={<Dashboard filters={handleSetFilters} />} /> */}
              </Routes>
            </Container>
        </JobsProvider>
    </>
  );
}
