import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { decrypt } from "../../../_assets/js/global";
import Loader from "../../../components/body/loader";
import Post from "./componentes/post";
import Stories from "./componentes/kpi-comunicados";
import MenuDocumentos from "./componentes/documento";
import BuscaPostComponent from "./componentes/busca/busca";
import Vendas from "./componentes/kpi-comunicados/vendas";
import axios from "axios";
import "./style.css";
import style from './style.module.scss';
import { JobsProvider } from "../../../context/Jobs";
import InfiniteScroll from "react-infinite-scroll-component";
import banner from '../../../_assets/img/hs_telecom.jpg';
import LogoHS from './images/logo.png';

/*SWIPER*/
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "../../../_assets/css/swiper.scss";
import ModalBusca from "./modal";

export default function HomeHS() {
    const params = useParams();
    const paramsUrl = params['*'].substring(params['*'].indexOf('/') + 1);
    const paramsUrlCount = (paramsUrl.match(/\//g) ? paramsUrl.match(/\//g).length : 0);
    var tokenUrl, tokenUrlAux;

    if (paramsUrlCount > 0) {
        tokenUrlAux = params['*'].substring(params['*'].indexOf('/') + 1, params['*'].lastIndexOf('/'));
        tokenUrl = tokenUrlAux.substring(0, tokenUrlAux.indexOf('/'));
    } else {
        tokenUrl = params['*'].substring(params['*'].indexOf('/') + 1);
    }

    var decryptedReturn = JSON.parse(decrypt(tokenUrl));

    window.token = tokenUrl;
    window.rs_id_emp = decryptedReturn.id_emp;
    window.rs_id_usr = decryptedReturn.id_usr;
    window.rs_id_lja = decryptedReturn.id_lja;
    window.rs_name_usr = decryptedReturn.name_usr;
    window.rs_id_apl = decryptedReturn.id_apl;
    window.rs_sistema_id = decryptedReturn.sistema_id;
    window.rs_permission_apl = decryptedReturn.pus;
    window.rs_id_module = decryptedReturn.id_module;
    window.rs_id_office = decryptedReturn.id_office;

    // ESTADOS
    const [swiper, setSwiper] = useState();
    const [search, setSearch] = useState('');
    const [searchAux, setSearchAux] = useState('');
    const [toggle, setToggle] = useState(false)
    const [active, setActive] = useState(window.isMobile ? false : true);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    //
    const [lista, setLista] = useState([]);
    const [loading, setLoading] = useState(true);
    //
    const [showSearch, setShowSeach] = useState(false);

    const handleSetSeach = () => (
        setShowSeach(true)
    )

    const handleCloseSeach = () => (setShowSeach(false), setSearchAux(''))

    const [reload, setReload] = useState(false);
    // Só atualiza duas vezes

    //
    const handleActive = () => setActive(!active)

    const handleToggle = _ => setToggle(!toggle)

    const handleSearch = (res) => {
        setSearch(res);
        getList(true);
        setShowSeach(true)
    }

    function getList(reload) {
        axios.get(window.host + "/homes/api/home3.php?do=cadastros&", {
            params: {
                ativo: 1,
                permissao: 3,
                page: (reload ? 1 : page),
                limit: 4,
                // hashtag_filtro: searchAux,
                filter_multiple_columns: searchAux,
                token: window.token
            }
        }).then((response) => {
            if (reload) {
                setLista(response.data);
                setPage(2);
            } else {
                setLista(lista => [...lista, ...response.data]);
                setPage(page + 1);
            }
            setLoading(false);
            setReload(false);
        })
    }

    //ATUALIZAR QUANDO CLICA EM VISUALIZAR
    const callback_view = () => getList();
    //
    useEffect(() => {
        getList();
    }, [reload, searchAux]);

    // CLICK DESTAQUE
    function handleClickDestaque(id) {
        let scrollTop, containerTop;

        if (id) {
            containerTop = document.getElementById('news_home').offsetTop;
            scrollTop = containerTop + document.getElementById('news_' + id).offsetTop + 60;
            window.scrollTo({
                top: scrollTop,
                behavior: 'smooth'
            });
        }
    }

    // RELOAD LISTA
    const handleReload = () => {
        getList(true);
    }
    // const [idDocAux, setIdDocAux] = useState('');
    // const [nameDocAux, setNameDocAux] = useState('');


    //MODAL DOCUMENTOS AGORA ABRE POR AQUI, PRA ABRIR NO MODAL BUSCA TBM -_-
    const [showModal, setShowModal] = useState(false);
    const [idDoc, setIdDoc] = useState('');
    const [idAuxDoc, setIdAuxDoc] = useState('');
    const [levelDoc, setLeveDoc] = useState('');
    const [nomeDoc, setNomeDoc] = useState('');

    const handleModal = (id, aux, level, nome) => (
        setShowModal(true),
        setIdDoc(id),
        setIdAuxDoc(aux),
        setLeveDoc(level),
        setNomeDoc(nome)
    )

    return (
        <JobsProvider>
            <ModalBusca
                show={showSearch}
                onHide={handleCloseSeach}
                search={search}
                // callback={callback}
                handleModal={handleModal}
                showModal={showModal}
                idDoc={idDoc}
                idAuxDoc={idAuxDoc}
                levelDoc={levelDoc}
                nomeDoc={nomeDoc}
                setShowModal={setShowModal}
            />
            <div className="div-row-post" >
                <MenuDocumentos
                    toggle={toggle}
                    active={active}
                    handleActive={handleActive}
                    //callback={callback}
                    handleModal={handleModal}
                    showModal={showModal}
                    idDoc={idDoc}
                    idAuxDoc={idAuxDoc}
                    levelDoc={levelDoc}
                    nomeDoc={nomeDoc}
                    setShowModal={setShowModal}
                //search={search}
                />
                <div className="content-home-main" style={{ width: 1100 }}>
                    <div className="banner-main" >
                        <Swiper
                            ref={swiper}
                            focusableElements="input, select, div, button, label, option, textarea"
                            preventClicks={false}
                            simulateTouch={false}
                            modules={[Autoplay, Pagination]}
                            autoHeight={false}
                            speed={1000}
                            autoplay={{
                                delay: 3000,
                            }}
                            spaceBetween={24}
                            onSwiper={(swiper) => setSwiper(swiper)}
                            allowTouchMove={true}
                            pagination={{ clickable: true }}
                            slidesPerView={1}
                            className="swiper_banner"
                        >
                            <SwiperSlide>
                                <img src={banner} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={banner} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={banner} />
                            </SwiperSlide>
                        </Swiper>
                    </div>

                    <div>
                        <div className={style.box_news_container}>
                            {lista.length > 0 &&
                                lista.map((item, i) => {
                                    let img_aux;
                                    if (item.img) {
                                        img_aux = process.env.REACT_APP_URL_UPLOAD + '/' + item.img.split(',')[0];
                                    } else {
                                        img_aux = LogoHS;
                                    }
                                    if (i <= 2) {
                                        return (
                                            <div className={style.box_news} key={'news_' + i} onClick={() => handleClickDestaque(item?.id)}>
                                                <div>
                                                    <div
                                                        className={style.box_image}
                                                        style={{ backgroundImage: `url(${img_aux})` }}
                                                    ></div>

                                                    <div className={style.box_news_info}>
                                                        {item?.nome}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>

                    {/* DIV ROW COM POST/KPI/COMUNICADOS */}
                    <div className="content-home-div">
                        <div className="div-home-posts" >
                            <BuscaPostComponent
                                value={searchAux}
                                onChange={(e) => (setSearchAux(e.target.value), getList(true))}
                                handleSearch={handleSearch}
                            />
                            <InfiniteScroll
                                dataLength={4 * page}
                                hasMore={hasMore}
                                next={() => getList()}
                            >
                                <ul>
                                    {(loading ?
                                        <Loader />
                                        :
                                        (lista.length > 0 ?
                                            lista?.map((item, i) => {

                                                var imagemTratada = (item.img).split(",");
                                                imagemTratada.forEach((index) => {
                                                    (imagemTratada.slice(index, index + 1));
                                                });

                                                return (
                                                    <Post
                                                        key={item.id}
                                                        id={item.id}
                                                        follower_photo
                                                        follower_picture={item.marca_logo ? `${process.env.REACT_APP_URL_UPLOAD}/${item.marca_logo}` : ''}
                                                        follower_name={item.nome}
                                                        follower_img={item.img == "" ? false : item.img && imagemTratada[0] ? process.env.REACT_APP_URL_UPLOAD + "/" + imagemTratada[0] : window.host + "/systems/news-react/"}
                                                        array_img={imagemTratada}
                                                        follower_text={item.descricao}
                                                        user_like={item.curtida}
                                                        user_view={item.visualizacao}
                                                        follower_likes={item.curtidas}
                                                        follower_action_time={item.data_publicacao}
                                                        file_post={item.documento_download}
                                                        documento_post={item.documento}
                                                        // callback_refresh={callback_refresh}
                                                        comments={item.comentarios}
                                                        reload={reload}
                                                        setReload={handleReload}
                                                        callback_view={callback_view}
                                                    />
                                                )
                                            })
                                            :
                                            'Nenhum resultado encontrado'
                                        )
                                    )}
                                </ul>
                            </InfiniteScroll>
                        </div>
                        <div className="side-column">
                            {/* <Stories
                            search={search}
                            /> */}
                            <Vendas
                            // search={search}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </JobsProvider>
    )
}
