import { useEffect, useState, useContext, useRef } from "react";
import axios from "axios";
// import { GerenciadorContext } from "../../../context/Gerenciador";
import { GerenciadorContext } from "../../../context/Gerenciador";
import GerenciadorCategoriaSubCategoria from "../Gerenciador/CategoriasSubcategorias/categoriasSubcategoria";
import Icon from "../../../components/body/icon";
import ModalCadastroImagem from "./modalAddFoto";
import Row from "../../../components/body/row";
import Col from "../../../components/body/col";
import Gerenciador from "../../../components/body/gerenciador";
import Table from "../../../components/body/table";
import Tbody from "../../../components/body/table/tbody";
import Tr from "../../../components/body/table/tr";
import Td from "../../../components/body/table/tbody/td";
import Input from "../../../components/body/form/input";
import Loader from "../../../components/body/loader";
import { GlobalContext } from "../../../context/Global";

export default function GerenciadorGaleria() {
    // CONTEXT
    const { handleSetFilters } = useContext(GlobalContext);

    // REF
    const scrollElement = useRef();

    //MODAL
    const [reload, setReload] = useState(false);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(!show);

    //FECHAR MODAL 
    const handleClose = (e) => {
        setShow(e);
        setReload(true);
        setTimeout(() => {
            setReload(false);
        }, 500)
    };

    //INFORMAÇÕES DA API
    const [info, setInfo] = useState('');
    //ESTADOS TABELA
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(true);
    // MANDAR ID PRA TELA CATEGORIA/SUBCATEGORIA
    const [categoriaId, setCategoriaId] = useState(window.rs_id_emp == 26 ? "" : window.rs_id_emp);

    //FUNÇÃO QUE ABRE OU FECHA A COLUNA CATEGORIA
    function handleSetCategoriasCol(id) {
        if (categoriaId === id) {
            setCategoriaId('');
        } else {
            setCategoriaId(id);
        }
    };
    
    //PEGAR AS INFORMAÇÕES DA API
    function getApiInfo() {
        axios.get(window.host+"/api/gerenciador.php?do=get_empreendimentos", {
            params: {
                empreendimento_id: window.rs_id_emp,
                token: window.token,
            }
        }).then((response) => {
            setInfo(response.data)
            setLoading(false);
        })
    };
    
    //RENDERIZAR A API AO CARREGAR A TELA
    useEffect(() => {
        getApiInfo();
        handleSetFilters('');
    }, []);

    return (
        <>
            <ModalCadastroImagem
                show={show}
                handleShow={handleShow}
                onHide={handleClose}
            />
            <Row wrap={(window.isMobile ? true : false)}>
                {(
                    window.rs_id_emp == 26 ?
                        <Col>
                            <Gerenciador
                                id="empreendimentos"
                                title="Empreendimentos"
                                // autoScroll={false}
                                search={
                                    <Input
                                        placeholder="Pesquisar"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        inverse={true}
                                    />
                                }
                                // icon={<Icon type="plus" onClick={handleShow} />}
                            >
                                <Table fullHeight={true}>
                                    <Tbody>
                                        {(loading ?
                                            <Tr>
                                                <Td>
                                                    <Loader show={true} />
                                                </Td>
                                            </Tr>
                                            :
                                            <>
                                                {(
                                                    info.length > 0 ?
                                                        (info.filter((item) => {
                                                            if (!search) return true;
                                                            if (item.nome.toLowerCase().includes(search.toLocaleLowerCase())) {
                                                                return true
                                                            }
                                                        }).map((item, i) => {
                                                            return (
                                                                <Tr
                                                                    cursor="pointer"
                                                                    key={item.id}
                                                                    active={(categoriaId == item.id ? true : false)}
                                                                    // hide={()}
                                                                    innerRef={(categoriaId == item.id ? scrollElement : {})}
                                                                >
                                                                    <Td
                                                                        onClick={() => (
                                                                            handleSetCategoriasCol(item.id)
                                                                        )}
                                                                    >
                                                                        {item.nome}
                                                                    </Td>
                                                                </Tr>
                                                            )
                                                        }))
                                                        : <Tr empty={true} align="left" text="Nenhum resultado encontrado" />
                                                )}
                                            </>
                                        )}
                                    </Tbody>
                                </Table>
                            </Gerenciador>
                        </Col>
                        : <></>
                )}
                {(categoriaId ? <GerenciadorCategoriaSubCategoria id={categoriaId} /> : <></>)}
            </Row>
        </>
    )
}