import { useState, useEffect } from "react";
import axios from "axios";
import Col from "../../../../components/body/col";
import Gerenciador from "../../../../components/body/gerenciador";
import Table from "../../../../components/body/table";
import Tbody from "../../../../components/body/table/tbody";
import Td from "../../../../components/body/table/tbody/td";
import Tr from "../../../../components/body/table/tr";
import Input from "../../../../components/body/form/input";
import Icon from "../../../../components/body/icon";
import Loader from "../../../../components/body/loader";
import ModalAddCell from "./modalAddCell";
import { toast } from "react-hot-toast";
import { useLayoutEffect } from "react";

export default function GerenciadorCategoriaSubCategoria({ id }) {

    //ESTADOS MODAL 
    const [reload, setReload] = useState(false);
    const [nome, setNome] = useState('');
    const [ativo, setAtivo] = useState('');
    const [categoriaId, setCategoriaId] = useState('');
    const [cellID, setCellD] = useState('');
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(!show);

    function handleSwitch(nome, ativo, categoria_id, cell_id) {
        handleShow();
        setNome(nome);
        setAtivo(ativo);
        setCategoriaId(categoria_id);
        setCellD(cell_id);
    };

    //FECHAR MODAL 
    const handleClose = (e) => {
        setShow(e);
        setReload(true);
        setTimeout(() => {
            setReload(false);
        }, 500)
    };

    //ESTADOS
    const [searchCategoria, setSearchCategoria] = useState('');
    const [searchSubCategoria, setSearchSubCategoria] = useState('');
    const [categoria, setCategoria] = useState([]);
    const [subCategoria, setSubCategoria] = useState([]);
    const [subCategoriaId, setSubCategoriaId] = useState('');
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);

    //ABRIR FECHAR COLUNA SUBCATEGORIA
    function handleCol(id) {
        if (subCategoriaId === id) {
            setSubCategoriaId("");
        } else {
            setSubCategoriaId(id);
        }
    };

    // RENDERIZAR -> CATEGORIA
    const getCategory = async () => {
        await axios.get(window.host+"/systems/galeria/api/gerenciador.php?do=get_categoria", {
            params: {
                empreendimento_id: id,
                ativo: 1,
                token: window.token,
            }
        }).then((response) => {
            setCategoria(response.data);
            setLoading(false);
        }).catch((_error) => { })
    };
    // RENDERIZAR -> SUBCATEGORIA
    const getSubcategory = async () => {
        await axios.get(window.host+"/systems/galeria/api/gerenciador.php?do=get_subcategoria", {
            params: {
                empreendimento_id: id,
                categoria_id: subCategoriaId,
                ativo: 1,
                token: window.token,
            }
        }).then((response) => {
            setSubCategoria(response.data);
            setLoading2(false)
        }).catch((_error) => { })
    };

    // APAGAR CÉLULA 
    function deleteCell(change, cell_id, emp_id) {
        if (window.confirm("Deseja apagar ?")) {
            axios.post(window.host+"/systems/galeria/api/gerenciador.php?do=update&what=" + change + "&id=" + cell_id + "&empreendimento_id=" + emp_id + "&token=" + window.token, {
                data: {
                    ativo: 0,
                }
            }).then((response) => { console.log(response)
                toast("Apagado com sucesso!");
                change === "subcategoria" ? getSubcategory() : getCategory()
            }).catch((_error) => { })
        }
    };

    //RENDERIZAR API AO CARREGAR A TELA
    useEffect(() => {
        getCategory();
        getSubcategory();
    }, [subCategoriaId, id]);

    const callback = () => {
        getCategory();
        getSubcategory();
    };

    return (
        <>
            <ModalAddCell
                show={show}
                handleShow={handleShow}
                onHide={handleClose}
                emp_id={id}
                cell_id={cellID}
                nome={nome}
                ativo={ativo}
                categoria_id={categoriaId}
                // sub_categoria_id={subCategoriaId}
                callback={callback}
            />
            <Col>
                <Gerenciador
                    id="categorias"
                    title="Categorias"
                    autoScroll={false}
                    search={
                        <Input
                            name="pesquisar-categoria"
                            placeholder="Pesquisar"
                            value={searchCategoria}
                            onChange={(e) => setSearchCategoria(e.target.value)}
                            inverse={true}
                        />}
                    //icon={<Icon type="new" onClick={() => handleSwitch('', subCategoriaId, '', 1, id)} />}> 
                    icon={<Icon type="new" onClick={() => handleSwitch()} />}>
                    <Table>
                        <Tbody>
                            {(
                                loading ?
                                    <Tr>
                                        <Td>
                                            <Loader show={true} />
                                        </Td>
                                    </Tr>
                                    :
                                    <>
                                        {(
                                            categoria.length > 0 ?
                                                (categoria.filter((item) => {
                                                    if (!searchCategoria) return true;
                                                    if (item.nome.toLowerCase().includes(searchCategoria.toLocaleLowerCase())) {
                                                        return true
                                                    }
                                                }).map((item, i) => {

                                                    return (
                                                        <>
                                                            {(
                                                                <Tr
                                                                    cursor="pointer"
                                                                    key={item.id}
                                                                    active={(subCategoriaId === item.id ? true : false)}
                                                                // hide
                                                                // innerRef
                                                                >
                                                                    <Td onClick={() => handleCol(item.id)}> {item.nome} </Td>
                                                                    <Td align="end" width={1}>
                                                                        <Icon type="edit" onClick={() => handleSwitch(item.nome, item.ativo, '', item.id)} />
                                                                        <Icon type="delete" onClick={() => deleteCell("categoria", item.id, item.empreendimento_id)} />
                                                                    </Td>
                                                                </Tr>
                                                            )}
                                                        </>
                                                    )
                                                }))
                                                : <Tr empty={true} align="left" text="Nenhum resultado encontrado" />
                                        )}
                                    </>
                            )}
                        </Tbody>
                    </Table>
                </Gerenciador>
            </Col>
            {(
                subCategoriaId ?
                    <Col>
                        <Gerenciador
                            id="sub-categorias"
                            title="Subcategorias"
                            search={
                                <Input
                                    name="pesquisar-subcategoria"
                                    placeholder="Pesquisar"
                                    value={searchSubCategoria}
                                    onChange={(e) => setSearchSubCategoria(e.target.value)}
                                    inverser={true}
                                />
                            }
                            // icon={<Icon type="new" onClick={() => handleSwitch('', subCategoriaId, '', 1, id)} />}
                            icon={<Icon type="new" onClick={() => handleSwitch('', '', subCategoriaId)} />}
                        // empreendimento_id
                        >
                            <Table>
                                <Tbody>
                                    {(
                                        loading2 ?
                                            <Tr>
                                                <Td>
                                                    <Loader show={true} />
                                                </Td>
                                            </Tr>
                                            :
                                            <>
                                                {(
                                                    subCategoria.length > 0 ?
                                                        (subCategoria.filter((item) => {
                                                            if (!searchSubCategoria) return true;
                                                            if (item.nome.toLowerCase().includes(searchSubCategoria.toLocaleLowerCase())) {
                                                                return true;
                                                            }
                                                        }).map((item, i) => {

                                                            return (
                                                                <>
                                                                    {(
                                                                        item.ativo === 1 ?
                                                                            <Tr
                                                                                key={item.id}
                                                                            >
                                                                                <Td>{item.nome}</Td>
                                                                                <Td align="end">
                                                                                    <Icon type="edit" onClick={() => handleSwitch(item.nome, item.ativo, item.categoria_id, item.id)} />
                                                                                    <Icon type="delete" onClick={() => deleteCell("subcategoria", item.id, item.empreendimento_id)} />
                                                                                </Td>
                                                                            </Tr>
                                                                            : ""
                                                                    )}
                                                                </>

                                                            )
                                                        }))
                                                        : <Tr empty={true} align="left" text="Nenhum resultado encontrado" />
                                                )}
                                            </>
                                    )}
                                </Tbody>
                            </Table>
                        </Gerenciador>
                    </Col>
                    : ""
            )}
        </>
    )
}