import { useEffect, useState, useContext, useRef } from "react";
import { GlobalContext } from "../../../../context/Global";
import { GerenciadorContext } from "../../../../context/Gerenciador";
import Gerenciador from "../../../../components/body/gerenciador";
import Table from "../../../../components/body/table";
import Tbody from "../../../../components/body/table/tbody";
import Tr from "../../../../components/body/table/tr";
import Td from "../../../../components/body/table/tbody/td";
import Input from "../../../../components/body/form/input";
import Icon from "../../../../components/body/icon";
import Editor from "../Editor";
import Documentos from "../Documentos";
import { useParams } from "react-router-dom";
import axios from "axios";
import Usuarios from "../Usuarios";
import Switch from "../../../../components/body/switch";
import { toast } from "react-hot-toast";

export default function ObrasColunaGerenciador(props) {
    // CONTEXT
    const {
        grupoSelected,
        handleSetGrupoSelected,
        handleSetEmpreendimentoSelected,
        //     handleAutoScroll,
        //     withoutAssociation,
        handleSetWithoutAssociation,
    } = useContext(GerenciadorContext);

    const { formSuccess, buttonState, handleSetFormSuccess } = useContext(GlobalContext);

    // REF
    const scrollElement = useRef();

    // GET PARAMS
    const params = useParams(); 

    // ESTADOS
    const [obras, setObras] = useState([]);
    const [search, setSearch] = useState('');
    const [obraId, setObraId] = useState(props?.obraId);
    const [lote, setLote] = useState('');
    const [newCol, setNewCol] = useState(false);
    const [lojaId, setLojaId] = useState(props?.lojaId);
    const [reload, setReload] = useState(false);
    const [options, setOptions] = useState('');
    const [loading, setLoading] = useState(false);
    const [filterActive, setFilterActive] = useState([1]);

    useEffect(() => {
        setLojaId(props?.loja);
        setObraId('');
    },[props?.loja]);

    // HANDLE SCROLL ELEMENT
    function handleScrollElement() {
        setTimeout(() => {
            if(scrollElement.current){
                scrollElement.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    // GET OBRAS LIST
    const handleSetObras = (e) => {
        setObras(e);
        if(e.length > 0 && params['id']){
            setObraId(params['id']);
        }

        if(e.length > 0 && params['lote']){
            setLote(params['lote']);
        }
    }
    
    // GET OBRA INFO
    const handleSetObraId = (id, lote, id_job) => {
        setObraId(id);
        setLote(lote);
        setNewCol(false);
        handleScrollElement();
    }

    // NOVA OBRA
    const handleSetNewCol = () => {
        setObraId('');
        setLote('');
        setOptions('');
        setNewCol(true);
    }

    // CALLBACK EDIÇÃO
    const handleCallbackEditor = (e) => {
        setReload(!reload);
        if(e){
            setNewCol(false);
            setObraId('');
            setLote('');
        }
    }

    // FECHA EDITOR
    const handleCloseEditor = () => {
        setNewCol(false);
        setObraId('');
        setLote('');
        setLoading(true);
    }

    // GET OPTIONS
    function get_options(){
        axios({
            url: window.host + "/systems/integration-react/api/registry.php?do=get_formOptions&token=" + window.token,
            method: "get",
            params: {
                lote: (lote ? lote : 0),
                filter_id_group: (obraId ? obraId : 0),
                filter_has_type_registry: true,
                filter_active: [1,0],
                filter_id_apl_default: 0,
                filter_id_lja: (lojaId ? lojaId : 0)
            }
        }).then((response) => {
            setOptions(response.data);
            setLoading(false);
        })
    }

    useEffect(() => {
        if((lote && obraId) || newCol){
            setLoading(true);
            get_options();
        }
    },[lote, obraId, newCol]);

    // FILTRO DE INATIVAS
    function handleFilterActive(e) {
        if(e.target.checked){
            setFilterActive([0, 1]);
        }else{
            setFilterActive([1]);
        }
    }

    // ATIVAR / DESATIVAR OBRAS
    function habilitarObra(lote, id_job, ativo){
        axios({
            method: 'post',
            url: window.host+"/systems/integration-react/api/registry.php?do=set_registry_active&token=" + window.token,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            data: {
                lote: lote,
                id_job: id_job,
                ativo: (ativo ? 1 : 0)
            }
        }).then(() => {
            setReload(!reload);
            toast('Obra '+(ativo ? 'ativada' : 'desativada'));
        });        
    }

    // RELOAD LIST
    const handleReload = () => {
        setLoading(true);
        get_options();
    }

    if(lojaId){
        return (
            <>
                <Gerenciador
                    id="obra"
                    title="Obras"
                    search={
                        <Input
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}                        
                        />
                    }
                    switch={
                        <Input
                            type="checkbox"
                            name="obras_inativas"
                            label="Inativas"
                            inverse={true}
                            onChange={(e) => handleFilterActive(e)}
                        />
                    }
                    icon={
                        <Icon
                            type="new"
                            onClick={() => handleSetNewCol('')}
                        />
                    }
                >
                    <Table
                        id="obras"
                        api={window.host+"/systems/integration-react/api/list.php?do=get_list&token="+window.token}
                        params={{
                            filter_type: '5',
                            filter_id_store: lojaId,
                            filter_active: filterActive,
                            np: false
                        }}
                        text_limit={false}
                        onLoad={handleSetObras}
                        reload={lojaId + filterActive + reload}
                    >
                        <Tbody>
                            {(obras.length > 0 ?
                                obras.filter((item) => {
                                    if (!search) return true;
                                    if (item.mov_formato.toLowerCase().includes(search.toLocaleLowerCase())){
                                        return true
                                    }
                                }).map((obra, i) => {
                                    return(
                                        <Tr
                                            cursor="pointer"
                                            key={obra?.id_job}
                                            active={(obraId == obra.id_job ? true : false)}
                                            innerRef={(grupoSelected == props?.obrasId ? scrollElement : {})}
                                        >
                                            <Td
                                                onClick={() => handleSetObraId(obra.id_job, obra.lote, obra.id_job)}>
                                                {obra?.mov_tipo}
                                                {(!obra?.mov_ativo ? <span className="text-secondary"> (Inativa)</span> : '')}
                                            </Td>              

                                            <Td align="end" width={1}>
                                                <Switch
                                                    checked={obra.mov_ativo}
                                                    onChange={(e) => habilitarObra(obra.lote, obra.id_job, e.target.checked)}
                                                />
                                            </Td>
                                        </Tr>
                                    )
                                })
                            :<></>)}
                        </Tbody>
                    </Table>
                </Gerenciador>

                {/* EDITA INFO OBRA */}
                {(obraId || newCol ?
                    <Editor
                        empty={true}
                        id={obraId}        
                        lote={lote}
                        loja={lojaId}
                        luc={props?.luc}
                        callback={(e) => handleCallbackEditor(e)}
                        close={handleCloseEditor}
                        options={options}
                        loading={loading}
                    />
                : 
                    <></>
                )}

                {(obraId ?
                    <>
                        <Usuarios
                            obraId={obraId}
                            options={options}
                            loading={loading}
                        />

                        <Documentos
                            title="Documentos"
                            id={obraId}               
                            loja={lojaId}
                            type="document"
                            options={options}
                            loading={loading}
                            callback={handleReload}
                        />

                        <Documentos
                            title="Arquivos"
                            id={obraId}                           
                            loja={lojaId}
                            type="file"
                            options={options}
                            loading={loading}
                            callback={handleReload}
                        />

                        <Documentos
                            title="Vistoria Inicial"
                            id={obraId}                           
                            loja={lojaId}
                            type="vistory_initial"
                            options={options}
                            loading={loading}
                        />

                        <Documentos
                            title="Vistoria Intermediária"
                            id={obraId}                          
                            loja={lojaId}
                            type="vistory_intermediate"
                            options={options}
                            loading={loading}
                        />

                        <Documentos
                            title="Vistoria Final"
                            id={obraId}                          
                            loja={lojaId}
                            type="vistory_final"
                            options={options}
                            loading={loading}
                        />
                    </>
                : 
                    <></>
                )}
            </>
        )
    }
}