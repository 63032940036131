import "./post.css";
import { useState, useEffect } from "react";
import Icon from "../../../../../components/body/icon";
import PostMessage from "./follower_message";
import InputEmoji from "./inputEmoji";
import Input from "../../../../../components/body/form/input";
import parse from "html-react-parser";
import axios from "axios";
import Form from "../../../../../components/body/form";
import ModalCurtida from "./modal";
import { cd, downloadFile } from "../../../../../_assets/js/global";

/*SWIPER*/
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
// import "../../../../_assets/css/swiper.scss";
import "../../../../../_assets/css/swiper.scss"
import ModalVisualizacao from "./modalvisualizacao";


export default function Post(props) {
    // ESTADOS
    const [comentario, setComentario] = useState('');
    const [showComment, setShowComment] = useState(false);
    const [countComment, setCountComment] = useState(props?.comments);
    const [countLike, setCountLike] = useState(props?.follower_likes);
    const [stateLike, setStateLike] = useState(props?.user_like);
    const [show, setShow] = useState(false);
    const [auxId, setAuxId] = useState('');
    const [reloadComment, setReloadComment] = useState();
    const [swiper, setSwiper] = useState();
    const [showView, setShowView] = useState(false);
    const [viewId, setViewId] = useState('')
    //modal tabela curtidas
    const handleShow = (id) => (setShow(true), setAuxId(id));
    const onHide = () => setShow(false);
    //modal tabela visualização
    const handleShowView = (id) => (setShowView(true), setViewId(id));
    const onHideView = () => setShowView(false)


    //AÇÕES VER COMENTÁRIO
    const handleComment = _ => setShowComment(!showComment);

    //AÇÕES POSTAR COMENTÁRIO
    const data = {
        id: props?.id,
        loja_id: window.rs_id_lja,
        usuario_id: window.rs_id_usr,
        comentario: comentario,
    };

    //FUNÇÃO QUE MANDA CURTIDAS PARA API
    function likeIt(news_id, likeUnlike) {
        axios({
            url: window.host + "/homes/api/home3.php?do=curtidaNoticia&token=" + window.token,
            method: "post",
            data: {
                id: news_id,
                loja_id: window.rs_id_lja,
                usuario_id: window.rs_id_usr,
                curtida: likeUnlike == 1 ? 0 : 1,
            },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }).then(() => {
            if (likeUnlike == 1) {
                setCountLike(countLike - 1);
            } else {
                setCountLike(countLike + 1);
            }
        }).catch((_error) => { })
    }

    // CALLBACK AO ENVIAR COMENTÁRIO
    const handleCallbackComentario = () => {
        setReloadComment(Math.random()); // SETA UM NÚMERO ALEATÓRIO PAR AO ESTADO SOFRER ALTERAÇÃO E REALIZAR O RELOAD
        setComentario('');
        setCountComment(countComment + 1);
    }

    //AÇAO VISUALIZAR POST 
    function viewIt() {
        axios({
            url: window.host + "/homes/api/home3.php?do=usuarioVisualizacao&token=" + window.token,
            method: "post",
            data: {
                // noticia_id: props?.id,
                id: props?.id,
                usuario_id: window.rs_id_usr,
            },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }).then(() => {
            props?.callback_view(true)
        }).catch((_error) => { })
    }

    return (
        <li className="list-post">
            <ModalCurtida id={auxId} show={show} onHide={onHide} />
            <ModalVisualizacao id={viewId} show={showView} onHide={onHideView} />
            <div className="follower-header" >
                <div className="follower-header-info" >
                    {(
                        props?.follower_picture ?
                            <img
                                src={props?.follower_picture}
                            />
                            :
                            <div className="post-user-initial">{props?.follower_name.slice(0, 1)}</div>
                    )}
                    <div>
                        <div className="follower-header-name">
                            <h5>{props?.follower_name}</h5>
                            {/* <span> adicionou {props?.follower_action}</span> */}
                        </div>
                        <div className="follower-header-span">
                            <span>Publicado: {cd(props?.follower_action_time)}</span>
                        </div>
                    </div>
                </div>
                {/* <div className="follower-header-btn"><button>...</button></div> */}
            </div>

            <div className="follower-paragraph-info">

                {(
                    props?.follower_text &&
                    parse(props?.follower_text)
                )}

            </div>

            <div className="follower-picture-video-info">
                {/* verificar se tem documento 
                
                documento_post

                colocar do tamanho da imagem ?? 
                
                */}
                {(
                    props?.documento_post ?

                        <div className="">
                            <div className="backgroud-pdf-post">
                                <iframe
                                    title={props?.id}
                                    className="iframe-post" src={`https://docs.google.com/gview?url=${process.env.REACT_APP_URL_UPLOAD}/${props?.documento_post}&embedded=true`}
                                // onError={(e) => console.log(e)}
                                >
                                </iframe>
                            </div>
                            <div className="backgroud-pdf-div" >
                                <span><a href={`${process.env.REACT_APP_URL_UPLOAD}/${props?.documento_post}`} target="_blank" rel="noreferrer">
                                    Clique aqui </a> caso o documento não seja exibido.</span>
                            </div>
                        </div>
                        : <></>
                )}

                {(
                    props?.follower_img ?

                        props.array_img.length <= 1 ?
                            <>
                                <img src={props?.follower_img} alt="foto" />
                            </>
                            :

                            <Swiper
                                ref={swiper}
                                focusableElements="input, select, div, button, label, option, textarea"
                                preventClicks={false}
                                simulateTouch={false}
                                modules={[Autoplay, Pagination]}
                                autoHeight={false}
                                speed={1000}
                                autoplay={{
                                    delay: 3000,
                                }}
                                spaceBetween={24}
                                onSwiper={(swiper) => setSwiper(swiper)}
                                allowTouchMove={true}
                                pagination={{ clickable: true }}
                                slidesPerView={1}
                                className="swiper_banner"
                            >
                                {props?.array_img.map((image) => {
                                    return (
                                        <SwiperSlide>
                                            <img
                                                src={image ? process.env.REACT_APP_URL_UPLOAD + "/" + image : window.host + "/systems/news-react/"}
                                                alt="foto"
                                            />
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>

                        : <></>
                )}
                {(
                    props?.follower_video ?

                        <iframe
                            className="videoPlayer"
                            src={props?.follower_video}
                            title={props.title}
                            allow={props.allow}
                            allowFullScreen={props.allowFullScreen}
                        >
                            {props.children}
                        </iframe>
                        : <></>
                )}
            </div>

            <div className="follower-row-action">
                <div>
                    <Icon
                        type="like"
                        animated
                        title={stateLike == 1 ? "Descurtir" : "Curtir"}
                        className={stateLike == 1 ? "text-primary" : "text-secondary"}
                        onClick={() => (
                            likeIt(props?.id, stateLike),
                            setStateLike(!stateLike)
                        )}
                    />

                    <span onClick={() => handleShow(props?.id)}>
                        {countLike == 0 ? 'Seja o primeiro a curtir' : (countLike + (window.isMobile ? ' Curt.' : ' Curtida(s)'))}
                    </span>

                    <span onClick={handleComment}>
                        {countComment} {(window.isMobile ? 'Comen.' : 'Comentário(s)')}
                    </span>
                </div>
                <div className="row-action-gray">
                    {
                        props?.file_post ?
                            <a href={`${process.env.REACT_APP_URL_UPLOAD}/uploads/upload-malltech/${props?.file_post}.pdf`}
                                target="_blank" rel="noreferrer"
                            >
                                <Icon
                                    type="download"
                                    animated
                                    onClick={() => downloadFile(props?.file_post, 'Documentos')}
                                // className={(props?.visualizacao == 1 ? 'text-success' : '') + "me-2"}
                                // onClick={() => handleShowView(props?.id)}
                                />
                            </a>
                            : <></>
                    }
                    <Icon
                        type="check"
                        animated
                        title="Confirmar visualização"
                        className={(props?.user_view == 1 ? 'text-success' : '') + " me-2"}
                        onClick={() => viewIt()}
                    />
                    <Icon
                        type="view"
                        animated
                        className={" me-2"}
                        onClick={() => handleShowView(props?.id)}
                    />
                    <>
                        <Icon type="share" animated title={false} className="me-2" />
                        <a href={'https://api.whatsapp.com/send?text=' + props.follower_name + '%20https://sistemas.malltech.com.br/?noticia=' + props?.id} target="_blank">
                            {(window.isMobile ? 'Comp.' : 'Compartilhar')}
                        </a>
                    </>
                </div>
            </div>
            {
                showComment &&

                <PostMessage
                    id={props?.id}
                    reload={reloadComment}
                />
            }

            <div className="write-message-div">
                <Form
                    className="formSocial"
                    data={data}
                    api={window.host + "/homes/api/home3.php?do=comentarNoticia&token=" + window.token}
                    callback={handleCallbackComentario}
                >
                    <InputEmoji
                        value={comentario}
                        onChange={(e) => setComentario(e.target.value)}
                        placeholder="Escreva um comentário"

                        type="text"
                        name="comentario"
                    />
                </Form>
            </div>
        </li>
    )
}