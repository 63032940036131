import { useState, useEffect } from "react";
import Input from "../../../../components/body/form/input";
import Gerenciador from "../../../../components/body/gerenciador";
import Table from "../../../../components/body/table";
import Tbody from "../../../../components/body/table/tbody";
import Td from "../../../../components/body/table/tbody/td";
import Tr from "../../../../components/body/table/tr";
import "../style.css"
import axios from "axios";
import Item from "./Item";
import Loader from "../../../../components/body/loader";
import Icon from "../../../../components/body/icon";
import ModalDocumento from "./Modal";

export default function DocumentosGerenciadorObras(props) {
    //const {formSuccess, buttonState, handleSetFormSuccess} = useContext(GlobalContext);

    //ESTADO BUSCA TABELA
    const [search, setSearch] = useState('');
    const [values, setValues] = useState([]);
    const [loadingItem, setLoadingItem] = useState(false);
    const [categorias, setCategorias] = useState([]);
    const [categoria, setCategoria] = useState('');
    const [subcategorias, setSubCategorias] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [reloadItem, setReloadItem] = useState(false)

    // GET CATEGORIAS / SUBCATEGORIAS
    useEffect(() => {
        if(props?.options?.category){
            setCategorias(props?.options?.category);
        }

        if(props?.options?.subcategory){
            setSubCategorias(props?.options?.subcategory);
        }

        if(props?.options?.select_type_registry){
            setValues(props?.options?.select_type_registry);
        }
    },[props?.options]);

    // CHAMA O GET VALUES QUANDO O USUÁRIO MARCAR/DESMACAR ALGUM DOCUMENTO
    const handleCallback = (e) => {
        setLoadingItem(true);
        if(e == 'check'){
            setLoadingItem(false); 
        }

        if(e == 'done'){
            props?.callback(true);
            setLoadingItem(false);
        }
    }

    // ABRE MODAL
    const handleShowModal = () => {
        setReloadItem(false);
        setShowModal(true);
        setCategoria(categorias.filter((elem) => elem.tipo_registro == props?.type)[0].value);
    }

    // FECHA MODAL
    const handleCloseModal = (e) => {
        if(e){        
            axios({
                method: 'post',
                url: window.host+"/systems/integration-react/api/registry.php?do=set_registry&token=" + window.token,
                data: {
                    lote: props?.lote,
                    id_group: props?.id,
                    id_store: [{id:props?.loja}],
                    id_category: categorias.filter((elem) => elem.tipo_registro == props?.type)[0].value,
                    id_subcategory: e.id_job,
                    id_frequency: 4,
                    type_registry: (props?.type ? props?.type : 'document'),
                    ativo: 1
                },
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            }).then(() => {
                props.callback(true); 
            });                       
        }

        setReloadItem(e.id_job);
        setShowModal(false);
        setCategoria('');
    }   

    const handleCallbackItem = () => {
        setReloadItem('');
    }

    return (
        <>
            <ModalDocumento
                show={showModal}
                handleShow={handleShowModal}
                onHide={handleCloseModal}
                categoria={categoria}
                type={props?.type}
            />

            <Gerenciador
                id="documentos-gerenciador-obras"
                title={(props?.title ? props?.title : 'Documentos')}
                search={
                    <Input
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        inverse={true}
                    />
                }
                icon={
                    ((window.rs_permission_apl === 'aprovador' || window.rs_permission_apl === 'master') && props?.type == 'file' ?
                        <Icon
                            type="new"
                            onClick={() => handleShowModal()}
                        />
                    :
                        <></>
                    )
                }
            >                
                <Table>
                    <Tbody>
                        {(props?.loading ? 
                            <Tr>
                                <Td colspan="100%">
                                    <Loader show={true} />
                                </Td>
                            </Tr> 
                        :
                            (categorias.length > 0 ?
                                categorias.filter((elem) => elem.tipo_registro == (props?.type ? props?.type : 'document')).map((categoria, i) => {
                                    return(
                                        <>
                                            <Tr
                                                key={'categoria_'+categoria.value}
                                                disabled={loadingItem}
                                            >
                                                <Td colspan="100%">
                                                    <b>{categoria.label}</b>
                                                </Td>
                                            </Tr>

                                            {(subcategorias.length > 0 ?
                                                subcategorias.filter((item) => {
                                                    if (!search) return true;
                                                    if (item.label.toLowerCase().includes(search.toLocaleLowerCase())){
                                                        return true
                                                    }
                                                }).filter((elem) => elem.aux1 == categoria.value).map((documento, i) => {
                                                    return(
                                                        <Item
                                                            key={'documento_'+documento?.value+'_'+i}
                                                            obraId={props?.id}
                                                            lojaId={props?.loja}
                                                            lote={props?.lote}
                                                            categoriaId={categoria?.value}
                                                            subcategoriaId={documento?.value}
                                                            documento={documento}
                                                            values={values}
                                                            callback={(e) => handleCallback(e)}
                                                            callbackItem={handleCallbackItem}
                                                            disabled={loadingItem}
                                                            reload={reloadItem}
                                                            type={(props?.type ? props?.type : 'document')}
                                                            edit={(props?.type == 'file' ? true : false)}
                                                        />
                                                    )
                                                })
                                            :
                                                <></>
                                            )}
                                        </>
                                    )
                                })
                            :
                                <></>
                            )
                        )}
                    </Tbody>
                </Table>
            </Gerenciador >
        </>
    )
}