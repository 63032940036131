import { useState, useEffect } from "react";
import Row from "../../../../../components/body/row";
import Table from "../../../../../components/body/table";
import Thead from "../../../../../components/body/table/thead";
import Tr from "../../../../../components/body/table/tr";
import Th from "../../../../../components/body/table/thead/th";
import Tbody from "../../../../../components/body/table/tbody";
import Td from "../../../../../components/body/table/tbody/td";
import "../../style.scss";
import style from './historico.module.scss';
import Col from "../../../../../components/body/col";
import { cd, cdh } from "../../../../../_assets/js/global";
import axios from "axios";
import Loader from "../../../../../components/body/loader";

export default function Historico(props) {
    // ESTADOS
    const [revisoes, setRevisoes] = useState([]);
    const [loading, setLoading] = useState(true);

    function get_historico(loading){
        if(loading !== false){
            setLoading(true);
        }

        axios({
            method: 'get',
            url: window.host + "/systems/integration-react/api/list.php?do=get_list&token=" + window.token,
            params: {
                filter_type: 5,
                filter_subtype: 'history',
                filter_parent: true,
                filter_id_group: props?.id,
                filter_type_registry: 'document'
            }
        }).then((response) => {
            setLoading(false);
            if(response.data){
                setRevisoes(response.data);
            }            
        });
    }

    // CHAMA O GET_HISTORICO SEMPRE QUE O COMPONENTE É ABERTO
    useEffect(() => {
        if(props?.show){
            get_historico();
        }
    },[props?.show])

    return (
        <>
            <Tr className={style.historico}>
                <Td colspan="100%">
                    {(loading ? 
                        <Loader />
                    :
                        <>
                            {(revisoes.length > 0 ?
                                <Row>
                                    <Col lg={12}>
                                        <div className="revisao-obras-title">
                                            <span className="revisao-obras-title-span">
                                                Revisão de Documentos
                                            </span>
                                            {/* <Icon
                                                type={showTable === false ? "plus" : "minus"}
                                                className="text-secondary"
                                                onClick={handleShowTable}
                                            /> */}
                                        </div>
                                    </Col>
                                </Row>
                            :
                                <></>
                            )}
                            
                            <div>                        
                                <div className={'nome-generico-teste '+ style.overflow + ' ' + style.container_revisao}>
                                    {(revisoes.length > 0 ?
                                        revisoes.map((revisao, i) => {
                                            return(
                                                <div key={'revision_'+i}>
                                                    <Table
                                                        className="revisao-doc-table"
                                                        text_limit={false}
                                                        fixed={false}
                                                    >
                                                        <Thead>
                                                            <Tr>
                                                                <Th className="th-revisao-doc" colspan="100%"> {revisao.revision}° Revisão </Th>
                                                            </Tr>
                                                            <Tr>
                                                                <Th className="th-revisao-doc">Documentos</Th>
                                                                <Th className="th-revisao-doc" align="center">Envio para Análise</Th>
                                                                <Th className="th-revisao-doc" align="center">Data da Análise</Th>
                                                                <Th className="th-revisao-doc" align="center">Status</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            {(revisao?.data?.length > 0 ?
                                                                revisao?.data.map((documento, i) => {
                                                                    let status, bg;

                                                                    if(documento.status_supervisor == 1){
                                                                        status = 'Aprovado';
                                                                        bg = style.aprovado;
                                                                    }else if(documento.status_supervisor == 2){
                                                                        status = 'Reprovado';
                                                                        bg = style.reprovado;
                                                                    }else if(documento.status_supervisor == 4){
                                                                        status = 'Aprov. c/ ressalva';
                                                                        bg = style.ressalva;
                                                                    }else{
                                                                        if(documento.status == 0){
                                                                            status = 'Pendente';
                                                                            bg = style.pendente
                                                                        }else{
                                                                            status = 'Em análise';
                                                                            bg = style.analise
                                                                        }
                                                                    }

                                                                    return(
                                                                        <Tr
                                                                            key={'documento_'+documento.id_job_status}
                                                                            className={(documento.status_supervisor == 2 ? style.alert : '')}
                                                                        >
                                                                            <Td>
                                                                                {documento?.subcategoria}
                                                                            </Td>
                                                                            <Td align="center" style={{whiteSpace:'nowrap'}}>
                                                                                {cdh(documento?.data_execucao)}
                                                                            </Td>
                                                                            <Td align="center" style={{whiteSpace:'nowrap'}}>
                                                                                {(documento?.data_supervisor ? cdh(documento?.data_supervisor) : '')}
                                                                            </Td>
                                                                            <Td align="center" style={{whiteSpace:'nowrap'}}>
                                                                                <span className={style.status + ' ' + bg}>
                                                                                    {status}
                                                                                </span>
                                                                            </Td>
                                                                        </Tr>
                                                                    )
                                                                })
                                                            :<></>)}
                                                        </Tbody>
                                                    </Table>
                                                </div>
                                            )
                                        })
                                    :
                                        <Table>
                                            <Tr>
                                                <Td align="center">
                                                    Nenhuma revisão de documentos realizada
                                                </Td>
                                            </Tr>
                                        </Table>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </Td>
            </Tr>
        </>
    )
}