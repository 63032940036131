import "./social.css";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import ModalDocumentos from "./modalDocumentos";
import style from './style.module.scss';
import Icon from "../../../../../components/body/icon";

export default function Documentos(props) {

    const [show, setShow] = useState('');
    const [lista, setLista] = useState([]);
    //Modal Documentos
    const [showModal, setShowModal] = useState(false);
    const [idDoc, setIdDoc] = useState('');
    const [idAuxDoc, setIdAuxDoc] = useState('');
    const [levelDoc, setLeveDoc] = useState('');
    const [nomeDoc, setNomeDoc] = useState('');

    const handleModal = (id, aux, level, nome) => (
        setShowModal(true),
        setIdDoc(id),
        setIdAuxDoc(aux),
        setLeveDoc(level),
        setNomeDoc(nome)
    )

    // FECHA MODAL CADASTRO
    const handleCloseModal = (e) => {
        setShowModal(e);
        setTimeout(() => {
        }, 500);
    }



    function getLista() {
        axios.get("https://dev.malltech.com.br/homes/api/home7.php?do=get_quickAccess&token=" + window.token, {
            params: {
                filter_multiple_columns: props?.search,
                id_apl: props?.search
            }
        })
            .then((response) => {
                setLista(response.data)
            })
    }

    useEffect(() => {
        getLista()
    }, [props?.search])

    return (
        <>
            <ModalDocumentos
                show={showModal}
                onHide={handleCloseModal}
                id={idDoc}
                id_aux={idAuxDoc}
                level={levelDoc}
                doc_name={nomeDoc}
            />

            <div className="div-home-stories" >
                <div>
                    <h4>
                        Documentos
                    </h4>
                </div>
                <div className="gray-border"></div>
                <div >
                    <ul>
                        {(lista.length > 0 ?
                            lista.map((item, i) => {
                                let icon;

                                if (item.tipo == "documento") {
                                    icon = "document"
                                } else {
                                    icon = item.tipo
                                }

                                return (
                                    <li className={style.document_item}
                                        key={'documento_' + item?.id}
                                    >
                                        <span className={show.includes(item.id) ? "span-active" : ''}>
                                            <div>
                                                <Icon
                                                    type={icon}
                                                    readonly={(props?.active ? true : false)}
                                                    title={(props?.active ? false : item.nome_aux)}
                                                />

                                                {(item.tipo == "link" ?
                                                    <a href={item.url} target="_blank">
                                                        {item.nome_aux}
                                                    </a>
                                                :
                                                    <a onClick={() => handleModal(218, item.aux_id, item.level, item.nome_aux)}>
                                                        {item.nome_aux}
                                                    </a>
                                                )}
                                            </div>
                                        </span>
                                    </li>
                                )
                            })
                            : '')}
                    </ul>
                </div>
            </div>
        </>
    )
}