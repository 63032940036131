import { useEffect, useState } from "react";
import Modal from "../../../components/body/modal";
import ModalHeader from "../../../components/body/modal/modalHeader";
import ModalBody from "../../../components/body/modal/modalBody";
import Form from "../../../components/body/form";
import Input from "../../../components/body/form/input";
import Textarea from "../../../components/body/form/textarea";
import Button from "../../../components/body/button";
import SelectReact from "../../../components/body/select";
import "./style.css"
import ModalTitle from "../../../components/body/modal/modalHeader/modalTitle";
import Col from '../../../components/body/col';
import CheckboxGroup from "../../../components/body/form/checkboxGroup";
import axios from "axios";
import { cdh, cd, get_date } from "../../../_assets/js/global";

export default function ModalSac({ show, handleShow, onHide, id, id_sac, cliente_id }) {

    //FECHAR MODAL
    const handleClose = (e) => {
        onHide(e)
    };

    //DATA ATUAL
    const hoje = new Date();

    // ESTADOS
    const [loading, setLoading] = useState(false);

    //ESTADOS - cliente
    const [idAux, setIdAux] = useState('');
    const [cadastroAnonimo, setCadastroAnonimo] = useState(2);
    const [nome, setNome] = useState('');
    const [nascimento, setNascimento] = useState('');
    const [cpf, setCpf] = useState('');
    const [rg, setRg] = useState('');
    const [sexo, setSexo] = useState('');
    const [estadoCivil, setEstadoCivil] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [celular, setCelular] = useState('');
    const [cep, setCep] = useState('');
    const [estado, setEstado] = useState('');
    const [cidade, setCidade] = useState('');
    const [bairro, setBairro] = useState('');
    const [endereco, setEndereco] = useState('');
    const [numero, setNumero] = useState('');
    const [complemento, setComplemento] = useState('');
    //ESTADOS - registro sac
    const [operador, setOperador] = useState('');
    const [dataAbertura, setDataAbertura] = useState('');
    const [dataOcorrencia, setDataOcorrencia] = useState('');
    const [empresa, setEmpresa] = useState('');
    const [prioridade, setPrioridade] = useState('');
    const [listaPrioridade, setListaPrioridade] = useState([]);
    const [clienteTipo, setClienteTipo] = useState('');
    const [listaClienteTipo, setListaClienteTipo] = useState([]);
    const [contato, setContato] = useState('');
    const [listaContato, setListaContato] = useState([]);
    const [departamento, setDepartamento] = useState('');
    const [listaDepartamento, setListaDepartamento] = useState([])
    const [assunto, setAssunto] = useState('');
    const [listaAssunto, setListaAssunto] = useState([]);
    const [motivo, setMotivo] = useState('');
    const [listaMotivo, setListaMotivo] = useState([]);
    const [loja, setLoja] = useState('');
    const [emailSac, setEmailSac] = useState('');
    const [descricao, setDescricao] = useState('');
    const [andamento, setAndamento] = useState('');
    const [conclusao, setConclusao] = useState('');
    //CPF AUX E DATA AUX
    const [cpfAux, setCpfAux] = useState('');
    const [horaAtual, setHoraAtual] = useState(new Date());
    //OPÇÕES ESTADOS ORDEM ALFABÉTICA
    const options_estados = [
        { id: 1, value: "Acre", label: "AC" },
        { id: 2, value: "Alagoas", label: "AL" },
        { id: 3, value: "Amapá", label: "AP" },
        { id: 4, value: "Amazonas", label: "AM" },
        { id: 5, value: "Bahia", label: "BA" },
        { id: 6, value: "Ceará", label: "CE" },
        { id: 7, value: "Distrito Federal", label: "DF" },
        { id: 8, value: "Espírito Santo", label: "ES" },
        { id: 9, value: "Goiás", label: "GO" },
        { id: 10, value: "Maranhão", label: "MA" },
        { id: 11, value: "Mato Grosso", label: "MT" },
        { id: 12, value: "Mato Grosso do Sul", label: "MS" },
        { id: 13, value: "Minas Gerais", label: "MG" },
        { id: 14, value: "Pará", label: "PA" },
        { id: 15, value: "Paraíba", label: "PB" },
        { id: 16, value: "Paraná", label: "PR" },
        { id: 17, value: "Pernambuco", label: "PE" },
        { id: 18, value: "Piauí", label: "PI" },
        { id: 19, value: "Rio de Janeiro", label: "RJ" },
        { id: 20, value: "Rio Grande do Norte", label: "RN" },
        { id: 21, value: "Rio Grande do Sul", label: "RS" },
        { id: 22, value: "Rondônia", label: "RO" },
        { id: 23, value: "Roraima", label: "RR" },
        { id: 24, value: "Santa Catarina", label: "SC" },
        { id: 25, value: "São Paulo", label: "SP" },
        { id: 26, value: "Sergipe", label: "SE" },
        { id: 27, value: "Tocantins", label: "TO" }
    ];

    //CHECKBOX LOJA
    function handleSetLoja(e) {
        setLoja(e);
    };

    //VALIDA SE O CPF É VÁLIDO
    const validarCpf = (cpf) => {
        var cpfRegex = /^(?:(\d{3}).(\d{3}).(\d{3})-(\d{2}))$/;
        if (!cpfRegex.test(cpf)) {
            return false;
        }
        var numeros = cpf.match(/\d/g).map(Number);
        var soma = numeros.reduce((acc, cur, idx) => {
            if (idx < 9) {
                return acc + cur * (10 - idx);
            }
            return acc;
        }, 0);
        var resto = (soma * 10) % 11;
        if (resto === 10 || resto === 11) {
            resto = 0;
        }
        if (resto !== numeros[9]) {
            return false;
        }
        soma = numeros.reduce((acc, cur, idx) => {
            if (idx < 10) {
                return acc + cur * (11 - idx);
            }
            return acc;
        }, 0);
        resto = (soma * 10) % 11;
        if (resto === 10 || resto === 11) {
            resto = 0;
        }
        if (resto !== numeros[10]) {
            return false;
            //Toast("CPF inválido")
        }
        //return true;
        setCpfAux(cpf)
    };

    //VALIDAR O CPF PARA MANDAR PRA API
    useEffect(() => {
        if (cpf && cpf.length == 14) {
            validarCpf(cpf)
        }
    }, [cpf]);

    //FUNÇÃO QUE REMOVE CARACTERES ESPECIAIS
    const removeSpecialChars = (value) => {
        return value.replace(/[()\-.\s]/g, "");
    };

    //SE O CPF FOR VÁLIDO, E EXISTIR NO BANCO, PEGA AS INFORMAÇÕES DO CLIENTE E MANDA PROS INPUTS
    function getCliente() {
        setLoading(true);
        axios.get(window.host + "/systems/sac/api/sac.php?&token=" + window.token, {
            params: {
                do: 'getClient',
                id: id,
                cpf: cpfAux
            }
        }).then((response) => {
            setLoading(false);
            return (
                response.data.map((item) => {
                    setIdAux(item?.id)
                    setNome(item?.nome);
                    setNascimento(new Date(item?.data_nascimento));
                    setRg(item?.rg);
                    setSexo(item?.sexo);
                    setEstadoCivil(item?.estado_civil);
                    setEmail(item?.email);
                    setTelefone(item?.telefone);
                    setCelular(item?.celular);
                    setCep(item?.cep);
                    setEstado(item?.estado);
                    setCidade(item?.cidade);
                    setBairro(item?.bairro);
                    setEndereco(item?.endereco);
                    setNumero(item?.numero);
                    setComplemento(item?.complemento);
                })
            );
        })
    }

    //SE PRECISAR ALTERAR UM SAC 
    function getSac() {
        axios.get(window.host + "/systems/sac/api/sac.php?&token=" + window.token, {
            params: {
                do: 'getSac',
                id: id_sac,
            }
        }).then((response) => {
            return (
                response.data.map((item) => {
                    setOperador(item?.operador_id);
                    setDataAbertura(item?.data_abertura);
                    setDataOcorrencia(item?.data_ocorrencia);
                    setEmpresa(item?.empreendimento_id);
                    setPrioridade(item?.prioridade);
                    setClienteTipo(item?.cliente_tipo);
                    setContato(item?.contato);
                    setDepartamento(item?.departamento);
                    setAssunto(item?.assunto);
                    setMotivo(item?.motivo);
                    setLoja(item?.loja_id && item?.loja_id.split(","));
                    setEmailSac(item?.email);
                    setDescricao(item?.descricao);
                    setAndamento(item?.andamento);
                    setConclusao(item?.conclusao);
                })
            )
        })
    };

    //ATUALIZAR CLIENTE E SAC INFO DE ACORDO COM INFO RECEBIDA DO INPUT / PROPS
    useEffect(() => {
        if (cpfAux) {
            getCliente();
        }
    }, [cpfAux])
    //SE FOR CADASTRO ANONIMO ATUALIZA O INPUT TIPO DE CLIENTE PARA ANONIMO 
    useEffect(() => {
        if (cadastroAnonimo == 1) {
            setClienteTipo(3718)
        }
    }, [cadastroAnonimo]);


    //INFO DOS INPUTS QUE VAI PARA A API
    const data = {
        //CLIENTE
        cpf: cpf ? cpf : false,
        nome: nome,
        //data_nascimento: nascimento && `${get_date('date_sql', nascimento)} ${horaAtual.getHours()}:${horaAtual.getMinutes()}:${horaAtual.getSeconds()}`,
        data_nascimento: nascimento && `${get_date('date_sql', nascimento)}`,
        rg: removeSpecialChars(rg),
        sexo: sexo,
        estado_civil: estadoCivil,
        email_cliente: email,
        telefone: removeSpecialChars(telefone),
        celular: removeSpecialChars(celular),
        cep: removeSpecialChars(cep),
        estado: estado,
        cidade: cidade,
        bairro: bairro,
        endereco: endereco,
        numero: numero,
        complemento: complemento,
        //SAC
        id: id_sac,
        cliente_id: cliente_id,
        operador_id: operador,
        data_abertura: dataAbertura ? `${get_date('date_sql', cdh(dataAbertura))} ${horaAtual.getHours().toString().padStart(2, '0')}:${horaAtual.getMinutes().toString().padStart(2, '0')}:${horaAtual.getSeconds().toString().padStart(2, '0')}` : cdh(horaAtual),
        data_ocorrencia: dataOcorrencia ? `${get_date('date_sql', cdh(dataOcorrencia))} ${horaAtual.getHours().toString().padStart(2, '0')}:${horaAtual.getMinutes().toString().padStart(2, '0')}:${horaAtual.getSeconds().toString().padStart(2, '0')}` : cdh(horaAtual),
        prioridade: prioridade,
        cliente_tipo: clienteTipo,
        contato: contato,
        departamento: departamento,
        assunto: assunto,
        motivo: motivo,
        loja_id: loja,
        email: emailSac,
        descricao: descricao,
        andamento: andamento,
        conclusao: conclusao,
    }

    //LIMPAR OS INPUTS AO ENVIAR FORMULARIO
    function resetForm() {
        //REGISTRO CLIENTE
        handleClose();
        setCadastroAnonimo(2);
        setNome('');
        setNascimento('');
        setCpf('');
        setRg('');
        setSexo('');
        setEstadoCivil('');
        setEmail('');
        setTelefone('');
        setCelular('');
        setCep('');
        setEstado('');
        setCidade('');
        setBairro('');
        setEndereco('');
        setNumero('');
        setComplemento('');
        setCpfAux('');
        //REGISTRO SAC
        setOperador('');
        setDataAbertura('');
        setDataOcorrencia('');
        setEmpresa('');
        setPrioridade('');
        setListaPrioridade('');
        setClienteTipo('');
        setListaClienteTipo('');
        setContato('');
        setListaContato('');
        setDepartamento('');
        setListaDepartamento('')
        setAssunto('');
        setListaAssunto('');
        setMotivo('');
        setListaMotivo('');
        setLoja('');
        setEmailSac('');
        setDescricao('');
        setAndamento('');
        setConclusao('');
    }

    return (
        <Modal show={show} onHide={handleClose} large={true} >
            <ModalBody className="pe-lg-0">
                <Form
                    className="sac-form-modal"
                    api={window.host + "/systems/sac/api/sac.php?do=" + ("sacInsert")}
                    data={data}
                    callback={resetForm}
                >
                    <Col lg={12}>
                        <ModalHeader className="pe-lg-0">
                            <div className="sac-modal-title">Cliente</div>
                        </ModalHeader>
                        <SelectReact
                            name="cadastro_anonimo"
                            label="Cadastro Anônimo"
                            type="text"
                            value={cadastroAnonimo}
                            onChange={(e) => setCadastroAnonimo(e.value)}
                            options={[{ value: 1, label: "Sim" }, { value: 2, label: "Não" }]}
                            required={false}
                        />
                        <Input
                            name="cpf"
                            label="CPF"
                            type="text"
                            value={cpf}
                            mask={'999.999.999-99'}
                            onChange={(e) => setCpf(e.target.value)}
                            disabled={cadastroAnonimo == 1 && true}
                            required={cadastroAnonimo == 1 ? false : true}
                        />
                        <Input
                            name="nome"
                            label="Nome"
                            type="text"
                            value={nome}
                            onChange={(e) => setNome(e.target.value)}
                            disabled={cadastroAnonimo == 1 && true}
                            required={cadastroAnonimo == 1 ? false : true}
                            loading={loading}
                        />
                        <Input
                            name="nascimento"
                            label="Nascimento"
                            type="date"
                            value={nascimento}
                            onChange={(e) => setNascimento(e)}
                            disabled={cadastroAnonimo == 1 && true}
                            required={cadastroAnonimo == 1 ? false : true}
                            loading={loading}
                        />

                        <Input
                            name="rg"
                            label="RG"
                            type="text"
                            value={rg}
                            mask={'99.999.999-9'}
                            onChange={(e) => setRg(e.target.value)}
                            disabled={cadastroAnonimo == 1 && true}
                            required={cadastroAnonimo == 1 ? false : true}
                            loading={loading}
                        />
                        <SelectReact
                            name="sexo"
                            label="Sexo"
                            // type="text"
                            value={sexo}
                            onChange={(e) => setSexo(e.value)}
                            options={[{ id: "Masculino", label: "Masculino" }, { id: "Feminino", label: "Feminino" }]}
                            disabled={cadastroAnonimo == 1 && true}
                            required={cadastroAnonimo == 1 ? false : true}
                            loading={loading}
                        />
                        <SelectReact
                            name="estado_civil"
                            label="Estado Cívil"
                            value={estadoCivil}
                            onChange={(e) => setEstadoCivil(e.value)}
                            options={[{ id: "Solteiro", label: "Solteiro" }, { id: "Casado", label: "Casado" }, { id: "Outros", label: "Outros" }]}
                            disabled={cadastroAnonimo == 1 && true}
                            required={cadastroAnonimo == 1 ? false : true}
                            loading={loading}
                        />
                        <Input
                            name="email"
                            label="E-mail"
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={cadastroAnonimo == 1 && true}
                            required={false}
                            loading={loading}
                        />
                        <Input
                            name="telefone"
                            label="Telefone"
                            type="text"
                            value={telefone}
                            mask={'(99) 9999-9999'}
                            onChange={(e) => setTelefone(e.target.value)}
                            disabled={cadastroAnonimo == 1 && true}
                            required={false}
                            loading={loading}
                        />
                        <Input
                            name="celular"
                            label="Celular"
                            type="text"
                            value={celular}
                            mask={'(99) 9999-99999'}
                            onChange={(e) => setCelular(e.target.value)}
                            disabled={cadastroAnonimo == 1 && true}
                            required={false}
                            loading={loading}
                        />
                        <Input
                            name="cep"
                            label="CEP"
                            type="text"
                            value={cep}
                            mask={'99999-999'}
                            onChange={(e) => setCep(e.target.value)}
                            disabled={cadastroAnonimo == 1 && true}
                            required={false}
                            loading={loading}
                        />
                        <SelectReact
                            name="estado"
                            label="Estado"
                            value={estado}
                            onChange={(e) => setEstado(e.value)}
                            options={options_estados}
                            disabled={cadastroAnonimo == 1 && true}
                            required={false}
                            loading={loading}
                        />
                        <Input
                            name="cidade"
                            label="Cidade"
                            type="text"
                            value={cidade}
                            onChange={(e) => setCidade(e.target.value)}
                            disabled={cadastroAnonimo == 1 && true}
                            required={false}
                            loading={loading}
                        />
                        <Input
                            name="bairro"
                            label="Bairro"
                            type="text"
                            value={bairro}
                            onChange={(e) => setBairro(e.target.value)}
                            disabled={cadastroAnonimo == 1 && true}
                            required={false}
                            loading={loading}
                        />
                        <Input
                            name="endereco"
                            label="Endereço"
                            type="text"
                            value={endereco}
                            onChange={(e) => setEndereco(e.target.value)}
                            disabled={cadastroAnonimo == 1 && true}
                            required={false}
                            loading={loading}
                        />
                        <Input
                            name="numero"
                            label="Número"
                            type="text"
                            value={numero}
                            onChange={(e) => setNumero(e.target.value)}
                            disabled={cadastroAnonimo == 1 && true}
                            required={false}
                            loading={loading}
                        />
                        <Input
                            name="complemento"
                            label="Complemento"
                            type="text"
                            value={complemento}
                            onChange={(e) => setComplemento(e.target.value)}
                            disabled={cadastroAnonimo == 1 && true}
                            required={false}
                            loading={loading}
                        />
                    </Col>
                    {/* SAC */}
                    <Col lg={12}>
                        <ModalHeader className="ps-lg-0 sac-modal-header">
                            <ModalTitle>Registro SAC</ModalTitle>
                        </ModalHeader>
                        <Input
                            name="operador"
                            label="Operador"
                            type="text"
                            value={operador}
                            onChange={(e) => setOperador(e.target.value)}
                        />
                        <Input
                            name="data_abertura"
                            label="Data Abertura"
                            type="date"
                            value={dataAbertura}
                            onChange={(e) => setDataAbertura(e)}
                        />
                        <Input
                            name="data_ocorrencia"
                            label="Data Ocorrência"
                            type="date"
                            value={dataOcorrencia}
                            onChange={(e) => setDataOcorrencia(e)}
                            valueEnd={hoje}
                        />
                        <Input
                            name="empresa_sac"
                            label="Empresa"
                            type="text"
                            value={empresa}
                            onChange={(e) => setEmpresa(e.target.value)}
                            required={false}
                        />
                        {/* <SelectReact
                            name="prioridade"
                            label="Prioridade"
                            type="text"
                            value={prioridade}
                            onChange={(e) => setPrioridade(e.value)}
                            options={[
                                { value: "0", label: "" },
                                { value: 3735, label: "Alta" },
                                { value: 3733, label: "Baixa" },
                                { value: 3736, label: "Média" },
                                { value: 3737, label: "Urgente" }
                            ]}
                        /> */}
                        <SelectReact
                            name="cliente_sac"
                            label="Cliente"
                            type="text"
                            value={clienteTipo}
                            onChange={(e) => setClienteTipo(e.value)}
                            options={[
                                { value: 3718, label: "Anônimo" },
                                { value: 3719, label: "Consumidor" },
                                { value: 3720, label: "Funcionário" },
                                { value: 3721, label: "Lojista" },
                                { value: 3722, label: "Procon" },
                                { value: 3723, label: "Via Judicial" }
                            ]}
                        />
                        <SelectReact
                            name="contato"
                            label="Contato"
                            type="text"
                            value={contato}
                            onChange={(e) => setContato(e.value)}
                            options={[
                                { value: 3724, label: "E-mail(SAC)" },
                                { value: 3725, label: "Facebook" },
                                { value: 3726, label: "Pessoalmente" },
                                { value: 3727, label: "Reclame Aqui" },
                                { value: 3728, label: "Telefone" },
                                { value: 3729, label: "Twitter" }
                            ]}
                        />
                        <SelectReact
                            name="departamento"
                            label="Departamento"
                            type="text"
                            value={departamento}
                            onChange={(e) => setDepartamento(e.value)}
                            options={[
                                { value: 1488, label: "Brigada de Incêndio" },
                                { value: 1491, label: "Estacionamento" },
                                { value: 1567, label: "Limpeza" },
                                { value: 1626, label: "Marketing" },
                                { value: 1380, label: "Operações" },
                                { value: 1236, label: "Segurança" },
                            ]}
                        />
                        <SelectReact
                            name="assunto"
                            label="Assunto"
                            type="text"
                            value={assunto}
                            onChange={(e) => setAssunto(e.value)}
                            options={[
                                { value: 3738, label: "Acidente" },
                                { value: 3739, label: "Ar Condicionado" },
                                { value: 3740, label: "Atendimento" },
                                { value: 3741, label: "Balcão" },
                                { value: 3742, label: "Bebedouro" },
                                { value: 3743, label: "Comunicação" },
                                { value: 3744, label: "Controle de Pragas" },
                                { value: 3745, label: "Criança Perdida" },
                                { value: 3746, label: "Dano Físico" },
                                { value: 3747, label: "Dano Material" },
                                { value: 3748, label: "Elevador" },
                                { value: 3749, label: "Entradas e Saídas" },
                                { value: 3750, label: "Limpeza" },
                                { value: 3751, label: "Outros" },
                                { value: 3752, label: "Pronto Atendimento" },
                                { value: 3753, label: "Reforma" },
                                { value: 3754, label: "Segurança" },
                                { value: 3755, label: "Vagas Sinalização" },
                                { value: 3756, label: "Vagas" },
                                { value: 3757, label: "Vagas Especiais" },
                            ]}
                        />
                        <SelectReact
                            name="motivo"
                            label="Motivo"
                            type="text"
                            value={motivo}
                            onChange={(e) => setMotivo(e.value)}
                            options={[
                                { value: 3730, label: "Elogio" },
                                { value: 3731, label: "Reclamação" },
                                { value: 3732, label: "Sugestão" }
                            ]}
                        />
                        <CheckboxGroup
                            group="loja"
                            callback={handleSetLoja}
                            required={false}
                            all={false}
                        />
                        <Input
                            name="email_sac"
                            label="E-mail"
                            type="text"
                            value={emailSac}
                            onChange={(e) => setEmailSac(e.target.value)}
                            disabled={cadastroAnonimo == 1 && true}
                            required={false}
                        />
                        <Textarea
                            name="descricao"
                            label="Descrição"
                            type="text"
                            value={descricao}
                            onChange={(e) => setDescricao(e.target.value)}
                            required={false}
                        />
                        <Textarea
                            name="andamento"
                            label="Andamento"
                            type="text"
                            value={andamento}
                            onChange={(e) => setAndamento(e.target.value)}
                            required={false}
                        />
                        <Textarea
                            name="conclusao"
                            label="Conclusão"
                            type="text"
                            value={conclusao}
                            onChange={(e) => setConclusao(e.target.value)}
                            required={false}
                        />
                        <Button type='submit' >Enviar</Button>
                    </Col>
                </Form>
            </ModalBody>
        </Modal>
    )
}