import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import Input from '../input';
import InputContainer from '../inputcontainer';
import Loader from '../../loader';

export default function CheckboxGroup(props){
    // VARIÁVEIS
    var label = 'Todos';
    var url = '';
    var name = 'checkbox';

    // ESTADOS
    const [checkboxItems, setCheckboxItems] = useState((props?.items ? props?.items : []));
    const [values, setValues] = useState((props.value ? props.value : []));
    const [checkAll, setCheckAll] = useState(null);

    if(props.group == 'loja'){
        label = 'Todas as lojas';
        name = 'checkbox_loja';
        url = window.host+'/api/sql.php?do=select&component=loja&filial=true&token='+window.token;
    }else if(props.group == 'departamento'){
        label = 'Todos os departamentos';
        name = 'checkbox_departamento';
        url = window.host+'/api/sql.php?do=select&component=departamento&token='+window.token;
    }else if(props.group == 'usuario'){
        name = 'checkbox_usuario';
        label = 'Todos os usuários';
        url = window.host+'/api/sql.php?do=select&component=usuario&token='+window.token;
    }else if(props.group == 'cargo'){
        name = 'checkbox_cargo';
        label = 'Todos os cargos';
        url = window.host+'/api/sql.php?do=select&component=cargo&token='+window.token;
    }else if(props.group == 'funcionario'){
        name = 'checkbox_funcionario';
        label = 'Todos os funcionários';
        url = window.host+'/api/sql.php?do=select&component=funcionario&token='+window.token;
    }else if(props.group == 'empreendimento'){
        name = 'checkbox_empreendimento';
        label = 'Todos os empreendimentos';
        url = window.host+'/api/sql.php?do=select&component=empreendimento&token='+window.token;
    }else if(props.group == 'marca'){
        name = 'checkbox_marca';
        label = 'Todas as marcas';
        url = window.host+'/api/sql.php?do=select&component=marca&token='+window.token;
    }else{
        name = (props?.name ? props?.name : 'checkbox_group');
        label = (props?.label ? props?.label : 'Todos');
        url = (props?.url ? window.host+'/api/sql.php?do=select&component=marca&token='+window.token : '');
    }

    // GET DOS ITENS DO CHECKBOX
    useEffect(() => {
        if(url && !props?.options){
            axios({
                method: 'get',
                url: url
            }).then((response) => {
                setCheckboxItems(response.data);
            });
        }

        if(props?.options){
            setCheckboxItems(props?.options)
        }
    },[props?.options]);

    // SELECIONAR ITENS
    function handleCheck(e){
        if (e.target.checked) {
            setValues([...values, e.target.value]);
            props.callback([...values, e.target.value]);
        } else {
            setValues(values.filter((item) => item != e.target.value));
            props.callback(values.filter((item) => item != e.target.value));
        }
    }

    // SELECIONAR TODOS OS ITENS
    function handleCheckAll(e){
        let values = [];

        if(e.target.checked){
            checkboxItems.map((item) => {            
                values.push(item.value);
            });
        }

        setValues(values);
        props.callback(values);
    }

    // VERIFICA SE TODOS OS ITENS ESTÃO SELECIONADOS
    useEffect(() => {
        if(checkboxItems?.length > 0 || props?.items?.length > 0){
            if(values?.length == (checkboxItems.length || props?.items.length)){
                setCheckAll(true);
            }else{
                setCheckAll(null);
            }
        }
    },[values, props?.value]);

    // VERIFICA SE A PROPS "VALUE" SOFREU ALGUMA ALTERAÇÃO
    useEffect(() => {
        setValues(props?.value);
    },[props?.value]);

    // AÇÕES AO FAZER O COLLAPSE
    const handleSetCollapse = (e) => {
        if(props.collapse){
            props.collapse(e);
        }
    }

    return(
        <InputContainer
            display="block"
            collapse={true}
            callback={{
                collapse: handleSetCollapse
            }}
            loading={props?.loading}
            label={
                (props?.all !== false ?
                    <Input
                        type="checkbox"
                        label={label}
                        id={name+'_all'}
                        className="mb-0 p-0"
                        checked={checkAll}
                        onChange={(e) => handleCheckAll(e)}
                        required={false}
                    />
                :
                    <div className="pe-1">
                        {label}
                    </div>
                )
            }
            show={(props.show ? props.show : false)}
            selected={values?.length}
        >
            {(props.loadingItems ? 
                <Loader />
            :
                checkboxItems.map((item, i) => {
                    let id = '';
                    let label = '';

                    if(item?.value){
                        id = item?.value;
                    }else if(item?.id){
                        id = item?.id
                    }

                    if(item?.label){
                        label = item?.label;
                    }else if(item?.nome){
                        label = item?.nome
                    }

                    return (
                        <Input
                            key={name+'_'+id+'_'+i}
                            type="checkbox"
                            name={name}
                            id={name+'_'+id}
                            label={label}
                            obs={(item?.nome_emp ? '('+item.nome_emp+')' : '')}
                            value={id}
                            checked={(values?.includes(id) || values?.includes(id.toString()) ? true : null)}
                            onChange={(e) => handleCheck(e)}
                            required={props?.required}
                        />
                    )
                })
            )}
        </InputContainer>
    )
}