import Row from "../../../components/body/row";
import Col from "../../../components/body/col";
import Table from "../../../components/body/table";
import Thead from "../../../components/body/table/thead";
import Th from "../../../components/body/table/thead/th";
import Tbody from "../../../components/body/table/tbody";
import Tr from "../../../components/body/table/tr";
import Td from "../../../components/body/table/tbody/td";
import "./style.css"
import Form from "../../../components/body/form";
import Textarea from "../../../components/body/form/textarea";
import Input from "../../../components/body/form/input";
import { useState } from "react";
import Button from "../../../components/body/button";
import { useEffect } from "react";
import axios from "axios";

export default function DetalheCliente(props) {
    //ESTADOS FORM
    const [descricao, setDescricao] = useState('');
    const [andamento, setAndamento] = useState('');
    const [conclusao, setConclusao] = useState('');
    const [anexo, setAnexo] = useState('');
    //INFO PARA API ATUALIZAR/FINALIZAR
    const [data, setData] = useState();
    const [api, setApi] = useState();
    //ESTADOS - registro sac
    const [clienteId, setClienteId] = useState('');
    const [operador, setOperador] = useState('');
    const [dataAbertura, setDataAbertura] = useState('');
    const [dataOcorrencia, setDataOcorrencia] = useState('');
    const [empresa, setEmpresa] = useState('');
    const [prioridade, setPrioridade] = useState('');
    const [clienteTipo, setClienteTipo] = useState('');
    const [contato, setContato] = useState('');
    const [departamento, setDepartamento] = useState('');
    const [assunto, setAssunto] = useState('');
    const [motivo, setMotivo] = useState('');
    const [loja, setLoja] = useState('');
    const [emailSac, setEmailSac] = useState('');

    // SETAR ANEXO
    const handleAnexo = (response) => {
        setAnexo(response[0]);
    }

    //FUNÇÃO QUE REMOVE CARACTERES ESPECIAIS
    const removeSpecialChars = (value) => {
        return value.replace(/[()\-.\s]/g, "");
    };

    //PEGAR INFO DO SAC
    function getSac() {
        axios.get(window.host + "/systems/sac/api/sac.php?&token=" + window.token, {
            params: {
                do: 'getSac',
                id: props?.id,
            }
        }).then((response) => {
            return (
                response.data.map((item) => {
                    setOperador(item?.operador_id);
                    setDataAbertura(item?.data_abertura);
                    setDataOcorrencia(item?.data_ocorrencia);
                    setEmpresa(item?.empreendimento_id);
                    setPrioridade(item?.prioridade);
                    setClienteTipo(item?.cliente_tipo);
                    setContato(item?.contato);
                    setDepartamento(item?.departamento);
                    setAssunto(item?.assunto);
                    setMotivo(item?.motivo);
                    setLoja(item?.loja_id);
                    setEmailSac(item?.email);
                    setDescricao(item?.descricao);
                    setAndamento(item?.andamento);
                    setConclusao(item?.conclusao);
                    setClienteId(item?.cliente_id);
                })
            )
        })
    }

    useEffect(() => {
        getSac();
    }, [props?.id, props?.ocorrencia_id]);

    useEffect(() => {
        if (props?.anexo) {
            setAnexo(props?.anexo)
        }
    }, [props?.anexo])

    //FUNÇÃO QUE VERIFICA QUAL INFORMAÇÃO VAI PARA API E DATA DO FORM
    function handleApi(api) {
        const anexo_aux = anexo && JSON.parse(anexo)[0].id
        if (api == 1) { //UPDATE TEXTO DENTRO DA LISTA
            setApi(window.host + "/systems/sac/api/sac.php?do=sacUpdateAdm&token=" + window.token)
            setData(
                {
                    //SAC
                    id: props?.id,
                    andamento: andamento,
                    anexo_opcional: anexo_aux,
                    assunto: assunto,
                    cliente_id: clienteId,
                    cliente_tipo: clienteTipo,
                    conclusao: conclusao,
                    contato: contato,
                    data_abertura: dataAbertura,
                    data_ocorrencia: dataOcorrencia,
                    departamento: departamento,
                    descricao: descricao,
                    email: emailSac,
                    loja_id: loja,
                    motivo: motivo,
                    operador_id: operador,
                    prioridade: prioridade,
                }
            )
        }
        if (api == 2) { //FINALIZAR SAC DENTRO DA LISTA
            setApi(window.host + "/systems/sac/api/sac.php?do=sacFinalizarOcorrencia&token=" + window.token);
            setData(
                {
                    id: props?.id,
                    prioridade: prioridade,
                    cliente_tipo: clienteTipo,
                    contato: contato,
                    departamento: departamento,
                    assunto: assunto,
                    motivo: motivo,
                    loja_id: loja,
                    email: emailSac,
                    descricao: descricao,
                    descricao_aux: descricao,
                    andamento: andamento,
                    andamento_aux: andamento,
                    conclusao: conclusao,
                    conclusao_aux: conclusao,
                    email_opcional: "",
                    anexo_opcional: anexo_aux,
                    array_departamento: departamento,
                    email_atualizacao: "",
                    email_concluir: "",
                }
            )
        }
    };

    //ATUALIZAR A LISTA AO ENVIAR INFO PARA API
    function resetForm() {
        props?.callback_lista(true);
    };

    return (
        <Tr>
            <Td colspan="100%" className="td_full_width">
                <div className="py-2">
                    <Row>
                        <div className="mb-3">
                            <span className="span-bold-sac">Protocolo:</span> {props.protocolo}
                        </div>
                    </Row>
                    <Row>
                        <div className="sac-w-40">
                            <Table
                            // className="w-40"                        
                            >
                                <Thead>
                                    <Tr><Th>Informação do Cliente</Th></Tr>
                                </Thead>
                                <Tbody>
                                    <Tr className="sac-tr"><Td><span className="span-bold-sac">Cliente:</span> {props?.nome}</Td></Tr>
                                    <Tr className="sac-tr"><Td><span className="span-bold-sac">CPF:</span> {props?.cpf}</Td></Tr>
                                    <Tr className="sac-tr"><Td><span className="span-bold-sac">RG:</span> {props?.rg}</Td></Tr>
                                    <Tr className="sac-tr"><Td><span className="span-bold-sac">Data:</span> {props?.data}</Td></Tr>
                                    <Tr className="sac-tr"><Td><span className="span-bold-sac">Contato:</span> {props?.contato}</Td></Tr>
                                    <Tr className="sac-tr"><Td><span className="span-bold-sac">Assunto:</span> {props?.assunto}</Td></Tr>
                                    <Tr className="sac-tr"><Td><span className="span-bold-sac">Motivo:</span> {props?.motivo}</Td></Tr>
                                    <Tr className="sac-tr"><Td><span className="span-bold-sac">Departamento:</span> {props?.departamento}</Td></Tr>
                                    <Tr className="sac-tr"><Td><span className="span-bold-sac">Status:</span> {props?.status}</Td></Tr>
                                    <Tr className="sac-tr"><Td><span className="span-bold-sac">E-mail:</span> {props?.email}</Td></Tr>
                                </Tbody>
                            </Table>
                        </div>
                        <div className="sac-w-40">
                            <Table  >
                                <Thead>
                                    <Tr><Th>Informações do SAC</Th></Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td className="td_full_width sac-td_full_width">
                                            <Form
                                                className="sac-form"
                                                api={api}
                                                data={data}
                                                callback={resetForm}
                                            >
                                                {/* <div> */}
                                                <Textarea
                                                    label="Descrição"
                                                    value={descricao}
                                                    onChange={(e) => setDescricao(e.target.value)}
                                                    required={false}
                                                    disabled={props?.status === "Concluído" ? true : false}
                                                />
                                                <Textarea
                                                    label="Andamento"
                                                    value={andamento}
                                                    onChange={(e) => setAndamento(e.target.value)}
                                                    required={false}
                                                    disabled={props?.status === "Concluído" ? true : false}
                                                />
                                                <Textarea
                                                    label="Conclusão"
                                                    value={conclusao}
                                                    onChange={(e) => setConclusao(e.target.value)}
                                                    required={false}
                                                    disabled={props?.status === "Concluído" ? true : false}
                                                />
                                                <Input
                                                    className="sac-input"
                                                    label="Anexar"
                                                    type="file"
                                                    name="anexo"
                                                    multiple={false}
                                                    value={(anexo)}
                                                    callback={handleAnexo}
                                                    disabled={props?.status === "Concluído" ? true : false}
                                                />
                                                {/* </div> */}
                                            </Form>

                                            {(props?.status !== "Concluído" ?
                                                <div
                                                    className={"sac-detalhe-btn mt-4"}
                                                >
                                                    <Button type="submit" onClick={() => handleApi(1)} >Salvar</Button>
                                                    <Button type="submit" onClick={() => handleApi(2)} >Finalizar</Button>
                                                </div>
                                            :'')}
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </div>
                    </Row>
                </div>
            </Td>
        </Tr>
    )
}