import { useState } from "react";
import Row from "../../../components/body/row";
import Col from "../../../components/body/col";
import Icon from "../../../components/body/icon";
import Table from "../../../components/body/table";
import Tbody from "../../../components/body/table/tbody";
import Td from "../../../components/body/table/tbody/td";
import Thead from "../../../components/body/table/thead";
import Th from "../../../components/body/table/thead/th";
import Tr from "../../../components/body/table/tr";
import Title from "../../../components/body/title";
import ModalVendas from "./modal";

export default function Lista() {

    //ESTADO QUE EXPANDE/ESCONDE ITENS DA TABELA
    const [hide, setHide] = useState(true);

    //ESTADO QUE ARMAZENA INFORMAÇÕES DA API
    const [infos, setInfos] = useState([]);
    const handleSetItems = (e) => {
        setInfos(e);
    }

    //FILTRO LOJA
    const [loja, setLoja] = useState('');
    const handleLoja = (e) => {
        setLoja(e);
    }

    //FILTRO NOME VENDEDOR
    const [nomeVendedor, setNomeVendedor] = useState('');
    const handleVendedor = (e) => {
        setNomeVendedor(e);
    }

    // MODAL
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(!show);

    return (
        <>
            <ModalVendas show={show} handleShow={handleShow} />
            <Row>
                <Col>
                    <Title
                        icon={
                            <>
                                <Icon type="excel" />
                                <Icon type="print" />
                                <Icon
                                    type="expandir"
                                    expanded={!hide}
                                    onClick={() => { setHide(!hide) }}
                                />
                                <Icon
                                    type="plus"
                                    onClick={handleShow}
                                />
                            </>
                        }>Vendas
                    </Title>
                    <Table
                        id="vendas"
                        api={window.host+"/systems/vendas/api/lista.php?do=get_vendas&token=" + window.token}
                        params={{
                            loja: loja,
                            nome: nomeVendedor
                        }}
                        onLoad={handleSetItems}
                    >
                        <Thead>
                            <Tr>
                                <Th hide={hide}>
                                    Id Vendedor Externo
                                </Th>
                                <Th
                                    name="busca_vendedor"
                                    onChange={handleVendedor}>
                                    Nome
                                </Th>
                                <Th hide={hide}>
                                    Id Loja externo
                                </Th>
                                <Th
                                    name="busca-lojas"
                                    api={window.host + '/api/sql.php?do=select&component=loja&np=true&filial=true&token=' + window.token}
                                    onChange={handleLoja}>
                                    Loja
                                </Th>
                                <Th>
                                    Venda Total
                                </Th>
                                <Th>
                                    %
                                </Th>
                                <Th>
                                    Valor Médio 1
                                </Th>
                                <Th>
                                    Valor Médio 2
                                </Th>
                                <Th>
                                    Quant. Vendas
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {(infos.length > 0 ?
                                infos.map((item) => {
                                    return (
                                        <Tr>
                                            <Td hide={hide} >
                                                {item.usuario_externo_id}
                                            </Td>
                                            <Td>
                                                {item.nome}
                                            </Td>
                                            <Td hide={hide} >
                                                {item.loja_externo_id}
                                            </Td>
                                            <Td>
                                                {item.loja}
                                            </Td>
                                            <Td>
                                                {item.venda_total}
                                            </Td>
                                            <Td>
                                                {item.porcentagem}
                                            </Td>
                                            <Td >
                                                {item.valor_medio1}
                                            </Td>
                                            <Td >
                                                {item.valor_medio2}
                                            </Td>
                                            <Td >
                                                {item.vendas}
                                            </Td>
                                        </Tr>
                                    )
                                })
                                : " ")}
                        </Tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
}