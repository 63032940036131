import { useEffect, useState, useContext, useRef } from "react";
import { GlobalContext } from "../../../../context/Global";
import Gerenciador from "../../../../components/body/gerenciador";
import Table from "../../../../components/body/table";
import Tbody from "../../../../components/body/table/tbody";
import Tr from "../../../../components/body/table/tr";
import Td from "../../../../components/body/table/tbody/td";
import Input from "../../../../components/body/form/input";
import Col from "../../../../components/body/col";
import Switch from "../../../../components/body/switch";
import axios from "axios";
import { toast } from "react-hot-toast";
import Loader from "../../../../components/body/loader";
import Icon from "../../../../components/body/icon";

export default function Usuarios(props) {
    const { handleSetFilter } = useContext(GlobalContext);

    // RECARREGA A LISTA SEMPRE QUE ENTRA NA PÁGINA
    useEffect(() => {
        handleSetFilter(true);
    },[]);

    // ESTADOS
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');

    // GET CATEGORIAS / SUBCATEGORIAS
    useEffect(() => {
        if(props?.options?.permission_user){
            setUsers(props?.options?.permission_user);
        }
    },[props?.options]);

    // HABILITA / DESABILITA USUÁRIOS
    function handleCheck(e){
        axios({
            method: 'post',
            url: window.host+"/systems/integration-react/api/registry.php?do=set_permission_user&token=" + window.token,
            data: {
                id_job: props?.obraId,
                id_user: e.target.value,
                active: (e.target.checked ? 1 : 0)
            },
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(() => {
            toast('Acesso '+(e.target.checked ? 'liberado' : 'removido'));
        });
    }

    return (
        <Col>
            <Gerenciador
                id="usuarios"
                title="Usuários"
                search={
                    <Input
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        inverse={true}
                    />
                }
                icon={
                    <a href={process.env.REACT_APP_URL_PROD+'/systems/gerenciador'} target="_blank">
                        <Icon
                            type="new"
                            title="Criar novo usuário"
                        />
                    </a>
                }                
            >
                <Table>
                    <Tbody>
                        {(props?.loading ? 
                            <Tr>
                                <Td colspan="100%">
                                    <Loader />
                                </Td>
                            </Tr> 
                        :
                            (users.length > 0 ?
                                (users.filter((user) => {
                                    if (!search) return true;
                                    if (user.nome_usr.toLowerCase().includes(search.toLocaleLowerCase())){
                                        return true
                                    }
                                }).map((user, i) => {
                                    return (
                                        <Tr key={'user_'+user.id_usr}>
                                            <Td>
                                                {user.nome_usr}
                                            </Td>

                                            <Td width={1}>
                                                <Switch
                                                    value={user.id_usr}
                                                    checked={(user.active == 1 ? true : false)}
                                                    onChange={(e) => handleCheck(e)}
                                                />
                                            </Td>
                                        </Tr>
                                    )
                                }))
                            :
                                <Tr empty={true}>
                                    <Td>Nenhum usuário enconrado</Td>
                                </Tr>
                            )
                        )}
                    </Tbody>
                </Table>
            </Gerenciador>
        </Col>
    );
}
