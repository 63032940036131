import React, { useEffect, useMemo, useCallback, createContext, useState, useContext } from "react";
import axios from "axios";

import { GlobalContext } from "./Global";

export const JobsContext = createContext();

export const JobsProvider = ({ children, chamados, fases, visitas, comunicados, notificacoes }) => {
    const [optionsEmpreendimentos, setOptionsEmpreendimentos] = useState([]);
    const [optionsMotivos, setOptionsMotivos] = useState([]);
    const [optionsStatus, setOptionsStatus] = useState([]);
    const [optionsLoja, setOptionsLoja] = useState([]);
    const [optionsUsuario, setOptionsUsuario] = useState([]);
    const [optionsSystems, setOptionsSystems] = useState([]);
    const [filterEmpreendimento, setFilterEmpreendimento] = useState([]);
    const [autoSwiper, setAutoSwiper] = useState(true);
    const [configuracoes, setConfiguracoes] = useState([]);

    const { filterModule } = useContext(GlobalContext);

    // FUNÇÃO PARA BUSCA INTEGRAÇÃO DE SISTEMAS
    useEffect(() => {
        let searchSystems = true;
        if(searchSystems && optionsSystems.length == 0){
            axios.get(window.host+"/systems/integration-react/api/registry.php?do=get_integration_react&filter_id_module="+filterModule+"&token="+window.token).then((response) => {
                if(response.data){
                    let system_aux = response.data;
                    if(chamados){
                        system_aux.unshift({value: 224, label: 'Chamados', disabled: true});
                    }

                    if(fases){
                        system_aux.unshift({value: 225, label: 'Fases', disabled: true});
                    }

                    if(visitas){
                        system_aux.unshift({value: 226, label: 'Visitas', disabled: true});
                    }

                    if(comunicados){
                        system_aux.unshift({value: 229, label: 'Comunicados', disabled: true});
                    }

                    if(notificacoes){
                        system_aux.unshift({value: 231, label: 'Notificações', disabled: true});
                    }

                    setOptionsSystems(system_aux);
                    searchSystems = false;
                }
            });
        }
    },[]);

    // FUNÇÃO PARA BUSCAR CONFIGURAÇÕES DO EMPREENDIMENTO
    useEffect(() => {
        if(configuracoes.length == 0){
            axios({
                method: 'get',
                url: window.host+"/systems/integration-react/api/list.php?do=configCards&token="+window.token
            }).then((response) => {
                if(response.data){
                    setConfiguracoes(response.data);
                }
            });
        }
    },[]);

    // GET EMPREENDIMENTOS
    useEffect(() => {
        if(optionsEmpreendimentos.length===0){
            axios.get(window.host+'/api/sql.php?do=select&component=grupo_empreendimento&token='+window.token).then((response) =>{
                setOptionsEmpreendimentos(response.data);

        //         if(response.data){
        //             handleSetFilterEmpreendimento(response.data[0].value);
        //         }
            });
        }
    },[]);

    // FILTER EMPREENDIMENTO
    const handleSetFilterEmpreendimento = useCallback((value) => {
        setFilterEmpreendimento(value);
    },[filterEmpreendimento]);

    // GET MOTIVOS
    useEffect(() => {
        if(optionsMotivos.length===0 && filterEmpreendimento){
            axios.get(window.host+'/systems/integration-react/api/list.php?do=get_motive&empreendimento_id='+filterEmpreendimento+'&filter_id_module='+filterModule+'&token='+window.token).then((response) =>{
                setOptionsMotivos(response.data);
            });
        }
    },[filterEmpreendimento]);

    // GET CORES PERSONALIZADAS DO EMPREENDIMENTO
    useEffect(() => {
        if(optionsStatus.length == 0){
        axios({
            method: 'get',
            url: window.host+'/systems/integration-react/api/list.php?do=get_status&token='+window.token
        }).then((response) => {
            setOptionsStatus(response.data);
        })
        }
    },[]);

    // GET OPTIONS DE LOJA
    useEffect(() => {
        axios({
            method: 'get',
            url: window.host + '/api/sql.php?do=select&component=loja',
            params: {
                token: window.token,
                np: true,
                filial: true,
                limit: false,
                grupo_id: (filterEmpreendimento.length > 0 ? false : true),
                empreendimento_id: filterEmpreendimento
            }
        }).then((response) => {
            setOptionsLoja(response.data);
        })

        axios({
            method: 'get',
            url: window.host + '/api/sql.php?do=select&component=usuario',
            params: {
                token: window.token,
                np: true,
                limit: false,
                grupo_id: (filterEmpreendimento.length > 0 ? false : true),
                empreendimento_id: filterEmpreendimento
            }
        }).then((response) => {
            setOptionsUsuario(response.data);
        })
    },[filterEmpreendimento]);

    // AUTO SWIPER
    const handleSetAutoSwiper = useCallback((value) => {
        setAutoSwiper(value);
    },[autoSwiper]);

    const value = useMemo(
        () => ({
            optionsEmpreendimentos,
            optionsMotivos,
            optionsStatus,
            optionsLoja,
            optionsUsuario,
            optionsSystems,
            filterEmpreendimento,
            handleSetFilterEmpreendimento,
            autoSwiper,
            handleSetAutoSwiper,
            configuracoes
        }),
        [
            optionsEmpreendimentos,
            optionsMotivos,
            optionsStatus,
            optionsLoja,
            optionsUsuario,
            optionsSystems,
            filterEmpreendimento,
            handleSetFilterEmpreendimento,
            autoSwiper,
            handleSetAutoSwiper,
            configuracoes
        ]
      );

    return (
        <JobsContext.Provider value={value}>{children}</JobsContext.Provider>
    );
};
