import { useState } from "react";
import Button from "../../../components/body/button";
import Form from "../../../components/body/form";
import Input from "../../../components/body/form/input";
import InputContainer from "../../../components/body/form/inputcontainer";
import TextareaEditor from "../../../components/body/form/textarea/textEditor";
import Icon from "../../../components/body/icon";
import Modal from "../../../components/body/modal";
import ModalBody from "../../../components/body/modal/modalBody";
import ModalHeader from "../../../components/body/modal/modalHeader";
import ModalTitle from "../../../components/body/modal/modalHeader/modalTitle";
import SelectReact from "../../../components/body/select";
import InputFoto from "./inputFoto";
import { useEffect } from "react";
import axios from 'axios';
import CheckboxGroup from "../../../components/body/form/checkboxGroup";
import Textarea from "../../../components/body/form/textarea";
import { cd } from "../../../_assets/js/global";

export default function NewModal({ showNew, handleShowNew, id, handleCloseNew, onHide }) {

    //VARIAVEL FECHAR MODAL
    const handleCloseModalNew = () => {
        onHide(false);
    };

    //ESTADO QUE ARMAZENA INFORMAÇÕES 
    const [titulo, setTitulo] = useState('');
    const [hashtags, setHashtags] = useState('');
    const [departamentos, setDepartamentos] = useState('');
    const [cargos, setCargos] = useState('');
    const [marcas, setMarcas] = useState('');
    const [lojas, setLojas] = useState('');
    const [comentarios, setComentarios] = useState('');
    const [dataInicio, setDataInicio] = useState('');//
    const [dataFinal, setDataFinal] = useState('');//
    const [texto, setTexto] = useState('');
    const [documento, setDocumento] = useState('');
    const [docDownload, setDocDownload] = useState('');
    const [video, setVideo] = useState('');
    const [foto, setFoto] = useState('');

    //MOSTRAR AS INFORMAÇÕES DA API NOS INPUTS, AO CLICAR NO ÍCONE DE EDITAR O POST
    function getEditNews(id) {
        axios({
            url: window.host + "/homes/api/home3.php?do=consultaNoticia&id=" + id + "&token=" + window.token,
            method: "post",
            data: { id: id },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }).then((response) => {
            setTitulo(response.data[0].nome);
            setHashtags(response.data[0].hashtags);
            setDepartamentos(response.data[0].departamento_id.split(','));
            setCargos(response.data[0].cargo_id.split(','));
            setMarcas(response.data[0].marca_id.split(','));
            setLojas(response.data[0].loja_id.split(','));
            setComentarios(response.data[0].permissao_comentario);
            // setDataInicio(new Date(response.data[0].data_publicacao)) -- Precisa ter horário
            // setDataFinal(new Date(response.data[0].data_saida)) -- Precisa ter horário
            setTexto(response.data[0].descricao);
            setDocumento(response.data[0].documento);
            setDocDownload(response.data[0].documento_download);
            setVideo(response.data[0].video);
            setFoto(response.data[0].img);
        }).catch((_error) => { })
    };

    // FUNÇÃO QUE ADICIONA OU MUDA AS INFORMAÇÕES DA API 
    const data = {
        id: id,
        titulo: titulo,
        hashtags: hashtags,
        checkbox_departamento: departamentos,
        cargos: cargos,
        marcas: marcas,
        lojas: lojas,
        permissao_comentario: comentarios,
        data_publicacao: (dataInicio ? cd(dataInicio) : ''),
        data_saida: (dataFinal ? cd(dataFinal) : ''),
        descricao: texto,
        documento: documento,
        documento_download: docDownload,
        video: video,
        img: foto,
    };

    useEffect(() => {
        getEditNews(id);
    }, [id]);

    //ESTADO QUE GUARDA AS OPÇÕES CARGOS E LOJAS QUE VEM DA API
    const [optionsDepartamento, setOptionsDepartamento] = useState([]);
    const [optionsCargo, setOptionsCargo] = useState([]);
    const [optionsMarca, setOptionsMarca] = useState([]);
    const [optionsLoja, setOptionsLoja] = useState([]);

    //OPÇÕES COMENTÁRIOS
    const options_comentarios = [
        { value: 1, label: "Sim" },
        { value: 0, label: "Não" }
    ];

    //CRIAR LISTA DE CARGOS
    useEffect(() => {
        axios.get(window.host + "/api/sql.php?do=select&component=departamento&token=" + window.token).then((response) => {
            setOptionsDepartamento(response.data)
        });
        axios.get(window.host + "/api/sql.php?do=select&component=cargo&token=" + window.token).then((response) => {
            setOptionsCargo(response.data)
        });
        axios.get(window.host + "/api/sql.php?do=select&component=marca&token=" + window.token).then((response) => {
            setOptionsMarca(response.data)
        });
        axios.get(window.host + "/api/sql.php?do=select&component=loja&np=true&filial=true&token=" + window.token).then((response) => {
            setOptionsLoja(response.data)
        });

        //CHECKBOXGROUP

        if (optionsDepartamento.length == 0) {
            axios({
                method: "get",
                url: window.host + "/api/sql.php?do=select&component=departamento&token=" + window.token
            }).then((response) => {
                setOptionsDepartamento(response.data)
            })
        }

        // if (optionCargos.length == 0) {
        //     axios({
        //         method: 'get',
        //         url: window.host + '/api/sql.php?do=select&component=cargo&token=' + window.token
        //     }).then((response) => {
        //         setOptionCargos(response.data);
        //     })
        // } 

        // if (optionMarcas.length == 0) {
        //     axios({
        //         method: "get",
        //         url: window.host + "/api/sql.php?do=select&component=marca&token=" + window.token
        //     }).then((response) => {
        //         setOptionMarcas(response.data);
        //     })
        // }

        // if (optionLojas.length == 0) {
        //     axios({
        //         method: "get",
        //         url: window.host + '/api/sql.php?do=select&component=loja&filial=true&token=' + window.token
        //     }).then((response) => {
        //         setOptionLojas(response.data);
        //     })
        // }

    }, []); // --> retirar o []

    // FUNÇÃO DE EXECUÇÃO DO CHECKBOX DEPARTAMENTOS
    function handleDepartamentos(e) {
        if (e.target.checked) {
            setDepartamentos([...departamentos, e.target.value]);
        } else {
            setDepartamentos(departamentos.filter((item) => item !== e.target.value));
        }
    };
    // checkbox
    function handleSetDepartamento(e) {
        setDepartamentos(e);
    };
    // FUNÇÃO DE EXECUÇÃO DO CHECKBOX CARGOS
    function handleCargos(e) {
        if (e.target.checked) {
            setCargos([...cargos, e.target.value]);
        } else {
            setCargos(cargos.filter((item) => item !== e.target.value));
        }
    };
    // checkbox
    // function handleSetCargos(e){
    //     setCargos(e);
    // };
    // FUNÇÃO DE EXECUÇÃO DO CHECKBOX MARCAS
    function handleMarcas(e) {
        if (e.target.checked) {
            setMarcas([...marcas, e.target.value]);
        } else {
            setMarcas(marcas.filter((item) => item !== e.target.value));
        }
    };
    // checkbox
    //  function handleSetMarcas(e){
    //     setMarcas(e);
    // };
    // FUNÇÃO DE EXECUÇÃO DO CHECKBOX LOJAS
    function handleLojas(e) {
        if (e.target.checked) {
            setLojas([...lojas, e.target.value]);
        } else {
            setLojas(lojas.filter((item) => item !== e.target.value));
        }
    };
    // checkbox
    //  function handleSetLojas(e){
    //     setLojas(e);
    // };

    // SETAR INPUT DOCUMENTO
    const handleSetDocumento = (response) => {
        setDocumento(response[0]);
    };

    // SETAR INPUT DOCUMENTO DOWNLOAD
    const handleSetDocDownload = (response) => {
        setDocDownload(response[0]);
    };

    //FUNÇÃO QUE RESETA OS INPUTS AO CONFIRMAR O ENVIO
    function resetForm() {
        handleCloseNew();
        setTitulo('');
        setHashtags('');
        setDepartamentos([]);
        setCargos([]);
        setMarcas([]);
        setLojas([]);
        setComentarios('');
        setDataInicio(''); // QUANDO ACIONADO INTERFERE NO QUE VEM DA API
        setDataFinal(''); // QUANDO ACIONADO INTERFERE NO QUE VEM DA API
        setTexto('');
        setDocumento('');
        setDocDownload('');
        setVideo('');
        setFoto('');
    };

    return (
        <>
            <Modal show={showNew} onClick={handleShowNew} onHide={handleCloseModalNew}>
                <ModalHeader>
                    <ModalTitle>
                        {(id > 0 ? "Editar" : "Novo")}
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Form
                        api={window.host + "/homes/api/home3.php?do=" + (id ? "updateNoticia" : "insertNoticia") + "&token=" + window.token}
                        data={data}
                        formData={true}
                        callback={resetForm}
                    >
                        <Input
                            name="titulo"
                            type="text"
                            label="Título"
                            value={titulo}
                            onChange={(e) => setTitulo(e.target.value)}
                        />
                        <Input
                            name="hashtagas"
                            type="text"
                            label="Hashtags"
                            value={hashtags}
                            onChange={(e) => setHashtags(e.target.value)}
                            required={false}
                        />
                        {/* <CheckboxGroup
                            group="departamento"
                            callback={handleSetDepartamento}
                            required={false}
                            value={[departamentos.map((item) => { return (item.id) })]}
                        /> */}
                        <InputContainer
                            label="Todos os Departamentos"
                            display="block"
                            collapse={true}
                            selected={departamentos.length}
                        >
                            {optionsDepartamento.map((item) => {
                                return (
                                    <Input
                                        id={"departamento" + item.value}
                                        type="checkbox"
                                        checked={(departamentos.includes(item.value.toString()) ? true : null)}
                                        label={item.label}
                                        value={item.value}
                                        onChange={(e) => handleDepartamentos(e)}
                                        required={false}
                                    />
                                )
                            })}
                        </InputContainer>
                        {/* <CheckboxGroup
                            group="cargo"
                            callback={handleSetCargos}
                            required={false}
                        /> */}
                        <InputContainer
                            label="Todos os Cargos"
                            display="block"
                            collapse={true}
                            selected={cargos.length}
                        >
                            {optionsCargo.map((item) => {
                                return (
                                    <Input
                                        id={"cargo" + item.value}
                                        type="checkbox"
                                        checked={(cargos.includes(item.value.toString()) ? true : null)}
                                        label={item.label}
                                        value={item.value}
                                        onChange={(e) => handleCargos(e)}
                                        required={false}
                                    />
                                )
                            })}
                        </InputContainer>
                        {/* <CheckboxGroup
                            group="marca"
                            callback={handleSetMarcas}
                            required={false}
                        /> */}
                        <InputContainer
                            label="Todas as Marcas"
                            display="block"
                            collapse={true}
                            selected={marcas.length}
                        >
                            {optionsMarca.map((item) => {
                                return (
                                    <Input
                                        id={"marcas" + item.id}
                                        type="checkbox"
                                        checked={(marcas.includes(item.id) ? true : null)}
                                        label={item.label}
                                        value={item.id}
                                        onChange={(e) => handleMarcas(e)}
                                        required={false}
                                    />
                                )
                            })}
                        </InputContainer>
                        {/* <CheckboxGroup
                            group="loja"
                            callback={handleSetLoja}
                            required={false}
                        /> */}
                        <InputContainer
                            label="Todas as Lojas"
                            display="block"
                            collapse={true}
                            selected={lojas.length}
                        >
                            {optionsLoja.map((item) => {
                                return (
                                    <Input
                                        id={"lojas" + item.id}
                                        type="checkbox"
                                        checked={(lojas.includes(item.value.toString()) ? true : null)}
                                        label={item.label}
                                        value={item.id}
                                        onChange={(e) => handleLojas(e)}
                                        required={false}
                                    />
                                )
                            })}
                        </InputContainer>
                        <SelectReact
                            name="comentario"
                            label="Permitir Comentários"
                            value={comentarios}
                            onChange={(e) => setComentarios(e.value)}
                            options={options_comentarios}
                        />
                        <Input
                            name="data publicacao"
                            type="date"
                            label="Agendar Publicação"
                            value={dataInicio}
                            onChange={(e) => setDataInicio(e)}
                            required={false}
                        />
                        <Input
                            name="data expiracao"
                            type="date"
                            label="Data de expiração"
                            value={dataFinal}
                            onChange={(e) => setDataFinal(e)}
                            required={false}
                        />
                        <TextareaEditor
                            name="texto"
                            type="text"
                            label="Digite seu texto..."
                            value={texto}
                            onChange={(e) => setTexto(e.target.value)}
                        />
                        <Textarea
                            name="texto"
                            type="text"
                            label="Digite seu texto..."
                            value={texto}
                            onChange={(e) => setTexto(e.target.value)}
                        />
                        <Input
                            name="documento"
                            type="file"
                            label="Documento"
                            value={documento}
                            multiple={true}
                            callback={handleSetDocumento}
                            required={false}
                        />
                        <Input
                            name="documento para donwload"
                            type="file"
                            label="Documento para download"
                            value={docDownload}
                            multiple={true}
                            callback={handleSetDocDownload}
                            required={false}
                        />
                        <Input
                            name="video"
                            type=" "
                            label="Vídeo"
                            value={video}
                            onChange={(e) => setVideo(e.target.value)}
                            required={false}
                        />
                        <InputFoto
                            name="foto2"
                            type="image"
                            value={foto}
                            onChange={(e) => setFoto(e.target.value)}
                            icon="photo"
                        />
                        <Button type="submit"
                            onClick={handleCloseModalNew}
                        >Enviar
                        </Button>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}