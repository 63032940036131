import { useContext, useState, useEffect } from "react";
import { addDays, cd, get_date, subDays } from "../../../_assets/js/global";
import { JobsContext } from "../../../context/Jobs";
import Col from "../../../components/body/col";
import Row from "../../../components/body/row";
import Card from "../Grafico/components/Card";
import Icon from "../../../components/body/icon";
import Chart from '../../../components/body/chart';
import FilterCheckbox from "../../../components/body/filterCheckbox";
import SelectReact from "../../../components/body/select";
import axios from "axios";

export default function Grafico2({ icons, filters }) {

    // CONTEXT JOBS
    const { optionsEmpreendimentos, handleSetFilterEmpreendimento, filterEmpreendimento, optionsUsuario } = useContext(JobsContext);

    // -> HOJE
    var date = new Date();

    // ESTADOS
    const [loja, setLoja] = useState([]);
    const [usuario, setUsuario] = useState([]);
    const [supervisor, setSupervisor] = useState([]);
    const [dataInicial, setDataInicial] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [dataFinal, setDataFinal] = useState(new Date());
    const [filterMonth, setFilterMonth] = useState(window.currentMonth);
    const [filterYear, setFilterYear] = useState(window.currentYear);
    const [usuariosAtivos, setUsuariosAtivos] = useState(0);
    const [usuariosOnline, setUsuariosOnline] = useState(0);

    //ESTADOS CARDS
    const [cardJobs, setCardJobs] = useState('');
    const [cardChamados, setCardChamados] = useState('');
    const [cardComunicados, setCardComunicados] = useState('');
    const [cardChecklist, setCardChecklist] = useState('');

    // ESTADOS DOS ÚLTIMOS 6 MESES
    const [monthJobs, setMonthJobs] = useState([]);
    const [monthChamados, setMonthChamados] = useState([]);
    const [monthComunicados, setMonthComunicados] = useState([]);
    const [monthChecklist, setMonthChecklist] = useState([]);
    const [monthUsuarios, setMonthUsuarios] = useState([]);
    const [loadingMonth, setLoadingMonth] = useState(true);


    // OPTIONS MESES
    const optionsMonths = [
        { value: 1, label: 'Janeiro' },
        { value: 2, label: 'Fevereiro' },
        { value: 3, label: 'Março' },
        { value: 4, label: 'Abril' },
        { value: 5, label: 'Maio' },
        { value: 6, label: 'Junho' },
        { value: 7, label: 'Julho' },
        { value: 8, label: 'Agosto' },
        { value: 9, label: 'Setembro' },
        { value: 10, label: 'Outubro' },
        { value: 11, label: 'Novembro' },
        { value: 12, label: 'Dezembro' }
    ]

    // OPTIONS ANO
    var optionsYear = [];
    for (var i = 0; i < 5; i++) {
        optionsYear.push(
            { value: window.currentYear - i, label: window.currentYear - i }
        )
    }

    // FILTRO DE MÊS
    const handleFilterMonth = (e) => {
        setFilterMonth(e);
        setDataInicial(new Date(filterYear, (e - 1), 1));
        const endDate = new Date(filterYear, (e - 1), 1);

        const endOfMonth = new Date(
            endDate.getUTCFullYear(),
            endDate.getUTCMonth() + 1,
            0
        );
        setDataFinal(endOfMonth);
    }

    // FILTRO DE ANO
    const handleFilterYear = (e) => {
        setFilterYear(e);
        setDataInicial(new Date(e, (filterMonth - 1), 1));
        setDataFinal(new Date(e, (filterMonth - 1), get_date('last_day', (e + '-' + (filterMonth - 1) + '-01'), 'date_sql')));
    }

    // GET CARDS
    function get_card(system) {
        let do_aux;

        if (system === 'jobs') {
            do_aux = 'getJob';
        } else if (system === 'chamados') {
            do_aux = 'getCalled';
        } else if (system === 'comunicados') {
            do_aux = 'getCommunication';
        } else if (system === 'checklist') {
            do_aux = 'getChecklist';
        }

        axios({
            url: window.host + '/systems/integration-react/api/request.php?type=Dashboard',
            params: {
                do: do_aux,
                token: window.token,
                filter_subtype: 'store',
                filter_date_start: (dataInicial ? get_date('date_sql', cd(dataInicial)) : ''),
                filter_date_end: (dataFinal ? get_date('date_sql', cd(dataFinal)) : ''),
                filter_id_emp: filterEmpreendimento,
                filter_supervisor: supervisor,
                filter_id_store: loja,
                filter_id_user: usuario,
            }
        }).then((response) => {
            if (response?.data?.data[0]) {
                if (system === 'jobs') {
                    setCardJobs(response.data.data[0]);
                } else if (system === 'chamados') {
                    setCardChamados(response.data.data[0]);
                } else if (system === 'comunicados') {
                    setCardComunicados(response.data.data[0]);
                } else if (system === 'checklist') {
                    setCardChecklist(response.data.data[0]);
                }
            }
        });
    }

    // GET ADM
    function get_adm() {
        axios({
            url: window.host + '/systems/integration-react/api/request.php?type=Dashboard',
            params: {
                do: "getUserAdmOnline",
                token: window.token,
                filter_id_emp: filterEmpreendimento
            }
        }).then((response) => {
            if (response?.data?.data[0]) {
                setUsuariosAtivos(response?.data?.data[0].qtd_total);
                setUsuariosOnline(response?.data?.data[0].qtd_onlines)
            }
        });
    }

    // GET ÚLTIMOS 6 MESES
    function get_months(system) {
        setLoadingMonth(true);

        let do_aux;

        if (system === 'jobs') {
            do_aux = 'getJob';
        } else if (system === 'chamados') {
            do_aux = 'getCalled';
        } else if (system === 'comunicados') {
            do_aux = 'getCommunication';
        } else if (system === 'checklist') {
            do_aux = 'getChecklist';
        } else if (system === 'usuarios') {
            do_aux = 'getUserAdmOnline';
        }

        const endDate = new Date(dataInicial);

        const endOfMonth = new Date(
            endDate.getUTCFullYear(),
            endDate.getUTCMonth() + 1,
            0
        );
        axios({
            url: window.host + '/systems/integration-react/api/request.php?type=Dashboard',
            params: {
                do: do_aux,
                token: window.token,
                filter_type: 'year_month',
                filter_date_start: (dataInicial ? get_date('date_sql', cd(dataInicial), 'date_sub_month', 5) : ''),
                filter_date_end: (dataFinal ? get_date('date_sql', cd(dataFinal)) : ''),
                filter_id_emp: filterEmpreendimento,
                filter_supervisor: supervisor,
                filter_id_store: loja,
                filter_id_user: usuario,
            }
        }).then((response) => {
            if (response?.data?.data[0]) {
                if (system === 'jobs') {
                    setMonthJobs(response.data.data);
                } else
                    if (system === 'chamados') {
                        setMonthChamados(response.data.data);
                    } else if (system === 'comunicados') {
                        setMonthComunicados(response.data.data);
                    } else if (system === 'checklist') {
                        setMonthChecklist(response.data.data);
                    } else if (system === 'usuarios') {
                        setMonthUsuarios(response?.data?.data);
                    }
            }
            setLoadingMonth(false);
        });
    }

    // useEffect(() => {
    //     axios.get(window.host + '/systems/integration-react/api/request.php', {
    //         params: {
    //             do: 'getBelated',
    //             type: 'Dashboard',
    //             token: window.token,
    //             filter_type: 'store',
    //             only_data: true,
    //             filter_date_start: (dataInicial ? dataInicial.toISOString().split('T')[0] : ''),
    //             filter_date_end: (dataFinal ? dataFinal.toISOString().split('T')[0] : ''),
    //             filter_supervisor: supervisor,
    //             filter_id_emp: filterEmpreendimento,
    //             filter_id_store: loja,
    //             id_apl: 223
    //         }
    //     }).then((response) => { console.log(response.data) })
    // }, [dataInicial])

    // CHAMADAS INICIAIS
    useEffect(() => {
        get_card('jobs');
        get_card('chamados');
        get_card('comunicados');
        get_card('checklist');
        get_adm();
        // get_months('jobs');
        get_months('chamados');
        get_months('comunicados');
        get_months('checklist');
        get_months('usuarios');

        //evolução jobs teste
        axios.get(window.host + '/systems/integration-react/api/request.php', {
            params: {
                do: 'getBelated',
                type: 'Dashboard',
                token: window.token,
                filter_type: 'store',
                only_data: true,
                filter_date_start: (dataInicial ? dataInicial.toISOString().split('T')[0] : ''),
                filter_date_end: (dataFinal ? dataFinal.toISOString().split('T')[0] : ''),
                filter_supervisor: supervisor,
                filter_id_emp: filterEmpreendimento,
                filter_id_store: loja,
                id_apl: 223
            }
        }).then((response) => { console.log(response.data) })
    }, [dataInicial, dataFinal, supervisor, loja, filterEmpreendimento]);

    useEffect(() => {
        if (icons) {
            icons(<Icon type="print" />)
        }
        if (filters) {
            filters(
                <>
                    <SelectReact
                        options={optionsMonths}
                        placeholder="Mês"
                        name="filter_month"
                        defaultValue={""}
                        value={filterMonth}
                        onChange={(e) => handleFilterMonth(e.value)}
                    />

                    <SelectReact
                        placeholder="Ano"
                        options={optionsYear}
                        value={filterYear}
                        onChange={(e) => handleFilterYear(e.value)}
                    />
                </>
            );
        }
    }, [filterEmpreendimento, optionsEmpreendimentos, filterMonth, filterYear, supervisor, loja]);

    return (
        <>
            <Row>
                {/* JOBS */}
                <Col lg={3} >
                    <Card
                        title={'Jobs'}
                        background={'#2cb59c'}
                        icon={<Icon type={'check'} />}
                        info={[
                            // { label: '', number: (cardJobs.qtd_atrasado ? cardJobs.qtd_atrasado : 0) },
                            // { label: 'Adesão Jobs', number: (cardJobs.qtd_feito ? cardJobs.qtd_feito : 0) },
                            // { label: 'Atrasados', number: (cardJobs.qtd_feito_com_atraso ? cardJobs.qtd_feito_com_atraso : 0) },
                            // { label: 'Não tem', number: (cardJobs.qtd_feito_com_atraso ? cardJobs.qtd_feito_com_atraso : 0) }
                            { label: 'Adesão Jobs', number: '83%' },
                            { label: 'Atrasados', number: 105 },
                            { label: 'Não tem', number: 4 }
                        ]}
                    />
                </Col>
                {/* CHAMADOS */}
                <Col lg={3} >
                    <Card
                        title={'Chamados'}
                        background={'#10459e'}
                        icon={<Icon type={'check'} />}
                        info={[
                            // { label: '', number: (cardChamados.qtd_pendente ? cardChamados.qtd_pendente : 0) },
                            // { label: 'Adesão Chamados', number: (cardChamados.qtd_finalizado ? cardChamados.qtd_finalizado : 0) },
                            // { label: 'Em fila', number: (cardChamados.tempo_medio_min ? Math.round(cardChamados.tempo_medio_min) + 'min.' : 0) }
                            { label: 'Adesão Chamados', number: '64%' },
                            { label: 'Em fila', number: 26 }
                        ]}
                    />
                </Col>
                {/* CHECKLIST */}
                <Col lg={3} >
                    <Card
                        title={'Checklist'}
                        background={'#ff772a'}
                        icon={<Icon type={'check'} />}
                        info={[
                            // { label: '', number: (cardComunicados.qtd_total ? cardComunicados.qtd_total : 0) },
                            // { label: 'Lidos', number: (cardComunicados.qtd_lido ? cardComunicados.qtd_lido : 0) },
                            // { label: '% Leitura', number: (cardComunicados.qtd_lido && cardComunicados.qtd_total ? Math.round(((cardComunicados.qtd_lido / cardComunicados.qtd_total) * 100)) : 0) }
                            { label: 'Adesão Checklist', number: '95%' },
                            { label: 'Atrasados', number: 11 },
                            { label: 'Tempo médio de resposta', number: '12 min' },
                        ]}
                    />
                </Col>
                {/* COMUNICADOS */}
                <Col lg={3} >
                    <Card
                        title={'Comunicados'}
                        background={'#714ae1'}
                        icon={<Icon type={'check'} />}
                        info={[
                            // { label: 'Conforme', number: (cardChecklist.qtd_aprovado ? cardChecklist.qtd_aprovado : 0) },
                            // { label: 'Não conforme', number: (cardChecklist.qtd_reprovado ? cardChecklist.qtd_reprovado : 0) },
                            // { label: 'Não se aplica', number: (cardChecklist.qtd_nao_aplica ? cardChecklist.qtd_nao_aplica : 0) }
                            { label: 'Total de Comunicados', number: 12 },
                            { label: 'Atrasados', number: 2 },
                            { label: 'Média de leitura', number: '3 min' }
                        ]}
                    />
                </Col>
            </Row>
            <Row>
                {/* média mensal - checklist */}
                <Col lg={4} >
                    <Chart
                        id="teste1"
                        title="Média Mensal - Checklist"
                        type="ComboChart"
                        // headers={['Mês', 'Jobs no prazo', 'Chamados abertos', 'Comunicados enviados', 'Checklists conformes', 'Usuários ativos']}
                        headers={['Dias', 'Notas do Checklist', 'Média de Inconformidades']}
                        body={{
                            type: "custom",
                            content: [
                                // [get_date('day', get_date('date_sql', cd(date), 'date_sub_day', 21), 'date_sql'), (monthChecklist[0]?.nota_checklist ? monthChecklist[0]?.nota_checklist : 0), (monthChecklist[0]?.media ? monthChecklist[0]?.media : 0)],
                                // [get_date('day', get_date('date_sql', cd(date), 'date_sub_day', 14), 'date_sql'), (monthChecklist[0]?.nota_checklist ? monthChecklist[0]?.nota_checklist : 0), (monthChecklist[0]?.media ? monthChecklist[0]?.media : 0)],
                                // [get_date('day', get_date('date_sql', cd(date), 'date_sub_day', 7), 'date_sql'), (monthChecklist[0]?.nota_checklist ? monthChecklist[0]?.nota_checklist : 0), (monthChecklist[0]?.media ? monthChecklist[0]?.media : 0)],
                                // [get_date('day', get_date('date_sql', cd(date), 'date_sub_day', 0), 'date_sql'), (monthChecklist[0]?.nota_checklist ? monthChecklist[0]?.nota_checklist : 0), (monthChecklist[0]?.media ? monthChecklist[0]?.media : 0)],
                                // [('Média'), (monthChecklist[0]?.nota_checklist ? monthChecklist[0]?.nota_checklist : 0), (monthChecklist[0]?.media ? monthChecklist[0]?.media : 0)],

                                [get_date('day', get_date('date_sql', cd(date), 'date_sub_day', 21), 'date_sql'), 64, 85],
                                [get_date('day', get_date('date_sql', cd(date), 'date_sub_day', 14), 'date_sql'), 80, 95],
                                [get_date('day', get_date('date_sql', cd(date), 'date_sub_day', 7), 'date_sql'), 30, 75],
                                [get_date('day', get_date('date_sql', cd(date), 'date_sub_day', 0), 'date_sql'), 58, 80],
                                [('Média'), 55, 85],

                            ]
                        }}
                        colors={['#0396d8', '#ff772a']}
                        //height={(window.isMobile ? 400 : 553)}
                        height={(window.isMobile ? 400 : 570)}
                        loading={loadingMonth}
                    />
                </Col>
                {/* evolução diária jobs */}
                <Col lg={8} >
                    <Chart
                        id="teste2"
                        title="Evolução diária - Jobs"
                        type="LineChart"
                        curveType={false}
                        headers={['Mês', 'Conforme', 'Não Conforme', 'Não se Aplica', 'Não foi feito']}
                        body={{
                            type: "custom",
                            content: [
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 30), 'date_sql'), 122, (monthChamados[0]?.qtd_atrasado ? monthChamados[0]?.qtd_atrasado : 100), (monthComunicados[0]?.qtd_total ? monthComunicados[0]?.qtd_total : 300), 150],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 29), 'date_sql'), 132, (monthChamados[1]?.qtd_atrasado ? monthChamados[1]?.qtd_atrasado : 110), (monthComunicados[1]?.qtd_total ? monthComunicados[1]?.qtd_total : 280), 110],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 28), 'date_sql'), 150, (monthChamados[2]?.qtd_atrasado ? monthChamados[2]?.qtd_atrasado : 120), (monthComunicados[2]?.qtd_total ? monthComunicados[2]?.qtd_total : 120), 230],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 27), 'date_sql'), 90, (monthChamados[3]?.qtd_atrasado ? monthChamados[3]?.qtd_atrasado : 130), (monthComunicados[3]?.qtd_total ? monthComunicados[3]?.qtd_total : 320), 200],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 26), 'date_sql'), 100, (monthChamados[4]?.qtd_atrasado ? monthChamados[4]?.qtd_atrasado : 200), (monthComunicados[4]?.qtd_total ? monthComunicados[4]?.qtd_total : 100), 200],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 25), 'date_sql'), 85, (monthChamados[5]?.qtd_atrasado ? monthChamados[5]?.qtd_atrasado : 230), (monthComunicados[5]?.qtd_total ? monthComunicados[5]?.qtd_total : 190), 150],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 24), 'date_sql'), 110, (monthChamados[6]?.qtd_atrasado ? monthChamados[6]?.qtd_atrasado : 120), (monthComunicados[6]?.qtd_total ? monthComunicados[6]?.qtd_total : 250), 140],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 23), 'date_sql'), 105, (monthChamados[7]?.qtd_atrasado ? monthChamados[7]?.qtd_atrasado : 150), (monthComunicados[7]?.qtd_total ? monthComunicados[7]?.qtd_total : 340), 100],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 22), 'date_sql'), 200, (monthChamados[8]?.qtd_atrasado ? monthChamados[8]?.qtd_atrasado : 320), (monthComunicados[8]?.qtd_total ? monthComunicados[8]?.qtd_total : 450), 300],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 21), 'date_sql'), 210, (monthChamados[9]?.qtd_atrasado ? monthChamados[9]?.qtd_atrasado : 250), (monthComunicados[9]?.qtd_total ? monthComunicados[9]?.qtd_total : 240), 120],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 20), 'date_sql'), 250, (monthChamados[10]?.qtd_atrasado ? monthChamados[10]?.qtd_atrasado : 410), (monthComunicados[10]?.qtd_total ? monthComunicados[10]?.qtd_total : 380), 321],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 19), 'date_sql'), 300, (monthChamados[11]?.qtd_atrasado ? monthChamados[11]?.qtd_atrasado : 190), (monthComunicados[11]?.qtd_total ? monthComunicados[11]?.qtd_total : 420), 123],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 18), 'date_sql'), 170, (monthChamados[12]?.qtd_atrasado ? monthChamados[12]?.qtd_atrasado : 290), (monthComunicados[12]?.qtd_total ? monthComunicados[12]?.qtd_total : 180), 251],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 17), 'date_sql'), 260, (monthChamados[13]?.qtd_atrasado ? monthChamados[13]?.qtd_atrasado : 470), (monthComunicados[13]?.qtd_total ? monthComunicados[13]?.qtd_total : 240), 321],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 16), 'date_sql'), 190, (monthChamados[14]?.qtd_atrasado ? monthChamados[14]?.qtd_atrasado : 390), (monthComunicados[14]?.qtd_total ? monthComunicados[14]?.qtd_total : 320), 160],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 15), 'date_sql'), 230, (monthChamados[15]?.qtd_atrasado ? monthChamados[15]?.qtd_atrasado : 200), (monthComunicados[15]?.qtd_total ? monthComunicados[15]?.qtd_total : 120), 214],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 14), 'date_sql'), 400, (monthChamados[16]?.qtd_atrasado ? monthChamados[16]?.qtd_atrasado : 190), (monthComunicados[16]?.qtd_total ? monthComunicados[16]?.qtd_total : 280), 150],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 13), 'date_sql'), 85, (monthChamados[17]?.qtd_atrasado ? monthChamados[17]?.qtd_atrasado : 200), (monthComunicados[17]?.qtd_total ? monthComunicados[17]?.qtd_total : 170), 215],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 12), 'date_sql'), 190, (monthChamados[18]?.qtd_atrasado ? monthChamados[18]?.qtd_atrasado : 310), (monthComunicados[18]?.qtd_total ? monthComunicados[18]?.qtd_total : 120), 100],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 11), 'date_sql'), 300, (monthChamados[19]?.qtd_atrasado ? monthChamados[19]?.qtd_atrasado : 230), (monthComunicados[19]?.qtd_total ? monthComunicados[19]?.qtd_total : 320), 143],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 10), 'date_sql'), 180, (monthChamados[20]?.qtd_atrasado ? monthChamados[20]?.qtd_atrasado : 250), (monthComunicados[20]?.qtd_total ? monthComunicados[20]?.qtd_total : 150), 210],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 9), 'date_sql'), 210, (monthChamados[21]?.qtd_atrasado ? monthChamados[21]?.qtd_atrasado : 400), (monthComunicados[21]?.qtd_total ? monthComunicados[21]?.qtd_total : 280), 120],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 8), 'date_sql'), 350, (monthChamados[22]?.qtd_atrasado ? monthChamados[22]?.qtd_atrasado : 170), (monthComunicados[22]?.qtd_total ? monthComunicados[22]?.qtd_total : 250), 154],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 7), 'date_sql'), 400, (monthChamados[23]?.qtd_atrasado ? monthChamados[23]?.qtd_atrasado : 190), (monthComunicados[23]?.qtd_total ? monthComunicados[23]?.qtd_total : 370), 250],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 6), 'date_sql'), 230, (monthChamados[24]?.qtd_atrasado ? monthChamados[24]?.qtd_atrasado : 200), (monthComunicados[24]?.qtd_total ? monthComunicados[24]?.qtd_total : 410), 160],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 5), 'date_sql'), 130, (monthChamados[25]?.qtd_atrasado ? monthChamados[25]?.qtd_atrasado : 130), (monthComunicados[25]?.qtd_total ? monthComunicados[25]?.qtd_total : 100), 145],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 4), 'date_sql'), 250, (monthChamados[26]?.qtd_atrasado ? monthChamados[26]?.qtd_atrasado : 140), (monthComunicados[26]?.qtd_total ? monthComunicados[26]?.qtd_total : 260), 410],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 3), 'date_sql'), 352, (monthChamados[27]?.qtd_atrasado ? monthChamados[27]?.qtd_atrasado : 320), (monthComunicados[27]?.qtd_total ? monthComunicados[27]?.qtd_total : 420), 103],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 2), 'date_sql'), 154, (monthChamados[28]?.qtd_atrasado ? monthChamados[28]?.qtd_atrasado : 270), (monthComunicados[28]?.qtd_total ? monthComunicados[28]?.qtd_total : 120), 152],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 1), 'date_sql'), 415, (monthChamados[29]?.qtd_atrasado ? monthChamados[29]?.qtd_atrasado : 390), (monthComunicados[29]?.qtd_total ? monthComunicados[29]?.qtd_total : 130), 301]
                                // [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 4), 'date_sql'), (monthJobs[26]?.qtd_feito ? monthJobs[26]?.qtd_feito : 0), (monthChamados[26]?.qtd_atrasado ? monthChamados[26]?.qtd_atrasado : 0), (monthComunicados[26]?.qtd_total ? monthComunicados[26]?.qtd_total : 0), (monthChecklist[26]?.qtd_aprovado ? monthChecklist[26]?.qtd_aprovado : 0)],
                                // [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 3), 'date_sql'), (monthJobs[27]?.qtd_feito ? monthJobs[27]?.qtd_feito : 0), (monthChamados[27]?.qtd_atrasado ? monthChamados[27]?.qtd_atrasado : 0), (monthComunicados[27]?.qtd_total ? monthComunicados[27]?.qtd_total : 0), (monthChecklist[27]?.qtd_aprovado ? monthChecklist[27]?.qtd_aprovado : 0)],
                                // [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 2), 'date_sql'), (monthJobs[28]?.qtd_feito ? monthJobs[28]?.qtd_feito : 0), (monthChamados[28]?.qtd_atrasado ? monthChamados[28]?.qtd_atrasado : 0), (monthComunicados[28]?.qtd_total ? monthComunicados[28]?.qtd_total : 0), (monthChecklist[28]?.qtd_aprovado ? monthChecklist[28]?.qtd_aprovado : 0)],
                                // [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 1), 'date_sql'), (monthJobs[29]?.qtd_feito ? monthJobs[29]?.qtd_feito : 0), (monthChamados[29]?.qtd_atrasado ? monthChamados[29]?.qtd_atrasado : 0), (monthComunicados[29]?.qtd_total ? monthComunicados[29]?.qtd_total : 0), (monthChecklist[29]?.qtd_aprovado ? monthChecklist[29]?.qtd_aprovado : 0)],
                                // [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 0), 'date_sql'), (monthJobs[5]?.qtd_feito ? monthJobs[5]?.qtd_feito : 0), (monthChamados[5]?.qtd_pendente ? monthChamados[5]?.qtd_pendente : 0), (monthComunicados[5]?.qtd_total ? monthComunicados[5]?.qtd_total : 0), (monthChecklist[5]?.qtd_aprovado ? monthChecklist[5]?.qtd_aprovado : 0)],

                            ]
                        }}
                        height={(window.isMobile ? 400 : 570)}
                        className="flex-1"
                    />
                </Col>
                <Row>
                    {/* principais inconformidades - checklist  */}
                    <Col lg={6} >
                        <Chart
                            id="teste1"
                            title="Principais inconformidades - Checklist"
                            type="ComboChart"
                            // headers={['Mês', 'Jobs no prazo', 'Chamados abertos', 'Comunicados enviados', 'Checklists conformes', 'Usuários ativos']}
                            headers={['Título', '']}
                            body={{
                                type: "custom",
                                content: [
                                    // ['Livro', (monthChecklist[0]?.media ? monthChecklist[0]?.media : 0)],
                                    // ['Câmeras', (monthChecklist[0]?.media ? monthChecklist[0]?.media : 0)],
                                    // ['Antenas', (monthChecklist[0]?.media ? monthChecklist[0]?.media : 0)],
                                    // ['Panfletos', (monthChecklist[0]?.media ? monthChecklist[0]?.media : 0)],
                                    // ['Atendimento', (monthChecklist[0]?.media ? monthChecklist[0]?.media : 0)],
                                    ['Livro de ponto', 5],
                                    ['Câmeras de segurança', 14],
                                    ['Antenas de segurança', 33],
                                    ['Panfletos no expositor', 7],
                                    ['Atendimentos perfumistas', 12],
                                    ['Agendamento de treinamentos', 26],
                                    ['Limpeza da loja', 27]
                                ]
                            }}
                            //height={(window.isMobile ? 400 : 553)}
                            colors={['#db2f30']}
                            height={450}
                            loading={loadingMonth}
                        />
                    </Col>
                    {/* adesão jobs */}
                    <Col lg={3}>
                        <Chart
                            title="Adesão Jobs"
                            subtitle=""
                            headers={['Lojas', 'Qtd. Aprovados']}
                            body={{
                                content: ['loja', 'qtd_aprovado']
                            }}
                            api={{
                                url: window.host + '/systems/integration-react/api/request.php',
                                params: {
                                    token: window.token,
                                    do: 'getChecklist',
                                    type: 'Dashboard',
                                    filter_type: 'store',
                                    filter_date_start: (dataInicial ? dataInicial.toISOString().split('T')[0] : ''),
                                    filter_date_end: (dataFinal ? dataFinal.toISOString().split('T')[0] : ''),
                                    filter_supervisor: supervisor,
                                    filter_id_emp: filterEmpreendimento,
                                    filter_id_store: loja,
                                    filter_id_user: usuario,
                                    only_data: true,
                                }
                            }}
                        />
                    </Col>
                    {/* adesão visitas */}
                    <Col lg={3}>
                        <Chart
                            title="Adesão Visitas"
                            subtitle=""
                            headers={['Lojas', 'Qtd. Aprovados']}
                            body={{
                                content: ['loja', 'qtd_reprovado']
                            }}
                            api={{
                                url: window.host + '/systems/integration-react/api/request.php',
                                params: {
                                    token: window.token,
                                    do: 'getChecklist',
                                    type: 'Dashboard',
                                    filter_type: 'store',
                                    filter_date_start: (dataInicial ? dataInicial.toISOString().split('T')[0] : ''),
                                    filter_date_end: (dataFinal ? dataFinal.toISOString().split('T')[0] : ''),
                                    filter_supervisor: supervisor,
                                    filter_id_emp: filterEmpreendimento,
                                    filter_id_store: loja,
                                    filter_id_user: usuario,
                                    only_data: true,
                                }
                            }}
                        />
                    </Col>
                </Row>
            </Row>
        </>
    )
}