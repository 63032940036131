import { useState, useEffect } from 'react';

import Gerenciador from '../../../components/body/gerenciador';
import Input from '../../../components/body/form/input';
import Table from '../../../components/body/table';
import Tbody from '../../../components/body/table/tbody';
import Tr from '../../../components/body/table/tr';
import Td from '../../../components/body/table/tbody/td';
import { toast } from 'react-hot-toast';
import axios from 'axios';

export default function Permissao({user, user_store, systems, system, callback, disabled}){
    // ESTADOS
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(0);
    const [checked, setChecked] = useState([]);

    // ATUALIZA A LISTA DE PERMISSÕES CASO O COMPONENTE PAI ATUALIZE A LISTA SISTEMAS
    useEffect(() => {
        if(systems.length > 0){
            systems.filter((elem) => elem.id === system).map((system, i) => {
                let permissoes_aux = [];
                let permissoes_id = system?.permissoes_id?.split('|');
                let checked_aux = [];

                system.permissoes.split('|').map((item, i) => {                    
                    permissoes_aux.push({
                        nome: item,
                        id: i,
                        permissao_id: permissoes_id[i],
                        checked: (system.permissao_usuario === i ? true : null),
                        permissao_loja: ((system.permissoes_loja ? (system.permissoes_loja.split('|')[i] == 1 ? true : false) : null))
                    });

                    if(system.permissao_usuario === i){
                        checked_aux = [i];
                    }
                });
                
                setItems(permissoes_aux);
                setChecked(checked_aux);
            })
        }
    },[systems, system, user]);

    // MARCA PERMISSÃO
    const handleChecked = (e) => {    
        setChecked(e.target.value);

        axios({
            method: 'post',
            url: window.host+'/systems/gerenciador/api/settings.php?do=insUpdColumn&token='+window.token,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            data: {
                columns: [{
                    column: 'user_permission_system',
                    params: {
                        id_usuario: user,
                        id_sistema: system,
                        permissao: (e.target.value ? e.target.value : 0),
                        permissao_id: (e.target.id ? e.target.id : undefined)
                    }
                }]
            }
        }).then(() => {
            toast('Permissão alterada');
        });
    }

    return(
        <Gerenciador 
            id="permissao"
            title="Permissao"
            search={false}
            disabled={disabled}
        >
            <Table id="table_sistemas" text_limit={(window.isMobile ? 20 : 30)}>
                <Tbody>
                    {(items.length > 0 ?
                        items.map((item, i) => {
                            if(!user_store || (user_store && item.permissao_loja)){
                                return(
                                    <Tr
                                        key={'permissao_'+item.id}
                                    >
                                        <Td>{item.nome}</Td>
                                        <Td width={1} align="center">
                                            <Input
                                                type="radio"
                                                name="permissao"
                                                checked={(checked.includes(item.id) ? true : null)}
                                                id={item?.permissao_id}
                                                value={item.id}
                                                onChange={handleChecked}
                                            />
                                        </Td>
                                    </Tr>
                                )
                            }
                        })
                    :
                        <Tr empty={true}>
                            <Td></Td>
                        </Tr>
                    )}
                </Tbody>
            </Table>
        </Gerenciador>
    )
}
