import Modal from "../../../../../../components/body/modal";
import ModalBody from "../../../../../../components/body/modal/modalBody";
import ModalHeader from "../../../../../../components/body/modal/modalHeader";
import ModalTitle from "../../../../../../components/body/modal/modalHeader/modalTitle";
import Form from "../../../../../../components/body/form"
import Input from "../../../../../../components/body/form/input";
import { useState } from "react";
import "./style.scss"
import Button from "../../../../../../components/body/button";
import Title from "../../../../../../components/body/title";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import Table from "../../../../../../components/body/table";
import Tr from "../../../../../../components/body/table/tr";
import Td from "../../../../../../components/body/table/tbody/td";
import Loader from "../../../../../../components/body/loader";
import Icon from "../../../../../../components/body/icon";
import { cdh, downloadFile } from '../../../../../../_assets/js/global';
import Thead from "../../../../../../components/body/table/thead";
import Tbody from "../../../../../../components/body/table/tbody";
import Th from "../../../../../../components/body/table/thead/th";
import style from './modal.module.scss';

export default function ModalCadastroDocumentos(props) {
    //ESTADOS
    const [anexos, setAnexos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [statusButton, setStatusButton] = useState('');

    //VARIAVEL FECHAR MODAL
    const handleCloseCadastro = (confirm) => {  
        if(anexos.length == 0 || confirm === false || props?.interaction === false){             
            props?.onHide(false);
            setTimeout(() => {
                setAnexos([]);
            },500);
            if(confirm === false){
                if(props?.job?.subcategoria){
                    props?.callback(props?.job?.subcategoria); // ENVIA O NOME DO DOCUMENTO PARA O COMPONENTE PAI
                }else{
                    props?.callback(true);
                }                
            }
        }else{   
            if(window.confirm('Os arquivos foram anexados, mas ainda não foram enviados, deseja realmente sair?')){                
                props?.onHide(false);
                setTimeout(() => {
                    setAnexos([]);
                },500);           
                props?.callback(false);
            }
        }
    };

    // SETAR INPUT DOCUMENTO
    const handleSetAnexo = (response) => {
        if(JSON.parse(response[0]).length > 0){
            let countFiles =  JSON.parse(response[0]).length;
            let files = [];

            JSON.parse(response[0]).map((file, i) => {
                files.push(file);
                if(countFiles == (i+1)){
                    send_file(files);
                }
            })
        }
    };

    function get_files(loading){
        if(loading !== false){
            setLoading(true);
        }
        if(props?.interaction === false){
            axios({
                method: 'get',
                url: window.host + "/systems/integration-react/api/list.php?do=get_list&token=" + window.token,
                params: {
                    filter_type: 5,
                    filter_subtype: 'history',
                    filter_parent: true,
                    filter_id_job: props?.job?.id_job,
                    filter_type_registry: 'document'
                }
            }).then((response) => {
                setLoading(false);
                if(response?.data){
                    setAnexos(response?.data);
                }            
            });
        }else{
            axios({
                method: 'get',
                url: window.host+'/systems/integration-react/api/list.php?do=get_msg&token='+window.token,
                params: {
                    id: props?.job?.id_job_status,
                    filter_isnull_log_aux: true,
                    filter_log_ativ: 'Anexou arquivo' // NÃO MUDAR
                }
            }).then((response) => {
                setLoading(false);
                if(response?.data){
                    setAnexos(response?.data);
                }     
            })
        }
    }

    function send_file(file){
        axios({
            method: 'post',
            url: window.host+'/systems/integration-react/api/list.php?do=set_msg&token='+window.token,
            data: {
                ativ: 'Anexou arquivo', // NÃO MUDAR
                ativ_desc: 'Anexou o(s) arquivo(s)',
                id_job: props?.job?.id_job,
                id_mov: props?.job?.id_job_status,
                anexos: JSON.stringify(file)
            },
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(() => {            
            toast('Arquivo(s) anexado(s) com sucesso');
            get_files();
        })
    }

    // DELETA ARQUIVO
    function handleDelete(anexo, id_log){
        let anexos_aux = JSON.parse(anexos[(anexos.length-1)].anexos).filter((elem) => {
            if(elem.id !== anexo){
                return true;
            }
        });

        if(window.confirm('Tem certeza que deseja remover o arquivo?')){
            axios({
                method: 'post',
                url: window.host+'/systems/integration-react/api/list.php?do=set_msg_active&token='+window.token,
                data: {
                    ativ: 'Anexou arquivo', // NÃO MUDAR
                    ativ_desc: 'Anexou o(s) arquivo(s)',
                    id_job: props?.job?.id_job,
                    id_mov: props?.job?.id_job_status,
                    anexos: JSON.stringify(anexos_aux),
                    id_log: id_log
                },
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            }).then(() => {            
                get_files(false);
                toast('Arquivo removido');
            })
        }
    }

    // STATUS BOTÃO FORM
    const handleSetButtonState = (e) => {
        setStatusButton(e);
    }

    useEffect(() => {        
        if(props?.show){
            get_files();
        }
    },[props?.show]);

    // VALORES FORMULÁRIO
    const data = {
        ativ_desc: 'Cadastrou um documento',
        id_job: props?.job?.id_job,
        id_mov: props?.job?.id_job_status,
        status: 1
    }

    return (
        <>
            <Modal show={props?.show} handleShow={props?.handleShow} onHide={() => handleCloseCadastro(true)} >
                <ModalHeader>
                    <ModalTitle>
                        {props?.job?.subcategoria}
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Form
                        api={window.host+"/systems/integration-react/api/list.php?do=set_status&token="+window.token}
                        data={data}
                        toast={'Documento cadastrado com sucesso'}
                        callback={() => handleCloseCadastro(false)}
                        status={handleSetButtonState}
                    >
                        {(props.interaction !== false ?
                            <Input
                                name="documento"
                                type="file"
                                label="Anexos"
                                multiple={true}
                                callback={handleSetAnexo}
                                required={false}
                            />
                        :'')}

                        <div className={(props.interaction !== false ? 'mt-4' : '')}>
                            {(props.interaction !== false ?
                                <Title>
                                    Arquivos anexados
                                </Title>
                            :'')}

                            {(loading ? 
                                <Loader />
                            :
                                (props.interaction === false ?
                                    (anexos.length > 0 ?
                                        anexos.map((anexo, i) => {
                                            return(
                                                <Table
                                                    key={'anexo_'+i}
                                                    border={false}
                                                    className={style.documentos}
                                                >
                                                    <Thead>
                                                        <Tr>
                                                            <Th>
                                                                Revisão {anexo.revision}
                                                            </Th>
                                                            <Th align="end" width={1}>
                                                                <span className="pe-2">
                                                                    {anexo?.data[0]?.anexo_job.length} arquivo(s)
                                                                </span>
                                                                <Icon
                                                                    type="download"
                                                                    title="Baixar todos"
                                                                    animated
                                                                    onClick={() => downloadFile(anexo?.data[0]?.anexo_job, 'Documentos', (anexo?.data[0]?.anexo_job.length > 0 ? true : false))}
                                                                />
                                                            </Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {(anexo?.data.length > 0 ?
                                                            anexo?.data[0]?.anexo_job.map((file, i) => {
                                                                return(                                                                    
                                                                    <Tr key={'file_'+i}>
                                                                        <Td>
                                                                            {file.name}
                                                                            {(!anexo?.data[0]?.status_supervisor ?
                                                                                <span className="text-secondary ps-2">(Em análise)</span>
                                                                            :'')}
                                                                        </Td>
                                                                        <Td align="end" width={1}>
                                                                            <Icon
                                                                                type="download"
                                                                                title="Baixar arquivo"
                                                                                animated
                                                                                onClick={() => downloadFile(file.id, file.name)}
                                                                            />
                                                                        </Td>
                                                                    </Tr>
                                                                )
                                                            })
                                                        :
                                                            <></>
                                                        )}
                                                    </Tbody>
                                                </Table>
                                            )
                                        })
                                    :
                                        <></>
                                    )
                                :
                                    <Table border={false} className="mb-4">
                                        {(anexos.length > 0 ?
                                            anexos.map((anexo, i) => {
                                                if(anexo?.anexos){
                                                    return JSON.parse(anexo.anexos).map((file, i) => {
                                                        let id = file.id;
                                                        let nome = file.name;

                                                        return(
                                                            <Tr key={'anexo_'+id}>                                        
                                                                <Td>
                                                                    {nome}
                                                                </Td>
        
                                                                <Td width={1} align="center">
                                                                    <Icon 
                                                                        type="download"
                                                                        title="Baixar arquivo"
                                                                        onClick={() => downloadFile(id, nome)}
                                                                    />
        
                                                                    {(props.interaction !== false ?
                                                                        <Icon
                                                                            type="trash"
                                                                            title="Excluir arquivo"
                                                                            onClick={() => handleDelete(id, anexo.id)}
                                                                        />
                                                                    :'')}
                                                                </Td>
                                                            </Tr>
                                                        )
                                                    });
                                                }
                                            })
                                        :
                                            <Tr>
                                                <Td>
                                                    Nenhum arquivo anexado
                                                </Td>
                                            </Tr>
                                        )}
                                    </Table>
                                )
                            )}
                        </div>

                        {(props.interaction !== false ?
                            <Button
                                type="submit"
                                disabled={(anexos.length > 0 ? false : true)}
                                title={(anexos.length > 0 ? false : 'Anexe algum documento no campo acima')}
                                status={statusButton}
                            >
                                Enviar
                            </Button>
                        :'')}
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}