import { useState } from 'react';
import { Routes, Route, useParams, Navigate, useLocation } from 'react-router-dom';
import { GerenciadorProvider } from '../../context/Gerenciador';
import Navbar from '../../components/header/navbar';
import NavbarLink from '../../components/header/navbar/navbarLink';
import Container from '../../components/body/container';
import Icon from '../../components/body/icon';
import { decrypt } from '../../_assets/js/global';

// PÁGINAS
import Lista from './Lista';
import Relatorio from './Relatório';
import GerenciadorMelhorias from './Gerenciador';
import CardsMelhorias from './Cards';

export default function Melhorias() {
    const params = useParams();
    const paramsUrl = params['*'].substring(params['*'].indexOf('/') + 1);
    const paramsUrlCount = (paramsUrl.match(/\//g) ? paramsUrl.match(/\//g).length : 0);
    var tokenUrl, tokenUrlAux;

    if (paramsUrlCount > 0) {
        tokenUrlAux = params['*'].substring(params['*'].indexOf('/') + 1, params['*'].lastIndexOf('/'));
        tokenUrl = tokenUrlAux.substring(0, tokenUrlAux.indexOf('/'));
    } else {
        tokenUrl = params['*'].substring(params['*'].indexOf('/') + 1);
    }

    var decryptedReturn = JSON.parse(decrypt(tokenUrl));

    window.token = tokenUrl;
    window.rs_id_emp = decryptedReturn.id_emp;
    window.rs_id_usr = decryptedReturn.id_usr;
    window.rs_id_lja = decryptedReturn.id_lja;
    window.rs_name_usr = decryptedReturn.name_usr;
    window.rs_id_apl = decryptedReturn.id_apl;
    window.rs_sistema_id = decryptedReturn.sistema_id;
    window.rs_permission_apl = decryptedReturn.pus;
    window.rs_id_module = decryptedReturn.id_module;
    window.rs_id_office = decryptedReturn.id_office;

    const location = useLocation();

    // ESTADOS (ÍCONES E FILTROS)
    const [icons, setIcons] = useState(null);
    const [filters, setFilters] = useState(null);

    // DEFINE ÍCONES NAVBAR
    const handleSetIcons = (e) => {
        setIcons(e);
    }

    // DEFINE FILTROS NAVBAR
    const handleSetFilters = (e) => {
        setFilters(e);
    }

    return (
        <>
            {/* // <GerenciadorProvider> */}
            <Navbar
                icons={
                    (location.pathname == '/systems/melhorias-react/lista/' + window.token || location.pathname == '/systems/melhorias-react/relatorio/' + window.token ?
                        <Icon type="print" />
                        : '')
                }
                filters={filters}
            >
                {/* <NavbarLink link={'cards/' + window.token} name="Cards" icon="relatorio" /> */}
                <NavbarLink link={'lista/' + window.token} name="Lista" icon="analytics" active={true} />
                {/* <NavbarLink link={'relatorio/' + window.token} name="Relatório" icon="relatorio" /> */}
                <NavbarLink link={'gerenciador/' + window.token} name="Gerenciador" icon="cog" />
            </Navbar>

            <Container>
                <Routes>
                    <Route path="/:token" index element={<Navigate to={'lista/' + window.token} replace />} />
                    <Route path="cards/:token" element={<CardsMelhorias />} />
                    <Route path="lista/:token" index element={<Lista filters={handleSetFilters} icons={handleSetIcons} />} />
                    {/* <Route path="relatorio/:token" element={<Relatorio />} /> */}
                    <Route path="gerenciador/:token" element={<GerenciadorMelhorias />} />
                </Routes>
            </Container>
            {/* </GerenciadorProvider> */}
        </>
    );
}
